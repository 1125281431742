/* MY PROFILE */
.myProfile .header{
	position: 							relative;
	display: 							flex;
	flex-flow: 							row nowrap;
	background-color: 					#FFFFFF;
	padding: 							60px 50px 40px 100px;
	box-shadow: 						0 4px 30px 0 rgba(35, 107, 216, 0.08);
	transition: 						background-color 0.3s ease-in-out;
}

.darkMode.myProfile .header{
	background-color: 					#202B34;
}

.myProfile .header::before{
	content: 							"";
	width: 								1px;
	height: 							80%;
	background-color: 					rgba(0, 0, 0, 0.05);
	position: 							absolute;
	left: 								69.5%;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
}

.myProfile .header .generalInformation {
	max-width: 							70%;
	flex: 								1 0 70%;
	padding-right: 						60px;
}

.myProfile .header .generalInformation .headInformation {
	display: 							flex;
	align-items: 						center;
	gap: 								15px;
	margin-bottom: 						20px;
}

.myProfile .header .generalInformation .headInformation .profilePhotoContainer{
	width: 								80px;
    height: 							80px;
    border-radius: 						100px;
    border: 							5px solid transparent;
    background: 						var(--gradient) padding-box, var(--gradient) border-box;
	display: 							flex;
	align-items: 						center;
	justify-content: 					center;
	position: 							relative;
	overflow: 							hidden;
}

.myProfile .header .generalInformation .headInformation .profilePhotoContainer .inputProfilePhoto{
	position: 							absolute;
	top: 								0;
	left: 								0;
	right: 								0;
	bottom: 							0;
	opacity: 							0;
	cursor: 							pointer;
}

.myProfile .header .generalInformation .headInformation .profilePhotoContainer .profilePhoto{
	width: 								60px;
	height: 							60px;
	object-fit: 						contain;
	object-position: 					center center;
	pointer-events: 					none;
}

.myProfile .header .generalInformation .headInformation > h3 > span{
	font-size: 							12px;
	font-weight: 						400;
	background: 						var(--gradient) text;
	-webkit-text-fill-color: 			transparent;
}

.myProfile .header .generalInformation .headInformation .ratingValue{
	font-size: 							16px;
	display: 							flex;
	align-items: 						center;
	padding: 							5px 10px;
	font-weight: 						700;
	background: 						var(--gradient) text, var(--blue) padding-box;
	-webkit-text-fill-color: 			transparent;
}

.myProfile .header .generalInformation .headInformation .ratingValue .ratingImg{
	width: 								20px;
    height: 							20px;
    object-fit: 						contain;
    object-position: 					center center;
}

.myProfile .header .generalInformation .industriesInformation {
	display: 							flex;
	align-items: 						center;
	gap: 								10px;
	margin-bottom: 						20px;
}

.myProfile .header .generalInformation .industriesInformation .industriesContainer {
	flex: 								1 0 80%;
	display: 							flex;
	flex-flow: 							row nowrap;
	align-items: 						center;
	gap: 								5px;
	overflow: hidden;
}

.myProfile .header .generalInformation .industriesInformation .industriesContainer > span{
	font-size: 							12px;
	font-weight: 						700;
	background: 						var(--gradient) text, var(--darkBlue) padding-box;
	-webkit-text-fill-color: 			transparent;
	padding: 							10px 15px;
	text-wrap: 							nowrap;
	text-overflow: 						ellipsis;
	overflow: 							hidden;
}

.myProfile .header .generalInformation .industriesInformation .btnBgBlueTextGradient {
	padding: 							10px 25px;
}

.myProfile .header .generalInformation .description{
	width: 								80%;
	font-size: 							12px;
	opacity: 							0.8;
	margin-bottom: 						20px;
}

.myProfile .header .contactInformation{
	flex: 								1 0 30%;
	padding-left: 						50px;
}

.myProfile .header .contactInformation .cvpBadge{
	width: 								60px;
    height: 							60px;
    object-fit: 						cover;
    object-position: 					center center;
	margin-bottom: 						30px;
}

.myProfile .header .contactInformation .info{
	margin-bottom: 						25px;
}

.myProfile .header .contactInformation .info:last-of-type{
	margin-bottom: 						0;
}

.myProfile .header .contactInformation .info .infoTitle{
	font-size: 							12px;
	font-weight: 						700;
}

.myProfile .header .contactInformation .info .infoText{
	font-size: 							12px;
	opacity: 							0.6;
}

.supplierClientsModalMyProfile .modal-content,
.supplierIndustriesModalMyProfile .modal-content {
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.supplierClientsModalMyProfile .modal-body,
.supplierIndustriesModalMyProfile .modal-body {
	padding: 							50px 65px 35px;
}

.supplierClientsModalMyProfile .modal-body .commentTitle,
.supplierIndustriesModalMyProfile .modal-body .commentTitle {
	font-weight: 						700;
	margin-bottom: 						20px;
}

.supplierClientsModalMyProfile .modal-body .tableClients th:nth-of-type(1) {
	width: 								40%;
}

.supplierClientsModalMyProfile .modal-body .tableClients th:nth-of-type(2) {
	width: 								25%;
}

.supplierClientsModalMyProfile .modal-body .actions {
	opacity: 							1;
}

.supplierClientsModalMyProfile .modal-body .actions .btnBgBlueTextGradient {
	padding: 							10px 30px;
}

.supplierClientsModalMyProfile .modal-body .actionButton .btnBgBlueTextGradient,
.supplierIndustriesModalMyProfile .modal-body .actionButton .btnBgBlueTextGradient {
	margin-top: 						30px;
	margin-left: 						auto;
}

.supplierIndustriesModalMyProfile .modal-body .industriesContainer { 
	display: 							flex;
	flex-flow: 							row wrap;
	gap: 								10px;
}

.supplierIndustriesModalMyProfile .modal-body .industriesContainer .industry {
	flex: 								1 0 auto;
	font-size: 							16px;
	font-weight: 						700;
	text-align: 						center;
	padding: 							10px 15px;
	background: 						var(--gradient) text, var(--darkBlue) padding-box;
	-webkit-text-fill-color: 			transparent;
}

.myProfile .nav-tabs{
	padding-top: 						35px;
}

.myProfile .saveChanges{
	border: 							unset;
	border-radius: 						100px;
	background-color: 					var(--blue);
	padding: 							10px;
	transition: 						all 0.3s ease-in-out;
	position: 							fixed;
	right: 								30px;
	bottom: 							30px;
	z-index: 							999;
}

.myProfile .saveChanges:hover{
	background-color: 					var(--darkBlue);
}

.myProfile .actionButtons{
	display: 							flex;
	gap: 								15px;
	align-items: 						center;
	justify-content: 					flex-end;	
	margin-bottom: 						100px;
}

@media (max-width: 991px){
	/* MY PROFILE */
	.myProfile .header{
		flex-flow: 							column nowrap;
		padding: 							80px 35px;
		align-items: 						center;
	}
	
	.myProfile .header::before{
		content: 							none;
	}
	
	.myProfile .header .generalInformation {
		max-width: 							100%;
		flex: 								1 0 0%;
		padding-right: 						0;
		margin-bottom: 						35px;
	}
	
	.myProfile .header .generalInformation .headInformation {
		flex-flow: 							column nowrap;
		margin-bottom: 						15px;
	}
	
	.myProfile .header .generalInformation .headInformation .profilePhotoContainer{
		width: 								90px;
		height: 							90px;
	}
	
	.myProfile .header .generalInformation .headInformation .profilePhotoContainer .profilePhoto{
		width: 								70px;
		height: 							70px;
	}

	.myProfile .header .generalInformation .headInformation h3{
		font-size: 							26px;
		text-align: 						center;
	}
	
	.myProfile .header .generalInformation .industriesInformation {
		gap: 								15px;
		margin-bottom: 						15px;
		flex-flow: 							row wrap;
	}
	
	.myProfile .header .generalInformation .industriesInformation .industriesContainer {
		flex: 								1 0 100%;
		flex-flow: 							row wrap;
		justify-content: 					center;
	}
	
	.myProfile .header .generalInformation .industriesInformation .btnBgBlueTextGradient {
		width: 								100%;
	}
	
	.myProfile .header .generalInformation .description{
		width: 								100%;
		text-align: 						center;
		margin-bottom: 						0;
	}

	.myProfile .header .generalInformation > .btnBgBlueTextGradient{
		display: 							none;
	}
	
	.myProfile .header .contactInformation{
		flex: 								1 0 0;
		padding-left: 						0;
		margin-bottom: 						35px;
	}
	
	.myProfile .header .contactInformation .cvpBadge{
		margin: 							0 auto 15px;
	}
	
	.myProfile .header .contactInformation .info{
		text-align: 						center;
		margin: 							0 auto 15px;
	}
	
	.myProfile .header .contactInformation .info:last-of-type{
		margin: 							0 auto 0;
	}

	.myProfile .header > .btnBgBlueTextGradient {
		width: 								100%;
	}

	.supplierClientsModalMyProfile .modal-body,
	.supplierIndustriesModalMyProfile .modal-body {
		padding: 							30px 25px;
	}

	.supplierClientsModalMyProfile .modal-body .commentTitle,
	.supplierIndustriesModalMyProfile .modal-body .commentTitle {
		margin-bottom: 						30px;
	}

	.supplierClientsModalMyProfile .modal-body .tableClients {
		display: 							none;
	}

	.supplierClientsModalMyProfile .modal-body .mobileCientList .clientRow {
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
		padding: 							25px 0;
	}

	.supplierClientsModalMyProfile .modal-body .mobileCientList .clientRow:last-of-type {
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.supplierClientsModalMyProfile .modal-body .mobileCientList .clientRow .clientHeaderTitle {
		font-size: 							12px;
		font-weight: 						500;
		margin-bottom: 						10px;
	}

	.supplierClientsModalMyProfile .modal-body .mobileCientList .clientRow .clientValue {
		font-size: 							10px;
		font-weight: 						400;
	}

	.supplierClientsModalMyProfile .modal-body .mobileCientList .clientRow .clientValue > .btnBgBlueTextGradient {
		width: 								100%;
	}

	.supplierClientsModalMyProfile .modal-body .mobileCientList .clientRow .clientTitle {
		font-size: 							10px;
		font-weight: 						700;
		opacity: 							0.4;
		margin-bottom: 						10px;
	}

	.supplierClientsModalMyProfile .modal-body .mobileCientList .clientRow .clientTitle:last-of-type {
		margin-bottom: 						0;
	}

	.supplierClientsModalMyProfile .modal-body .actionButton .btnBgBlueTextGradient,
	.supplierIndustriesModalMyProfile .modal-body .actionButton .btnBgBlueTextGradient {
		width: 								100%;
		font-size: 							16px;
	}

	.supplierIndustriesModalMyProfile .modal-body .industriesContainer .industry {
		font-size: 							12px;
	}

	.myProfile .nav-tabs{
		padding-top: 						20px;
	}

	.myProfile .actionButtons{
		justify-content: 					center;	
	}
}