.no-select {
    -webkit-user-select:            none; /* Safari */
    -moz-user-select:               none; /* Firefox */
    -ms-user-select:                none; /* Internet Explorer/Edge */
    user-select:                    none; /* Propiedad estándar */
}
  
.warehouse-tab {
    margin-bottom:                  30px;
}

.warehouse-tab .tabs-container {
    display:                        flex;
    justify-content:                space-between;
    padding:                        10px;
    background-color:               #FFF;
}

.darkMode .warehouse-tab .tabs-container {
    background-color:               #181E24;
}


.warehouse-tab .tabs-container .customTabs {
    display:                        flex;
    gap:                            5px;
    overflow-x:                     auto;
}

.warehouse-tab .tabs-container .customTabs .customTab {
    display:                        flex;
    height:                         41px;
    align-items:                    center;
    padding:                        5px 15px;
    cursor:                         pointer;
    transition:                     all 0.3s ease-in-out;
}

.warehouse-tab .tabs-container .customTabs .customTab > span {
    font-size:                      12px;
    pointer-events:                 none;
    white-space:                    nowrap;
}

.warehouse-tab .tabs-container .customTabs .customTab.selected {
    background-color:               rgba(4, 26, 57, 1);
}

.darkMode .warehouse-tab .tabs-container .customTabs .customTab.selected {
    background-color:               #243D55;
}

.warehouse-tab .tabs-container .customTabs .customTab.selected > span {
    color:                          #FFF;
    font-weight:                    700;
}

.warehouse-tab .add {
    padding:                        10px 20px;
    white-space:                    nowrap;
}

.warehouse-tab .add::before {
    content:                        "";
    width:                          15px;
    height:                         15px;
    background:                     url(../../images/add.svg) center center no-repeat;
    background-size:                15px 15px;
    margin-right:                   5px;
}

.warehouse-tab .no-warehouse {
    display:                        flex;
    padding:                        20px;
    align-content:                  center;
    justify-content:                center;
}

/* Warehouse section */
.warehouse-container .warehouse-header {
    display:                        flex;
    gap:                            10px;
    align-items:                    center;
    padding:                        15px 30px;
    background-color:               rgba(4, 26, 57, 0.03);
}

.warehouse-container .warehouse-header > h5 {
    font-weight:                    700;
}

.warehouse-container .warehouse-header > img {
    width:                          15px;
    height:                         15px;
    cursor:                         pointer;
}


.warehouse-container .warehouse-info-section {
    display:                        grid;
    padding:                        30px;
    grid-template-columns:          minmax(200px, 1fr) minmax(200px, 2fr);
    row-gap:                        10px;
    column-gap:                     30px;
    margin-bottom:                  30px;
}



/* Table */
.table-section #add-product-button {
    margin:                         unset;
}

.table-section .tabs-container .customTabs .customTab {
    background-color:               #FFF;
}

.darkMode .table-section .tabs-container .customTabs .customTab {
    background-color:               #27343F;
}

.prodAsign-table .table-header,
.prodAsign-table .table-section,
.prodAsign-table .table-section .table-element {
    display:                        grid;
}

.prodAsign-table .table-header {
    background-color:               rgba(4, 26, 57, 0.03);
    padding:                        20px 30px;
}

.prodAsign-table .table-header .header-item-container {
    display:                        flex;
    flex-wrap:                      nowrap;
    align-items:                    center;
    gap:                            5px;
}

.prodAsign-table .table-header .header-item {
    font-weight:                    700;
    font-size:                      12px;
    white-space:                    nowrap;
}

.prodAsign-table .table-header .header-item-container > img {
    cursor:                         pointer;
}

.prodAsign-table .table-section {
    grid-auto-rows:                 auto;
    margin-bottom:                  50px;
    background-color:               unset;
    padding:                        0;
}

.prodAsign-table .table-header.Products,
.prodAsign-table .table-section .table-element.Products {
    /* grid-template-columns:          minmax(80px, 1fr) repeat(2, minmax(80px, 2fr)) minmax(80px, 2.5fr); */
    grid-template-columns:          minmax(80px, 1fr)minmax(80px, 2fr) minmax(80px, 2.5fr);
    column-gap:                     26px;
}

.darkMode .prodAsign-table .table-header.Products {
    background-color:               #27343F;
}

.prodAsign-table .table-section .table-element {
    padding:                        25px 30px;
    border-bottom:                  1px solid rgba(0, 0, 0, 0.03);
    align-items:                    center;
    position:                       relative;
}

.prodAsign-table .table-section .table-element span {
    font-size:                      14px;
}

.prodAsign-table .table-section .table-element .element-1.withCursor {
    cursor:                         pointer;
}

.prodAsign-table .table-section .table-element.Products span:nth-child(1),
.prodAsign-table .table-section .table-element.Products span:nth-child(3) {
    color:                          rgba(153, 153, 153, 1);
}

.prodAsign-table .table-section .table-element.Products .element-header {
    font-weight:                    500;
}

.prodAsign-table .table-section .table-element.Products .element-2 {
    padding:                        5px 10px;
    background-color:               rgba(226, 255, 248, 1);
    color:                          rgba(24, 194, 174, 1);
    width:                          fit-content;
}

.prodAsign-table .table-header.Purchase.orders,
.prodAsign-table .table-section .table-element.Purchase.orders {
    grid-template-columns:          minmax(80px, 1fr)  minmax(15px, 2fr) repeat(3, minmax(80px, 1fr))
}

.prodAsign-table .table-section .table-element.Purchase.orders > span:nth-child(1),
.prodAsign-table .table-section .table-element.Purchase.orders > span:nth-child(3),
.prodAsign-table .table-section .table-element.Purchase.orders > span:nth-child(4) {
    color:                          rgba(153, 153, 153, 1);
}

.prodAsign-table .table-section .table-element.Purchase.orders > span:nth-child(5) {
    padding:                        5px 10px;
    background-color:               rgba(226, 255, 248, 1);
    color:                          rgba(24, 194, 174, 1);
    width:                          fit-content;
}

.prodAsign-table .table-section .table-element.Purchase.orders > span:nth-child(5).orange {
    padding:                        5px 10px;
    background-color:               rgba(255, 241, 224, 1);
    color:                          rgba(255, 145, 65, 1);
    width:                          fit-content;
}

.prodAsign-table .table-section .table-element.Purchase.orders > span:nth-child(5).red {
    padding:                        5px 10px;
    background-color:               rgba(255, 218, 229, 1);
    color:                          rgba(255, 49, 111, 1);
    width:                          fit-content;
}

.prodAsign-table .table-section .table-element .inputContainer {
    width:                          calc(100% - 20px);
}

.prodAsign-table .table-section .table-element .inputContainer > input:disabled{
    background-color:               rgba(4, 26, 57, 0.03);
}

.prodAsign-table .table-section .table-element.Purchase.orders .inputContainer {
    display:                        none;
}

.prodAsign-table .table-section .table-element.Products .element-variable {
    display:                        none;
}

.prodAsign-table .table-section .table-element .more-options-container {
    position:                       absolute;
    right:                          30px;
    top:                            50%;
    transform:                      translateY(-50%);
    cursor:                         pointer;
}

.prodAsign-table .table-section .table-element .more-options-container.not-allowed {
    cursor:                         not-allowed;
}
    
.prodAsign-table .table-section .table-element .more-options-container .more-options {
    position:                       absolute;
    left:                           50%;
    transform:                      translateX(-50%);
    bottom:                         100%;
    background-color:               #FFF;
    padding:                        5px;
    border-radius:                  10px;
    opacity:                        0;
    visibility:                     hidden;
    transition:                     all 0.3s ease-in-out;
}

.prodAsign-table .table-section .table-element .more-options-container .more-options.show {
    opacity:                        1;
    visibility:                     visible;
}

.prodAsign-table .table-section .table-element .more-options-container .more-options span {
    white-space:                    nowrap;
    border-radius:                  10px;
    padding:                        10px;
    transition:                     all 0.3s ease-in-out;
    color:                          rgba(0, 0, 0, 0.5);
}

.prodAsign-table .table-section .table-element .more-options-container .more-options span:hover {
    color:                          rgba(0, 0, 0, 1);
    background-color:               rgba(4, 26, 57, 0.03);
}

/* Warehouse Modal */
.warehouse-modal-actions {
    display:                        flex;
    justify-content:                flex-end;
    margin-top:                     20px;
    gap:                            10px;
}

.warehouse-modal-actions #action-warehouse,
.warehouse-modal-actions #action-product {
    font-size:                      16px;
}

.warehouse-modal-actions #action-warehouse:disabled {
    cursor: 							not-allowed;
	background-color: 					rgba(0, 0, 0, 0.1);
	opacity: 							0.5;
}

.darkMode .warehouse-modal-actions #action-warehouse:disabled {
	background-color: 					#27343F;
}

#warehouse-header {
    margin-top:                     40px;
}

#warehouse-header > .modal-title {
    font-size:                      23px;
}

#delete-warehouse {
    background:                     var(--blue);
    padding:                        10px;
    cursor:                         pointer;
}

.warehouses-modal-inputs {
    display:                        grid;
    grid-template-columns:          repeat(2, minmax(130px, 1fr));
    column-gap:                     10px;
    row-gap:                        10px;
}

.warehouses-modal-inputs .warehouse-input-container {
    grid-column:                    1 / -1;
}

.warehouses-modal-inputs .warehouse-input-container > label {
    font-size:                      12px;
    font-weight:                    700;
}

#warehouse-input-city,
#warehouse-input-state {
    grid-column:                    span 1;
}

/* Product Modal */
.product-modal .searchContainer {
    margin-bottom:                  20px;
}

.product-modal .searchContainer > input {
    background-color:               rgba(4, 26, 57, 0.03);
}

.product-modal .products-list {
    display:                        flex;
    flex-direction:                 column;
    padding:                        30px;
    gap:                            30px;
    background-color:               rgba(4, 26, 57, 0.03);
    min-height:                     400px;
}

.product-modal .products-list {
    display:                        flex;
    flex-direction:                 column;
    padding:                        20px;
    gap:                            30px;
    background-color:               rgba(4, 26, 57, 0.03);
    min-height:                     400px;
}

.product-modal .products-list::-webkit-scrollbar {
	width: 						    3px;
	height: 						3px;
}

.product-modal .products-list::-webkit-scrollbar-track {
	background: 					rgba(0, 0, 0, 0.05);
}

.product-modal .products-list::-webkit-scrollbar-thumb {
	background: 					var(--darkBlue);
}

.product-modal .product-container {
    display:                        flex;
    gap:                            10px;
    align-items:                    flex-start;
    cursor:                         pointer;
}

.product-modal .product-container > input {
    margin:                         2px 0 0 0;
}

.product-modal .product-container .product-name {
    font-size:                      12px;
    font-weight:                    600;
}

.product-modal .product-container .product-barcode {
    font-size:                      12px;
    opacity:                        0.4;
}

/* purchase order modal */

.po-modal .modal-dialog {
    max-width:                              1200px !important;
}

.po-modal .modal-content {
    padding-bottom:                         30px !important;
    background-color:                       rgba(245, 246, 247, 1) !important
}

.po-modal.darkMode .modal-content {
    background-color:                       rgba(19, 30, 41, 1) !important
}

.po-modal .modal-header {
    display:                                flex;
    flex-direction:                         row;
    align-items:                            flex-start;
    gap:                                    10px;
    max-width:                              100%;
    margin-top:                             50px;
}

.po-modal .modal-header h4{
    font-weight:                            700;
}

.po-modal .modal-header .order-status-container {
    display:                                flex;
    background-color:                       white;
    padding:                                0 10px 0 10px;
    justify-content:                        center;
    align-items:                            center;
    position:                               relative;
    cursor:                                 pointer;
    min-width:                              145px;
}

.darkMode.po-modal .modal-header .order-status-container {
    background-color:                       rgba(32, 43, 52, 1);
}

.po-modal .modal-header .order-top-options {
    display:                                flex;
    height:                                 38px;
}

.po-modal .modal-header .order-top-options .order-status-container .order-status {
    display:                                flex;
    flex-direction:                         row;
    align-items:                            center;
    gap:                                    5px;
    color:                                  rgba(8, 209, 161, 1);
    font-size:                              12px;
    padding:                                0 5px 0 5px;
}

.po-modal .modal-header .order-top-options .order-status-container .order-status::after {
    content:                                "";
    width:                                  5px;
    height:                                 5px;
    border-radius:                          50px;
    background-color:                       rgba(8, 209, 161, 1);
}

.po-modal .modal-header .order-top-options .order-status-container .order-status-label {
    font-size:                              12px;
    color:                                  rgba(0, 0, 0, 0.5);
}

.po-modal .modal-header .order-top-options .order-status-container .order-status.orange {
    color:                                  rgba(255, 145, 65, 1);
}

.po-modal .modal-header .order-top-options .order-status-container .order-status.orange::after {
    background-color:                       rgba(255, 145, 65, 1);
}

.po-modal .modal-header .order-top-options .order-status-container .order-status.red {
    color:                                  rgba(255, 49, 111, 1);
}
    
.po-modal .modal-header .order-top-options .order-status-container .order-status.red::after {
    background-color:                       rgba(255, 49, 111, 1);
}

.po-modal .modal-header .order-top-options .order-status-container .status-button-icon {
    background: 							var(--gradient);
    mask-size:                              15px 15px;
    mask: 									url("../../../assets/images/arrowForward.svg") center center no-repeat;
    -webkit-mask-size:                      11px 20px;
    width:                                  20px;
    height:                                 10px;
    object-fit:                             contain;
    transform: 							    rotate(90deg);
    margin-left:                            5px;
}

.po-modal .modal-header .order-top-options .order-status-container .status-options-modal { 
    position:                               absolute;
    top:                                    100%;
    left:                                   0;
    background-color:                       white;
    width:                                  100%;
    z-index:                                2;
}

.darkMode.po-modal .modal-header .order-top-options .order-status-container .status-options-modal { 
    background-color:                       rgba(32, 43, 52, 1);

}

.po-modal .modal-header .order-top-options .order-status-container .status-options-modal .status-option { 
    padding:                                5px 15px 5px 15px;
    font-size:                              12px;
    cursor:                                 pointer;
}

.po-modal .modal-header .order-top-options .order-status-container .status-options-modal .status-option:hover { 
    background: 						    var(--gradient), transparent;
	background-clip: 					    text, padding-box;
	-webkit-background-clip: 			    text, padding-box;
	-webkit-text-fill-color: 			    transparent;
    font-size:                              12px;
}

.po-modal .modal-header .order-top-options .download-po-button {
    display:                                flex;
    align-items:                            center;
    background-color:                       var(--blue);
    color:                                  black;
    border:                                 none;
    padding:                                10px 20px 10px 20px;
    cursor:                                 pointer;
    font-size:                              14px;
    margin-left:                            20px;
}

.po-modal .modal-header .order-top-options .download-po-button .download-po-button-text {
    background: 						    var(--gradient), transparent;
	background-clip: 					    text, padding-box;
	-webkit-background-clip: 			    text, padding-box;
	-webkit-text-fill-color: 			    transparent;
    font-size:                              12px;
}

.po-modal .modal-header .order-top-options .download-po-button .download-po-button-icon {
    background: 							var(--gradient);
    mask: 									url("../../../assets/images/download.svg") center center no-repeat;
    width: 									15px;
    height: 								15px;
    margin-left:                            5px;
}

.po-modal .modal-body {
    display:                                flex;
    flex:                                   1;
    flex-direction:                         column;
    gap:                                    30px;
}

.po-modal .modal-body .flex-column {
    display:                                flex;
    flex-direction:                         column;
}

.po-modal .modal-body .order-information-main-container {
    display:                                flex;
    flex-direction:                         column;
    flex:                                   1;
}

.po-modal .modal-body .order-information-subcontainer {
    display:                                flex;
    flex-direction:                         row;
    flex:                                   1;
}

.po-modal .modal-body .order-information-main-container .comments-label {
    display:                                flex;
    flex-direction:                         column;
    font-size:                              12px;
    font-weight:                            700;
    padding-bottom:                         10px;
}

.po-modal .modal-body .order-information-main-container .order-information-subcontainer .download-po-button-mobile {
    display:                                none;
}

.po-modal .modal-body .order-information-subcontainer-items:first-of-type {
    display:                                flex;
    flex-direction:                         column;
    flex:                                   1;
    margin-right:                           20px;
}

.po-modal .modal-body .order-information-subcontainer-items {
    display:                                flex;
    flex-direction:                         column;
    flex:                                   2;
}

.po-modal .modal-body .order-information-subcontainer-items > h5 {
    font-size:                              12px;
    font-weight:                            700;
    margin-bottom:                          15px;
}

.po-modal .modal-body .order-information-subcontainer .comments-container {
    display:                                flex;
    flex-direction:                         column;
    flex:                                   1;
    margin-right:                           20px;
    max-height:                             175px;
    overflow-y:                             auto;
}

/* Personalización del scrollbar */
.comments-container::-webkit-scrollbar {
    width:                                  5px;
}

.comments-container::-webkit-scrollbar-track {
    background:                             rgba(0, 0, 0, 0.05);
    height:                                 50px;
}

.comments-container::-webkit-scrollbar-thumb {
    background:                             rgba(4, 26, 57, 1);
}

.po-modal .modal-body .order-information-subcontainer .add-comment-container {
    display:                                flex;
    flex-direction:                         column;
    flex:                                   0.5;
}

.po-modal .modal-body .order-information-subcontainer .add-comment-container .add-comment-textarea {
    height:                                 125px;
    margin-bottom:                          10px;
}

.po-modal .modal-body .order-information-subcontainer .add-comment-container .add-comment-button {
    background-color:                       rgba(0, 163, 255, 0.1);
    padding:                                10px 15px;
    border:                                 none;
    cursor:                                 pointer;
    transition:                             background-color 0.3s ease;
}

.po-modal .modal-body .order-information-subcontainer .add-comment-container .add-comment-button .add-comment-button-text {
    background: 						    var(--gradient), transparent;
	background-clip: 					    text, padding-box;
	-webkit-background-clip: 			    text, padding-box;
	-webkit-text-fill-color: 			    transparent;
    font-size:                              12px;
}

.po-modal .modal-body .order-information-container {
    flex-direction:                         column;
    padding:                                15px 30px 15px 30px;
    background-color:                       rgba(4, 26, 57, 0.03);
    margin-bottom:                          10px;
}

.darkMode.po-modal .modal-body .order-information-container {
    background:                             rgba(32, 43, 52, 1);
}

.po-modal .modal-body .order-information-container > span {
    display:                                flex;
    flex-direction:                         column;
    font-size:                              12px;
    font-weight:                            700;
}

.po-modal .modal-body .order-information-container > span::after {
    font-size:                              12px;
    color:                                  rgba(0, 0, 0, 0.5);
}

.po-modal .modal-body .order-information-container .rfc {
    font-weight:                            400;
}

.po-modal .modal-body .order-information-container .rfc::after {
    font-weight:                            400;
    content:                                "RFC";
}

.po-modal .modal-body .order-information-container .supplier-name {
    font-weight:                            400;
}

.po-modal .modal-body .order-information-container .supplier-name::after {
    font-weight:                            400;
    content:                                "Name";
}

.po-modal .modal-body .order-information-container .address {
    font-weight:                            400;
}
    
.po-modal .modal-body .order-information-container .folio::after {
    font-weight:                            400;
    content:                                "Folio";
}

.po-modal .modal-body .order-information-container .location::after {
    font-weight:                            400;
    content:                                "Location";
}

.po-modal .modal-body .order-information-container .address::after {
    content:                                "Address";
}

.po-modal .modal-body .order-information-container .comment {
    font-weight:                            400;
}

.po-modal .modal-body .product-list-container {
    width:                                  100%;
}

.po-modal .modal-body .product-list-container > h5 {
    font-size:                              12px;
    font-weight:                            700;
    margin-bottom:                          15px;
}

.po-modal .modal-body .product-list-container .product-list {
    overflow-y:                             auto;
    background-color:                       rgba(4, 26, 57, 0.03);
    padding-inline:                         20px;
}

.darkMode.po-modal .modal-body .product-list-container .product-list {
    background:                             rgba(32, 43, 52, 1);

}

.po-modal .modal-body .product-list-container .product-list-header {
    display:                                flex;
    flex:                                   2;
    padding-inline:                         20px;
    padding-top:                            20px;
    padding-bottom:                         10px;
}

.po-modal .modal-body .product-list-container .product-list-header .product-list-header-item:nth-child(1) {
    display:                                flex;
    flex:                                   2;
    min-width:                              fit-content;
    max-width:                              200px;
    margin:                                 0 10px 0 10px;
}
.po-modal .modal-body .product-list-container .product-list-header .product-list-header-item:nth-child(2) {
    display:                                flex;
    flex:                                   2;
    min-width:                              fit-content;
    max-width:                              700px;
    margin:                                 0 10px 0 10px;
}
.po-modal .modal-body .product-list-container .product-list-header .product-list-header-item:nth-child(3) {
    display:                                flex;
    flex:                                   2;
    min-width:                              fit-content;
    max-width:                              400px;
    justify-content:                        flex-end;
    margin:                                 0 10px 0 10px;
}
.po-modal .modal-body .product-list-container .product-list-header .product-list-header-item:nth-child(4) {
    display:                                flex;
    flex:                                   2;
    min-width:                              fit-content;
    max-width:                              400px;
    justify-content:                        flex-end;
    margin:                                 0 10px 0 10px;
}
.po-modal .modal-body .product-list-container .product-list-header .product-list-header-item:nth-child(5) {
    display:                                flex;
    flex:                                   2;
    min-width:                              fit-content;
    max-width:                              400px;
    justify-content:                        flex-end;
    margin:                                 0 10px 0 10px;
}

.po-modal .modal-body .product-list-container .product-list-header > h5 {
    font-size:                              12px;
    font-weight:                            700;
    margin-bottom:                          15px;
}

.po-modal .modal-body .product-list-container .products-list::-webkit-scrollbar {
    width: 						            3px;
	height: 						        3px;
}
    
.po-modal .modal-body .product-list-container .products-list::-webkit-scrollbar-track {
    background: 					        rgba(0, 0, 0, 0.05);
}
    
.po-modal .modal-body .product-list-container .products-list::-webkit-scrollbar-thumb {
    background: 					        var(--darkBlue);
}

.po-modal .modal-body .product-list-container .product-list .product {
    display:                                flex;
    flex:                                   1;
    align-items:                            center;
    padding:                                20px 0;
    border-bottom:                          1px solid rgba(0, 0, 0, 0.05);
}

.po-modal .modal-body .product-list-container .product-list .product > h5 {
    font-size:                      12px;
    font-weight:                    700;
    margin-bottom:                  15px;
}

.po-modal .modal-body .product-list-container .product-list .product-price {
    color:                          rgba(0, 0, 0, 0.4);
}

.po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper:nth-child(1) {
    display:                        flex;
    flex:                           2;
    max-width:                      200px;
    margin:                         0 10px 0 10px;
}

.po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper:nth-child(2) {
    display:                        flex;
    flex:                           2;
    max-width:                      700px;
    margin:                         0 10px 0 10px;
}

.po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper:nth-child(3) {
    display:                        flex;
    flex:                           2;
    max-width:                      400px;
    justify-content:                flex-end;
    margin:                         0 10px 0 10px;

    font-weight:                    500;
}

.po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper:nth-child(4) {
    display:                        flex;
    flex:                           2;
    min-width:                      fit-content;
    max-width:                      400px;
    justify-content:                flex-end;
    margin:                         0 10px 0 10px;
}

.po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper:nth-child(5) {
    display:                        flex;
    flex:                           2;
    min-width:                      fit-content;
    max-width:                      400px;
    justify-content:                flex-end;
    margin:                         0 10px 0 10px;
}

.po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper .product-item {
    font-size:                      12px;
}
            
.po-modal .modal-body .product-list-container .total-cost {
    display:                        flex;
    align-items:                    center;
    justify-content:                space-between;
    padding:                        20px 25px 20px 25px;
    background: 				    var(--gradient), transparent;
	background-clip: 			    text, padding-box;
	-webkit-background-clip: 	    text, padding-box;
	-webkit-text-fill-color: 	    transparent;
}

.po-modal .modal-body .product-list-container .total-cost > span {
    font-size:                      12px;
}

@media (width <= 991px) {
    .warehouse-tab .tabs-container {
        margin:                     unset;
    }

    .warehouse-tab .add {
        margin:                     20px 0 10px auto;
    }
    
    .warehouse-container .warehouse-info-section {
        grid-template-columns:      1fr;
        margin-bottom:              unset;
    }

    /* Warehouse modal mobile */
    .warehouse-modal .modal-content,
    .product-modal .modal-content {
        border-radius:              35px;
    }

    .warehouse-modal .modal-content .modal-title {
        order:                      unset;
    }

    .warehouse-modal .modal-header {
        flex-direction:             row;
    }

    .warehouses-modal-inputs {
        grid-template-columns:      1fr;
    }

    .warehouse-modal-actions {
        flex-direction:             column;
    }

    .warehouse-modal-actions .btnBgTextRed {
        order:                      2;
    }

    /* Product modal mobile */
    .product-modal .products-list {
        background-color:           transparent;
        min-height:                 350px;
    }

    .warehouse-tab .tabs-container .customTabs .customTab {
        height:                     38px;
    }

    /* Table mobile*/
    .prodAsign-table .table-section {
        padding:                    0;
        row-gap:                    10px;
    }

    .prodAsign-table .table-section .table-element .element-1 {
        display:                    flex;
        flex-direction:             column;
    }


    /* Table mobile for Products*/
    .prodAsign-table .table-section .table-element.Products {
        grid-template-columns:      repeat(3, 1fr);
        padding:                    0;
        border-bottom:              unset;
    }

    .prodAsign-table .table-section .table-element.Products .element-header {
        grid-column:                span 3;
        background-color:           rgba(4, 26, 57, 0.03);
        grid-row:                   1 / 1;
        padding:                    10px 20px;
        font-weight:                700;
    }

    .prodAsign-table .table-section .table-element .element-1,
    .prodAsign-table .table-section .table-element .element-2 {
        padding:                    20px;
    }

    .prodAsign-table .table-section .table-element.Products .element-1 {
        grid-column:                span 2;
    }

    .prodAsign-table .table-section .table-element .element-1::after {
        content:                    "ID";
        font-size:                  10px;
        font-weight:                700;
        color:                      rgba(0, 0, 0, 0.4);
    }

    .prodAsign-table .table-section .table-element .element-2 {
        display:                    flex;
        flex-direction:             column;
        justify-self:               center;
    }

    .prodAsign-table .table-section .table-element .inputContainer {
        display:                    flex;
        flex-direction:             column;
        grid-row:                   3 / 3;
        grid-column:                1 / -1;
        width:                      calc(100% - 20px);
        justify-self:               center;
    }

    .prodAsign-table .table-section .table-element .inputContainer::before {
        content:                    "Stock";
        font-size:                  10px;
        font-weight:                700;
        color:                      rgba(0, 0, 0, 0.4);
    }

    /* Table mobile for Purchase Orders*/
    .prodAsign-table .table-section .table-element.Purchase.orders {
        grid-template-columns:      repeat(2, 1fr);
        padding:                    0;
        border-bottom:              unset;
    }

    .prodAsign-table .table-section .table-element.Purchase.orders .element-header {
        grid-column:                span 2;
        background-color:           rgba(4, 26, 57, 0.03);
        grid-row:                   1 / 1;
        padding:                    10px 20px;
        font-weight:                700;
    }

    .prodAsign-table .table-section .table-element.Purchase.orders .element-po {
        grid-row:                   2 / 2;
        grid-column:                2 / 2;
        justify-self:               center;
    }

    .prodAsign-table .table-section .table-element.Purchase.orders .element-variable {
        display:                    flex;
        color:                      rgba(0, 0, 0, 1) !important;
        flex-direction:             column;
        padding:                    0 20px 20px;
    }

    .prodAsign-table .table-section .table-element.Purchase.orders .element-variable::after {
        content:                    "Category";
        font-size:                  10px;
        font-weight:                700;
        color:                      rgba(0, 0, 0, 0.4);
    }
        
    .prodAsign-table .table-section .table-element.Purchase.orders .element-2 {
        color:                      rgba(0, 0, 0, 1) !important;
        padding:                    0 20px 20px;
    }

    .prodAsign-table .table-section .table-element.Purchase.orders .element-2::after {
        content:                    "Order date";
        font-size:                  10px;
        font-weight:                700;
        color:                      rgba(0, 0, 0, 0.4);
    }

    .prodAsign-table .table-section .table-element .more-options-container {
        position:                   absolute;
        right:                      20px;
        top:                        10px;
        transform:                  unset;
    }

    .prodAsign-table .table-section .table-element .more-options-container > img {
        transform:                  rotate(90deg);
    }

    .prodAsign-table .table-section .table-element .more-options-container .more-options {
        bottom:                     calc(100% + 5px);
        transform:                  translateX(-75%);
    }

    /*Order modal for Purchase Order */
    .po-modal .modal-dialog {
        display:                                flex;
        flex:                                   1;
        justify-content:                        center;
    }
    
    .po-modal .modal-content {
        padding-bottom:                         30px !important;
        background-color:                       rgba(245, 246, 247, 1) !important;
        width:                                  90%;
        height:                                 100%;
        overflow-y:                             auto;
    }
    
    .po-modal .modal-header {
        display:                                flex;
        flex-direction:                         column-reverse;
        align-items:                            flex-start;
        gap:                                    10px;
        max-width:                              100%;
        margin-top:                             50px;
    }
    
    .po-modal .modal-header h4{
        font-weight:                            700;
    }
    
    .po-modal .modal-header .order-status-container {
        display:                                flex;
        background-color:                       white;
        padding:                                10px;
        justify-content:                        center;
        align-items:                            center;
        position:                               relative;
        cursor:                                 pointer;
        width:                                  100%;
        min-width:                              0;
    }
    
    .po-modal .modal-header .order-top-options {
        display:                                flex;
        width:                                  100%;
    }
    
    .po-modal .modal-header .order-top-options .order-status-container .order-status {
        display:                                flex;
        flex-direction:                         row;
        align-items:                            center;
        gap:                                    5px;
        color:                                  rgba(8, 209, 161, 1);
        font-size:                              12px;
        padding:                                0 5px 0 5px;
    }
    
    .po-modal .modal-header .order-top-options .order-status-container .order-status::after {
        content:                                "";
        width:                                  5px;
        height:                                 5px;
        border-radius:                          50px;
        background-color:                       rgba(8, 209, 161, 1);
    }
    
    .po-modal .modal-header .order-top-options .order-status-container .order-status-label {
        font-size:                              12px;
        color:                                  rgba(0, 0, 0, 0.5);
    }
    
    .po-modal .modal-header .order-top-options .order-status-container .order-status.orange {
        color:                                  rgba(255, 145, 65, 1);
    }
    
    .po-modal .modal-header .order-top-options .order-status-container .order-status.orange::after {
        background-color:                       rgba(255, 145, 65, 1);
    }
    
    .po-modal .modal-header .order-top-options .order-status-container .order-status.red {
        color:                                  rgba(255, 49, 111, 1);
    }
        
    .po-modal .modal-header .order-top-options .order-status-container .order-status.red::after {
        background-color:                       rgba(255, 49, 111, 1);
    }
    
    .po-modal .modal-header .order-top-options .order-status-container .status-button-icon {
        background: 							var(--gradient);
        mask-size:                              15px 15px;
        mask: 									url("../../../assets/images/arrowForward.svg") center center no-repeat;
        -webkit-mask-size:                      11px 20px;
        width:                                  20px;
        height:                                 10px;
        object-fit:                             contain;
        transform: 							    rotate(90deg);
        margin-left:                            5px;
    }
    
    .po-modal .modal-header .order-top-options .order-status-container .status-options-modal { 
        position:                               absolute;
        top:                                    100%;
        left:                                   0;
        background-color:                       white;
        width:                                  100%;
        z-index:                                2;
    }
    
    .po-modal .modal-header .order-top-options .order-status-container .status-options-modal .status-option { 
        padding:                                5px 15px 5px 15px;
        font-size:                              12px;
        cursor:                                 pointer;
    }
    
    .po-modal .modal-header .order-top-options .order-status-container .status-options-modal .status-option:hover { 
        background: 						    var(--gradient), transparent;
        background-clip: 					    text, padding-box;
        -webkit-background-clip: 			    text, padding-box;
        -webkit-text-fill-color: 			    transparent;
        font-size:                              12px;
    }
    
    .po-modal .modal-header .order-top-options .download-po-button {
        display:                                none;
    }
    
    .po-modal .modal-body {
        display:                                flex;
        flex:                                   1;
        flex-direction:                         column;
        gap:                                    30px;
        background-color:                       rgba(245, 246, 247, 1);
    }
    
    .po-modal .modal-body .flex-column {
        display:                                flex;
        flex-direction:                         column;
    }
    
    .po-modal .modal-body .order-information-main-container {
        display:                                flex;
        flex-direction:                         column;
        flex:                                   1;
    }
    
    .po-modal .modal-body .order-information-subcontainer {
        display:                                flex;
        flex-direction:                         column;
        flex:                                   1;
    }
    
    .po-modal .modal-body .order-information-main-container .comments-label {
        display:                                flex;
        flex-direction:                         column;
        font-size:                              12px;
        font-weight:                            700;
        padding:                                60px 0 10px 0;
    }
    
    .po-modal .modal-body .order-information-subcontainer-items:first-of-type {
        display:                                flex;
        flex-direction:                         column;
        flex:                                   1;
        margin-right:                           0px;
    }
    
    .po-modal .modal-body .order-information-subcontainer-items {
        display:                                flex;
        flex-direction:                         column;
        flex:                                   2;
    }
    
    .po-modal .modal-body .order-information-subcontainer-items > h5 {
        font-size:                              12px;
        font-weight:                            700;
        margin-bottom:                          15px;
    }
    
    .po-modal .modal-body .order-information-subcontainer .comments-container {
        display:                                flex;
        flex-direction:                         column;
        flex:                                   1;
        margin-right:                           0px;
        margin-bottom:                          10px;
        max-height:                             175px;
        overflow-y:                             auto;
    }

/* */
    .comments-container::-webkit-scrollbar {
        width:                                  5px;
    }

    .comments-container::-webkit-scrollbar-track {
        background:                             rgba(0, 0, 0, 0.05);
        height:                                 50px;
    }

    .comments-container::-webkit-scrollbar-thumb {
        background:                             rgba(4, 26, 57, 1);
    }

    .po-modal .modal-body .order-information-subcontainer .add-comment-container {
        display:                                flex;
        flex-direction:                         column;
        flex:                                   0.5;
    }

    .po-modal .modal-body .order-information-subcontainer .add-comment-container .add-comment-textarea {
        height:                                 125px;
        margin-bottom:                          10px;
    }

    .po-modal .modal-body .order-information-subcontainer .add-comment-container .add-comment-button {
        background-color:                       rgba(0, 163, 255, 0.1);
        padding:                                10px 15px;
        border:                                 none;
        cursor:                                 pointer;
        transition:                             background-color 0.3s ease;
    }

    .po-modal .modal-body .order-information-subcontainer .add-comment-container .add-comment-button .add-comment-button-text {
        background: 						    var(--gradient), transparent;
        background-clip: 					    text, padding-box;
        -webkit-background-clip: 			    text, padding-box;
        -webkit-text-fill-color: 			    transparent;
        font-size:                              12px;
    }

    .po-modal .modal-body .order-information-container {
        flex-direction:                         column;
        padding:                                15px 30px 15px 30px;
        background-color:                       rgba(4, 26, 57, 0.03);
        margin-bottom:                          10px;
    }

    .po-modal .modal-body .order-information-container > span {
        display:                                flex;
        flex-direction:                         column;
        font-size:                              12px;
        font-weight:                            700;
    }

    .po-modal .modal-body .order-information-container > span::after {
        font-size:                              12px;
        color:                                  rgba(0, 0, 0, 0.5);
    }

    .po-modal .modal-body .order-information-container .rfc {
        font-weight:                            400;
    }

    .po-modal .modal-body .order-information-container .rfc::after {
        font-weight:                            400;
        content:                                "RFC";
        margin-bottom:                          10px;
    }

    .po-modal .modal-body .order-information-container .supplier-name {
        font-weight:                            400;
    }

    .po-modal .modal-body .order-information-container .supplier-name::after {
        font-weight:                            400;
        content:                                "Name";
    }

    .po-modal .modal-body .order-information-container .address {
        font-weight:                            400;
    }
        
    .po-modal .modal-body .order-information-container .folio::after {
        font-weight:                            400;
        content:                                "Folio";
    }

    .po-modal .modal-body .order-information-container .location::after {
        font-weight:                            400;
        content:                                "Location";
        margin-bottom:                          10px;
    }

    .po-modal .modal-body .order-information-container .address::after {
        content:                                "Address";
    }

    .po-modal .modal-body .order-information-container .comment {
        font-weight:                            400;
    }

    .po-modal .modal-body .product-list-container {
        display:                                flex;
        flex-direction:                         column;
        flex:                                   1;
    }

    .po-modal .modal-body .product-list-container > h5 {
        font-size:                              12px;
        font-weight:                            700;
        margin-bottom:                          15px;
    }

    .po-modal .modal-body .product-list-container .product-list {
        overflow-y:                             auto;
        background-color:                       rgba(245, 246, 247, 1);
        padding-inline:                         20px;
    }

    .po-modal .modal-body .product-list-container .product-list-header {
        display:                                none;
        flex:                                   2;
        padding-inline:                         20px;
        padding-top:                            20px;
        padding-bottom:                         10px;
    }

    .po-modal .modal-body .product-list-container .product-list-header .product-list-header-item:nth-child(1) {
        display:                                flex;
        flex:                                   2;
        min-width:                              fit-content;
        max-width:                              200px;
        margin:                                 0 10px 0 10px;
        
    }
    .po-modal .modal-body .product-list-container .product-list-header .product-list-header-item:nth-child(2) {
        display:                                flex;
        flex:                                   2;
        min-width:                              fit-content;
        max-width:                              700px;
        margin:                                 0 10px 0 10px;
    }
    .po-modal .modal-body .product-list-container .product-list-header .product-list-header-item:nth-child(3) {
        display:                                flex;
        flex:                                   2;
        min-width:                              fit-content;
        max-width:                              400px;
        justify-content:                        flex-end;
        margin:                                 0 10px 0 10px;
    }
    .po-modal .modal-body .product-list-container .product-list-header .product-list-header-item:nth-child(4) {
        display:                                flex;
        flex:                                   2;
        min-width:                              fit-content;
        max-width:                              400px;
        justify-content:                        flex-end;
        margin:                                 0 10px 0 10px;
    }
    .po-modal .modal-body .product-list-container .product-list-header .product-list-header-item:nth-child(5) {
        display:                                flex;
        flex:                                   2;
        min-width:                              fit-content;
        max-width:                              400px;
        justify-content:                        flex-end;
        margin:                                 0 10px 0 10px;
    }

    .po-modal .modal-body .product-list-container .product-list-header > h5 {
        font-size:                              12px;
        font-weight:                            700;
        margin-bottom:                          15px;
    }

    .po-modal .modal-body .product-list-container .products-list::-webkit-scrollbar {
        width: 						            3px;
        height: 						        3px;
    }
        
    .po-modal .modal-body .product-list-container .products-list::-webkit-scrollbar-track {
        background: 					        rgba(0, 0, 0, 0.05);
    }
        
    .po-modal .modal-body .product-list-container .products-list::-webkit-scrollbar-thumb {
        background: 					        var(--darkBlue);
    }
    
    .po-modal .modal-body .order-information-main-container .order-information-subcontainer .product-list-container .product-list .product {
        display:                                grid;
        grid-template-columns:                  1fr 1fr;
        column-gap:                             5px;
        position:                               relative;
        padding-bottom:                         20px;
    }

    .po-modal .modal-body .order-information-main-container .order-information-subcontainer .product-list-container .product-list .product .barcodeItem {
        grid-row:                               2/3;
    }

    .po-modal .modal-body .order-information-main-container .order-information-subcontainer .product-list-container .product-list .product .productNameItem {
        grid-column:                            1/-1;
    }

    .po-modal .modal-body .order-information-main-container .order-information-subcontainer .download-po-button-mobile {
        display:                                flex;
        justify-content:                        center;
        align-items:                            center;
        background-color:                       var(--blue);
        color:                                  black;
        border:                                 none;
        padding:                                10px 20px 10px 20px;
        cursor:                                 pointer;
        font-size:                              14px;
        margin-top:                             50px;
    }

    .po-modal .modal-body .order-information-main-container .order-information-subcontainer .download-po-button-mobile .download-po-button-text {
        background: 						    var(--gradient), transparent;
        background-clip: 					    text, padding-box;
        -webkit-background-clip: 			    text, padding-box;
        -webkit-text-fill-color: 			    transparent;
        font-size:                              12px;
    }
    
    .po-modal .modal-body .order-information-main-container .order-information-subcontainer .download-po-button-mobile .download-po-button-icon {
        background: 							var(--gradient);
        mask: 									url("../../../assets/images/download.svg") center center no-repeat;
        width: 									15px;
        height: 								15px;
        margin-left:                            5px;
    }
    
    .po-modal .modal-body .product-list-container .product-list .product > h5 {
        font-size:                              12px;
        font-weight:                            700;
        margin-bottom:                          15px;
    }
    
    .po-modal .modal-body .product-list-container .product-list .product-price {
        color:                                 rgba(0, 0, 0, 0.4);
    }

    .po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper:nth-child(1) {
        display:                                flex;
        flex:                                   2;
        max-width:                              200px;
        margin:                                 0 10px 0 10px;
    }
    
    .po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper:nth-child(2) {
        display:                                flex;
        flex:                                   2;
        max-width:                              700px;
        margin:                                 0 10px 0 10px;
    }
    
    .po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper:nth-child(3) {
        display:                                flex;
        flex:                                   2;
        max-width:                              400px;
        justify-content:                        center;
        margin:                                 0 10px 0 10px;
    
        font-weight:                            500;
    }
    
    .po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper:nth-child(4) {
        display:                                flex;
        flex:                                   2;
        min-width:                              fit-content;
        max-width:                              400px;
        justify-content:                        flex-end;
        margin:                                 0 10px 0 10px;
    }
    
    .po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper:nth-child(5) {
        display:                                flex;
        flex:                                   2;
        min-width:                              fit-content;
        max-width:                              400px;
        justify-content:                        flex-end;
        margin:                                 0 10px 0 10px;
    }

    .po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper {
        display:                                flex;
        flex-direction:                         column;
    }

    .po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper .product-label,
    .po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper .product-label::after {
        content:                                attr(data-label);
        font-size:                              10px;
        font-weight:                            700;
        color:                                  rgba(0, 0, 0, 0.4);
        padding-bottom: 10px;
      }
    
    .po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper .product-item {
        font-size:                              12px;
    }

    .po-modal .modal-body .product-list-container .product-list .product .product-item-wrapper:nth-child(2) .product-item {
        font-size:                              12px;
        font-weight:                            500;
    }
                
    .po-modal .modal-body .product-list-container .total-cost {
        display:                                flex;
        align-items:                            center;
        justify-content:                        space-between;
        padding:                                20px 25px 20px 25px;
        background: 				            var(--gradient), white;
        background-clip: 			            text, padding-box;
        -webkit-background-clip: 	            text, padding-box;
        -webkit-text-fill-color: 	            transparent;
    }
    
    .po-modal .modal-body .product-list-container .total-cost > span {
        font-size:                              12px;
    }

}