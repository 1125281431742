/* INDUSTRIES */
.industry .titleAndSearch{
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
	margin-bottom: 						55px;
}

.industry .titleAndSearch .searchAndSort{
	flex: 								0 0 25%;
}

.industry .titleAndSearch .searchAndSort .searchContainer input{
	padding-right: 						50px;
}


.industry .tableIndustry{
	margin-bottom: 						30px;
}

.industry .tableIndustry thead th:nth-of-type(1){
	width: 								85%;
}

.industry .tableIndustry tbody td:first-of-type .infoContainer > span {
	opacity: 							1;
	cursor: 							pointer;
}

.industry .tableIndustry tbody td:first-of-type .infoContainer > span:hover {
	background:							var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.industry .tableIndustry tbody .infoContainer .moreOptions{
	width: 								18px;
	height: 							18px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
}

.darkMode .industry .tableIndustry tbody .infoContainer .moreOptions{
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.industry .tableIndustry tbody td:last-of-type .infoContainer {
	justify-content: 					space-between;
}

.industry .tableIndustry tbody .industryOptions{ 
	width: 								max-content;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	position: 							absolute;
	top: 								150%;
	right: 								-17px;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
	opacity: 							0;
	visibility: 						hidden;
}

.industry .tableIndustry tbody .industryOptions.darkMode{ 
	background: 						#131E29;
}

.industry .tableIndustry tbody .industryOptions.show{ 
	opacity: 							1;
	visibility: 						visible;
}

.industry .tableIndustry tbody .industryOptions::before{ 
	content: 							"";
	width: 								0;
	height: 							0;
	position: 							absolute;
	bottom: 							100%;
	right: 								15px;
	margin: 							0 auto;
	border-left: 						10px solid transparent;
	border-right: 						10px solid transparent;
	border-bottom: 						10px solid #FFFFFF;
}

.industry .tableIndustry tbody .industryOptions.darkMode::before{ 
	border-bottom: 						10px solid #131E29;
}

.industry .tableIndustry tbody .industryOptions > span{ 
	font-size: 							12px;
	padding: 							5px 20px;
	cursor: 							pointer;
	transition: 						all 0.3s ease-in-out;
}

.industry .tableIndustry tbody .industryOptions.darkMode > span{ 
	color: 								white;
}

.industry .tableIndustry tbody .industryOptions > span:hover{ 
	background-color: 					rgba(4, 26, 57, 0.03);
}

.industry .tableIndustry tbody .industryOptions.darkMode > span:hover{ 
	background-color: 					#243D55;
}

.industry .addAndPagination{
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
	margin-bottom: 						10px;
}

.industry .addAndPagination .btnBgBlueTextGradient{
	padding: 							10px 15px;
}

.industry .addAndPagination .btnBgBlueTextGradient::before{
	content: 							"";
	width: 								18px;
	height: 							18px;
	background: 						url("../../images/add.svg") center center no-repeat;
	background-size: 					18px 18px;
	margin-right: 						5px;
}

.industry .addAndPagination .pagination{
	display: 							flex;
	align-items: 						center;
	gap: 								5px;
}

.industry .addAndPagination .number{
	border: 							unset;
	font-size: 							12px;
	padding: 							2px 7.5px;
	opacity: 							0.5;
}

.industry .addAndPagination .number.active{
	opacity: 							1;
	font-weight: 						900;
	background:  						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.industry .addAndPagination .nextPage{
	width: 								13px;
	height: 							13px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
}

.industry .tableCount{
	width: 								fit-content;
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
	margin: 							0 0 55px auto;
}

.industryModal .modal-content{
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.industryModal .modal-body{
	padding: 							45px 45px 40px;
}

.industryModal .modal-body .closeModal{
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								15px;
	right: 								15px;
	cursor: 							pointer;
}

.industryModal .modal-body .btnBgBlueTextGradient{
	margin: 							20px auto 0;
}

@media (max-width: 991px){
	/* INDUSTRIES */
	.industry .titleAndSearch{
		justify-content: 					unset;
		flex-flow: 							column nowrap;
		margin-bottom: 						20px;
	}

	.industry .titleAndSearch .title{
		margin-bottom: 						30px;
	}

	.industry .titleAndSearch .searchAndSort{
		width: 								100%;
		flex: 								0 0 100%;
		display: 							flex;
		gap: 								40px;
	}

	.industry .titleAndSearch .searchContainer{
		flex: 								1 0 0;
	}

	.industry .titleAndSearch .searchContainer input{
		padding-right: 						35px;
		height: 							100%;
	}

	.industry .titleAndSearch .searchAndSort .sortMobile{
		border: 							unset;
		background-color: 					#FFFFFF;
		padding: 							10px;
		font-size: 							12px;
		color: 								rgba(0, 0, 0, 0.40);
		font-weight: 						300;
		display: 							flex;
		gap: 								40px;
		align-items: 						center;
	}

	.industry.darkMode .titleAndSearch .searchAndSort .sortMobile{
		background-color: 					#131E29;
		color: 								white;
	}

	.industry .titleAndSearch .searchAndSort .sortMobile::after{
		content: 							"";
		width: 								20px;
		height: 							20px;
		background: 						url("../../images/arrowForward.svg") center center no-repeat;
		background-size: 					15px;
		transform: 							rotate(90deg);
	}

	.industry .titleAndSearch .searchAndSort .sortDataContainer{
		width: 								90vw;
		height: 							max-content;
		position: 							fixed;
		top: 								0;
		left: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto;
		border-radius: 						35px;
		padding: 							50px 35px;
		background: 						var(--grey);
		box-shadow: 						0px 0px 100px 10px rgba(4, 26, 57, 0.55);
		z-index: 							999;
		opacity: 							0;
		visibility: 						hidden;
		transition: 						all 0.3s ease-in-out;
	}

	.industry.darkMode .titleAndSearch .searchAndSort .sortDataContainer{
		background: 						#131E29;
	}

	.industry .titleAndSearch .searchAndSort .sortDataContainer::after{
		content: 							"";
		width: 								25px;
		height: 							25px;
		background: 						url("../../images/close.svg") center center no-repeat;
		background-size: 					25px;
		position: 							absolute;
		top: 								25px;
		right: 								25px;
	}

	.darkMode .industry .titleAndSearch .searchAndSort .sortDataContainer::after{
		mask: 								url("../../images/close.svg") center center no-repeat;
		mask-size: 							25px;
		background: 						white;
	}

	.industry .titleAndSearch .searchAndSort .sortDataContainer.show{
		opacity: 							1;
		visibility: 						visible;
	}

	.industry .titleAndSearch .searchAndSort .sortDataContainer > .title{
		font-size: 							16px;
		font-weight: 						700;
	}

	.industry .titleAndSearch .searchAndSort .sortDataContainer > span{
		font-size: 							12px;
		margin-bottom: 						20px;
	}

	.industry .titleAndSearch .searchAndSort .sortDataContainer > span:last-of-type{
		margin-bottom: 						0;
	}

	.industry .tableIndustry{
		display: 							none;
	}

	.industry .industryInformation{
		display: 							flex;
		flex-flow: 							column nowrap;
		margin: 							25px 0;
	}

	.industry .industryInformation .industryRow{
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
		padding: 							15px 23px 25px;
		position: 							relative;
	}

	.industry .industryInformation .industryRow:last-of-type{
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.industry .industryInformation .industryRow .industryName{
		font-weight: 						500;
		display: 							flex;
		justify-content: 					space-between;
	}

	.industry .industryInformation .industryValue{
		font-size: 							12px;
	}

	.industry .industryInformation .industryTitle{
		font-size: 							10px;
		font-weight: 						700;
		opacity: 							0.4;
	}

	.industry .industryInformation .industryTitle:first-of-type{
		margin-bottom: 						10px;
	}

	.industry .industryInformation .moreOptions{
		width: 								18px;
		height: 							18px;
		object-fit: 						contain;
		object-position: 					center center;
		position: 							absolute;
		top: 								0;
		right: 								20px;
		bottom: 							0;
		margin:  							auto 0;
		cursor: 							pointer;
	}

	.darkMode .industry .industryInformation .moreOptions{
		filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
	}

	.industry .industryInformation .industryOptions{ 
		width: 								max-content;
		background: 						#FFFFFF;
		box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
		position: 							absolute;
		top: 								65%;
		right: 								10px;
		z-index: 							999;
		transition: 						all 0.3s ease-in-out;
		opacity: 							0;
		visibility: 						hidden;
	}

	.industry .industryInformation .industryOptions.darkMode{ 
		background: 						#131E29;
	}
	
	.industry .industryInformation .industryOptions.show{ 
		opacity: 							1;
		visibility: 						visible;
	}
	
	.industry .industryInformation .industryOptions::before{ 
		content: 							"";
		width: 								0;
		height: 							0;
		position: 							absolute;
		bottom: 							100%;
		left: 								75%;
		border-left: 						10px solid transparent;
		border-right: 						10px solid transparent;
		border-bottom: 						10px solid #FFFFFF;
	}

	.industry .industryInformation .industryOptions.darkMode::before{ 
		border-bottom: 						10px solid #131E29;
	}
	
	.industry .industryInformation .industryOptions > span{ 
		font-size: 							12px;
		padding: 							5px 20px;
		cursor: 							pointer;
		transition: 						all 0.3s ease-in-out;
	}

	.industry .industryInformation .industryOptions.darkMode > span{ 
		color: 								white;
	}
	
	.industry .addAndPagination{
		padding: 							0 20px;
	}

	.industry .tableCount{
		font-size: 							10px;
		margin: 							0 25px 25px auto;
	}

	.industryModal .modal-content{
		border-radius: 						35px;
	}
	
	.industryModal .modal-body{
		padding: 							35px 20px;
	}
	
	.industryModal .modal-body .closeModal{
		top: 								10px;
		right: 								10px;
	}
}