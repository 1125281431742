/* SUPPLIER PROFILE */
.supplierProfile .header{
	position: 							relative;
	display: 							flex;
	flex-flow: 							row nowrap;
	background-color: 					#FFFFFF;
	padding: 							60px 50px 40px 100px;
	box-shadow: 						0 4px 30px 0 rgba(35, 107, 216, 0.08);
	transition: 						background-color 0.3s ease-in-out;
}

.supplierProfile.darkMode .header{
	background-color: 					#202B34;
}

.supplierProfile .header::before{
	content: 							"";
	width: 								1px;
	height: 							80%;
	background-color: 					rgba(0, 0, 0, 0.05);
	position: 							absolute;
	left: 								69.5%;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
}

.supplierProfile .header .generalInformation {
	max-width: 							70%;
	flex: 								1 0 70%;
	padding-right: 						60px;
}

.supplierProfile .header .generalInformation .headInformation {
	display: 							flex;
	align-items: 						center;
	gap: 								15px;
	margin-bottom: 						20px;
}

.supplierProfile .header .generalInformation .headInformation .goBack{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	transform: 							rotate(180deg);
	cursor: 							pointer;
}

.supplierProfile .header .generalInformation .headInformation .profilePhoto{
	width: 								80px;
    height: 							80px;
    border-radius: 						100px;
    border: 							5px solid transparent;
    background: 						var(--gradient) padding-box, var(--gradient) border-box;
	object-fit: 						contain;
	object-position: 					center center;
}

.supplierProfile .header .generalInformation .headInformation > h3 > span{
	font-size: 							12px;
	font-weight: 						400;
	background: 						var(--gradient) text;
	-webkit-text-fill-color: 			transparent;
}

.supplierProfile .header .generalInformation .headInformation .ratingValue{
	font-size: 							16px;
	display: 							flex;
	align-items: 						center;
	padding: 							5px 10px;
	font-weight: 						700;
	background: 						var(--gradient) text, var(--blue) padding-box;
	-webkit-text-fill-color: 			transparent;
}

.supplierProfile .header .generalInformation .headInformation .ratingValue .ratingImg{
	width: 								20px;
    height: 							20px;
    object-fit: 						contain;
    object-position: 					center center;
}

.supplierProfile .header .generalInformation .industriesInformation {
	display: 							flex;
	align-items: 						center;
	gap: 								10px;
	margin-bottom: 						20px;
}

.supplierProfile .header .generalInformation .industriesInformation .industriesContainer {
	flex: 								1 0 80%;
	display: 							flex;
	flex-flow: 							row nowrap;
	align-items: 						center;
	gap: 								5px;
	overflow: hidden;
}

.supplierProfile .header .generalInformation .industriesInformation .industriesContainer > span{
	font-size: 							12px;
	font-weight: 						700;
	background: 						var(--gradient) text, var(--darkBlue) padding-box;
	-webkit-text-fill-color: 			transparent;
	padding: 							10px 15px;
	text-wrap: 							nowrap;
	text-overflow: 						ellipsis;
	overflow: 							hidden;
}

.supplierProfile .header .generalInformation .industriesInformation .btnBgBlueTextGradient {
	padding: 							10px 25px;
}

.supplierProfile .header .generalInformation .description{
	width: 								80%;
	font-size: 							12px;
	opacity: 							0.8;
	margin-bottom: 						20px;
}

.supplierProfile .header .generalInformation .actions {
	display: 							flex;
	flex-flow: 							row wrap;
	gap: 								10px;
}

.supplierProfile .header .generalInformation .actions > .btnBgBlueTextGradient{
	padding:							10px 20px;
}

.supplierProfile .header .contactInformation{
	flex: 								1 0 30%;
	padding-left: 						50px;
}

.supplierProfile .header .contactInformation .cvpBadge{
	width: 								60px;
    height: 							60px;
    object-fit: 						cover;
    object-position: 					center center;
	margin-bottom: 						30px;
}

.supplierProfile .header .contactInformation .info{
	margin-bottom: 						25px;
}

.supplierProfile .header .contactInformation .info:last-of-type{
	margin-bottom: 						0;
}

.supplierProfile .header .contactInformation .info .infoTitle{
	font-size: 							12px;
	font-weight: 						700;
}

.supplierProfile .header .contactInformation .info .infoText{
	font-size: 							12px;
	opacity: 							0.6;
}

.supplierProfile .header .inputContainer{
	flex:  								0 0 15%;
	margin: 							0;
	align-self: 						flex-start;
}

.supplierProfile .header .inputContainer {
	width: 								25%;
}

.supplierProfile .header .inputContainer .dropdown-toggle{
	border-bottom: 						1px solid #009AFF;
}

.supplierProfile .header .inputContainer .dropdown-toggle:hover.successAlone,
.supplierProfile .header .inputContainer .dropdown-toggle:focus.successAlone,
.supplierClientsModalSupplierProfile .modal-body .actions .dropdown-toggle:hover.successAlone,
.supplierClientsModalSupplierProfile .modal-body .actions .dropdown-toggle:focus.successAlone{
	color: 								var(--green);
}

.supplierProfile .header .inputContainer .dropdown-toggle:hover.failedAlone,
.supplierProfile .header .inputContainer .dropdown-toggle:focus.failedAlone,
.supplierClientsModalSupplierProfile .modal-body .actions .dropdown-toggle:hover.failedAlone,
.supplierClientsModalSupplierProfile .modal-body .actions .dropdown-toggle:focus.failedAlone{
	color: 								var(--red);
}

.supplierProfile .header .inputContainer .dropdown-toggle:hover.newAlone,
.supplierProfile .header .inputContainer .dropdown-toggle:focus.newAlone,
.supplierClientsModalSupplierProfile .modal-body .actions .dropdown-toggle:hover.newAlone,
.supplierClientsModalSupplierProfile .modal-body .actions .dropdown-toggle:focus.newAlone{
	color: 								#00A3FF;
}

.supplierProfile .header .inputContainer .dropdown-item, 
.supplierClientsModalSupplierProfile .modal-body .actions .dropdown-item {
	padding: 							10px 20px;
	font-weight: 						700;
}

.supplierProfile .header .inputContainer .dropdown-item:hover.success, 
.supplierClientsModalSupplierProfile .modal-body .actions .dropdown-item:hover.success {
	color: 								#FFFFFF;
	background-color: 					var(--green);
}

.supplierProfile .header .inputContainer .dropdown-item:hover.new, 
.supplierClientsModalSupplierProfile .modal-body .actions .dropdown-item:hover.new {
	color: 								#FFFFFF;
	background-color: 					#00A3FF;
}

.supplierProfile .header .inputContainer .dropdown-item:hover.failedAlone, 
.supplierClientsModalSupplierProfile .modal-body .actions .dropdown-item:hover.failedAlone {
	color: 								#FFFFFF;
	background-color: 					var(--red);
}

.supplierProfile .header .inputContainer .dropdown-item::after, 
.supplierClientsModalSupplierProfile .modal-body .actions .dropdown-item::after {
	content: 							"\025cf";
	margin-left: 						5px;
}

.supplierProfile .header .statusButton {
	width: 								100px;
	padding: 							5px;
	border-radius: 						25px;
	overflow: 							hidden;
	background-color: 					var(--red);
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.supplierProfile .header .statusButton:hover {
	background-color: 					var(--darkRed);
}

.supplierProfile .header .statusButton .statusBall {
	position: 							relative;
	width: 								25px;
	height: 							25px;
	border-radius: 						100px;
	background-color: 					#FFFFFF;
	transition: 						all 0.3s ease-in-out;
}

.supplierProfile .header .statusButton .statusBall::before {
	content: 							"Active";
	height: 							fit-content;
	font-weight: 						700;
	color: 								#FFFFFF;
	font-size: 							12px;
	position: 							absolute;
	right: 								150%;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
}

.supplierProfile .header .statusButton .statusBall::after {
	content: 							"Disabled";
	height: 							fit-content;
	font-weight: 						700;
	color: 								#FFFFFF;
	font-size: 							12px;
	position: 							absolute;
	left: 								130%;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
}

.supplierProfile .header .statusButton.active {
	background-color: 					var(--green);
}

.supplierProfile .header .statusButton.active:hover {
	background-color: 					var(--darkGreen);
}

.supplierProfile .header .statusButton.active .statusBall {
	transform: 							translateX(65px);
}

.supplierIndustriesModalSupplierProfile .modal-content,
.supplierClientsModalSupplierProfile .modal-content {
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

#root > .darkMode + .supplierClientsModalSupplierProfile .modal-content {
	background: 						#131E29;
}

.supplierIndustriesModalSupplierProfile .modal-body,
.supplierClientsModalSupplierProfile .modal-body {
	padding: 							50px 65px 35px;
}

.supplierIndustriesModalSupplierProfile .modal-body .commentTitle,
.supplierClientsModalSupplierProfile .modal-body .commentTitle {
	font-weight: 						700;
	margin-bottom: 						20px;
}

.supplierClientsModalSupplierProfile .modal-body .actions {
	width: 								100%;
	gap: 								10px;
	justify-content: 					flex-end;
}

.supplierClientsModalSupplierProfile .modal-body .tableClients .actions > .btnBgBlueTextGradient{
	font-size: 							10px;
	padding: 							5px;
}

.supplierClientsModalSupplierProfile .modal-body .tableClients .actions > .btnBgBlueTextGradient::after{
	content: 							"";
	width: 								15px;
	height: 							15px;
	background: 						url("../../images/arrowForwardFull.svg") center center no-repeat;
	background-size: 					15px 15px;;
}

.supplierClientsModalSupplierProfile .modal-body .actionButton .btnBgBlueTextGradient,
.supplierIndustriesModalSupplierProfile .modal-body .actionButton .btnBgBlueTextGradient {
	margin-top: 						30px;
	margin-left: 						auto;
}

.supplierIndustriesModalSupplierProfile .modal-body .industriesContainer { 
	display: 							flex;
	flex-flow: 							row wrap;
	gap: 								10px;
}

.supplierIndustriesModalSupplierProfile .modal-body .industriesContainer .industry {
	flex: 								1 0 auto;
	font-size: 							16px;
	font-weight: 						700;
	text-align: 						center;
	padding: 							10px 15px;
	background: 						var(--gradient) text, var(--darkBlue) padding-box;
	-webkit-text-fill-color: 			transparent;
}

.supplierClientsModalSupplierProfile .modal-body .tableClients span{
	opacity: 							1;
}

.supplierProfile .tabs-container {
	position: 							relative;
}

.supplierProfile .tabs-container .send-email {
	display: 							flex;
	gap: 								5px;
	align-items: 						center;
	position: 							absolute;
	right: 								0;
	top: 								32px;
	font-size: 							14px;
	padding: 							10px 30px;
}

#send-email-tooltip .tooltip-inner{
	font-size: 							12px;
	background-color: 					#FFFFFF;
	color: 								#000000;
	font-weight: 						500;
	padding: 							10px 20px;
}

#send-email-tooltip .tooltip-arrow::before{
	border-top: 						6px solid #FFFFFF;
}

.supplierProfile .tabs-container .send-email::before {
	content: 							"";
	mask: 								url("../../images/forward_to_inbox.svg");
    mask-size:							20px;
    width:                              20px;
    height:                             16px;
    background:                         var(--gradient);
}

.supplierProfile .nav-tabs {
	padding-top: 						35px;
	border: 							unset;
	gap: 								10px;
	margin-bottom: 						10px;
	width: 								84%;
}

.supplierProfile .nav-tabs .nav-link{
	padding: 							10px 20px;
	border-radius: 						unset;
	border: 							unset;
	background-color: 					#FFFFFF;
	color: 								#000000;
	font-size: 							12px;
}

.supplierProfile.darkMode .nav-tabs .nav-link{
	background-color: 					#131E29;
	color: 								white;
}

.supplierProfile .nav-tabs .nav-link.active{
	font-weight: 						700;
	color: 								#FFFFFF;
	background-color: 					var(--darkBlue);
}

.supplierProfile.darkMode .nav-tabs .nav-link.active{
	background-color: 					#243D55;
}

.supplierProfile .saveChanges{
	border: 							unset;
	border-radius: 						100px;
	background-color: 					var(--blue);
	padding: 							10px;
	transition: 						all 0.3s ease-in-out;
	position: 							fixed;
	right: 								30px;
	bottom: 							30px;
	z-index: 							999;
}

.supplierProfile .saveChanges:hover{
	background-color: 					var(--darkBlue);
}

.supplierProfile .actionButtons{
	display: 							flex;
	gap: 								15px;
	align-items: 						center;
	justify-content: 					flex-end;	
	margin-bottom: 						100px;
}

.supplierProfile .actionButtons .btnBgBlueTextGradient:first-of-type{
	color: 								#000000;
	background-color: 					transparent;
	-webkit-text-fill-color: 			unset;
}

.supplierProfile.darkMode .actionButtons .btnBgBlueTextGradient:first-of-type{
	color: 								white;
}

@media (max-width: 991px){
	/* SUPPLIER PROFILE */
	.supplierProfile .header{
		flex-flow: 							column nowrap;
		padding: 							80px 35px;
		align-items: 						center;
	}
	
	.supplierProfile .header::before{
		content: 							none;
	}
	
	.supplierProfile .header .generalInformation {
		max-width: 							100%;
		flex: 								1 0 0%;
		padding-right: 						0;
		margin-bottom: 						35px;
	}
	
	.supplierProfile .header .generalInformation .headInformation {
		flex-flow: 							column nowrap;
		margin-bottom: 						15px;
	}

	.supplierProfile .header .generalInformation .headInformation .goBack{
		align-self: 						flex-start;
	}
	
	.supplierProfile .header .generalInformation .headInformation .profilePhoto{
		width: 								90px;
		height: 							90px;
	}
	
	.supplierProfile .header .generalInformation .headInformation h3{
		font-size: 							26px;
		text-align: 						center;
	}
	
	.supplierProfile .header .generalInformation .industriesInformation {
		gap: 								15px;
		margin-bottom: 						15px;
		flex-flow: 							row wrap;
	}
	
	.supplierProfile .header .generalInformation .industriesInformation .industriesContainer {
		flex: 								1 0 100%;
		flex-flow: 							row wrap;
		justify-content: 					center;
	}
	
	.supplierProfile .header .generalInformation .industriesInformation .btnBgBlueTextGradient {
		width: 								100%;
	}
	
	.supplierProfile .header .generalInformation .description{
		width: 								100%;
		text-align: 						center;
		margin-bottom: 						0;
	}

	.supplierProfile .header .generalInformation .actions {
		display: 							none;
	}

	.supplierProfile .header .generalInformation > .btnBgBlueTextGradient{
		display: 							none;
	}
	
	.supplierProfile .header .contactInformation{
		flex: 								1 0 0;
		padding-left: 						0;
		margin-bottom: 						35px;
	}
	
	.supplierProfile .header .contactInformation .cvpBadge{
		margin: 							0 auto 15px;
	}
	
	.supplierProfile .header .contactInformation .info{
		text-align: 						center;
		margin: 							0 auto 15px;
	}
	
	.supplierProfile .header .contactInformation .info:last-of-type{
		margin: 							0 auto 0;
	}

	.supplierProfile .header .actions {
		width: 								100%;
		display: 							flex;
		flex-flow: 							column nowrap;
		gap: 								10px;
	}

	.supplierProfile .header .generalInformation > .inputContainer .dropdown {
		display: 							none !important;
	}

	.supplierProfile .header > .inputContainer {
		width: 								100%;
	}

	.supplierProfile .header > .btnBgBlueTextGradient {
		width: 								100%;
	}

	.supplierProfile .header .statusButton {
		margin: 							0 auto;
	}

	.supplierIndustriesModalSupplierProfile .modal-body,
	.supplierClientsModalSupplierProfile .modal-body {
		padding: 							30px 25px;
	}

	.supplierIndustriesModalSupplierProfile .modal-body .commentTitle,
	.supplierClientsModalSupplierProfile .modal-body .commentTitle {
		margin-bottom: 						30px;
	}

	.supplierIndustriesModalSupplierProfile .modal-body .actionButton .btnBgBlueTextGradient {
		width: 								100%;
		font-size: 							16px;
	}

	.supplierIndustriesModalSupplierProfile .modal-body .industriesContainer .industry {
		font-size: 							12px;
	}

	.supplierClientsModalSupplierProfile .modal-body .actionButton{
		justify-content: 					center;
		margin-top: 						10px;
	}

	.supplierClientsModalSupplierProfile .modal-body .actionButton > .btnBgBlueTextGradient{
		width: 								100%;
	}

	.supplierClientsModalSupplierProfile .modal-body .tableClients{
		display: 							none;
	}

	.supplierClientsModalSupplierProfile .modal-body .mobileCientList .clientRow {
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
		padding: 							25px 0;
	}

	.supplierClientsModalSupplierProfile .modal-body .mobileCientList .clientRow:last-of-type {
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.supplierClientsModalSupplierProfile .modal-body .mobileCientList .clientRow .clientHeaderTitle {
		font-size: 							12px;
		font-weight: 						500;
		margin-bottom: 						10px;
	}

	.supplierClientsModalSupplierProfile .modal-body .mobileCientList .clientRow .clientValue {
		font-size: 							10px;
		font-weight: 						400;
	}

	.supplierClientsModalSupplierProfile .modal-body .mobileCientList .clientRow .clientTitle {
		font-size: 							10px;
		font-weight: 						700;
		opacity: 							0.4;
		margin-bottom: 						10px;
	}

	.supplierClientsModalSupplierProfile .modal-body .mobileCientList .clientRow .clientTitle:last-of-type {
		margin-bottom: 						0;
	}

	.supplierClientsModalSupplierProfile .modal-body .mobileCientList .actions {
		display: 							flex;
		gap: 								10px;
	}

	.supplierClientsModalSupplierProfile .modal-body .mobileCientList .actions > .btnBgBlueTextGradient{
		font-size: 							10px;
		padding: 							5px;
		margin-left: 						auto;
	}
	
	.supplierClientsModalSupplierProfile .modal-body .mobileCientList .actions > .btnBgBlueTextGradient::after{
		content: 							"";
		width: 								15px;
		height: 							15px;
		background: 						url("../../images/arrowForwardFull.svg") center center no-repeat;
		background-size: 					15px 15px;;
	}

	.supplierProfile .tabs-container {
		margin-top: 						60px;
	}

	.supplierProfile .tabs-container .send-email {
		top: 								unset;
		bottom: 							99.8%;
	}

	.supplierProfile .nav-tabs{
		padding-top: 						20px;
		gap: 								5px;
		margin-bottom: 						0;
		padding-bottom: 					10px;
		flex-flow: 							row nowrap;
		overflow-x: 						auto;
		width: 								100%;
	}

	.supplierProfile .nav-tabs .nav-link{
		font-size: 							10px;
		width: 								max-content;
		padding: 							10px;
	}

	.supplierProfile .actionButtons{
		justify-content: 					center;	
	}
}