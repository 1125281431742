/* NON COMPLIANT LIST */
.newRequest .newRequestTable {
	margin-bottom: 							30px;
}

.newRequest .newRequestTable td:first-of-type .infoContainer span {
	opacity: 								1;
	cursor: 								pointer;
}

.newRequest .newRequestTable td:first-of-type .infoContainer span:hover {
	background: 							var(--gradient) text;
	-webkit-text-fill-color: 				transparent;
}

.newRequest .newRequestTable td:last-of-type .infoContainer {
	justify-content: 						space-between;
}

.newRequest .newRequestTable td .infoContainer .reviewed {
	width: 									20px;
	height: 								20px;
	object-fit: 							contain;
	object-position: 						center center;
	cursor: 								pointer;
}

.newRequest .addAndPagination {
	display: 								flex;
	align-items: 							center;
	justify-content: 						flex-end;
	margin-bottom: 							10px;
}

.newRequest .addAndPagination .pagination {
	display: 								flex;
	align-items: 							center;
	gap: 									5px;
}

.newRequest .addAndPagination .number {
	border: 								unset;
	font-size: 								12px;
	padding: 								2px 7.5px;
	opacity: 								0.5;
}

.newRequest .addAndPagination .number.active {
	opacity: 								1;
	font-weight: 							900;
	background: 							var(--gradient), var(--blue);
	background-clip: 						text, padding-box;
	-webkit-background-clip: 				text, padding-box;
	-webkit-text-fill-color: 				transparent;
}

.newRequest .addAndPagination .nextPage {
	width: 									13px;
	height: 								13px;
	object-fit: 							contain;
	object-position: 						center center;
	cursor: 								pointer;
}

.newRequest .tableCount {
	width: 									fit-content;
	font-size: 								12px;
	font-weight: 							300;
	opacity: 								0.5;
	margin: 								0 0 55px auto;
}

@media (max-width: 991px) {
	.newRequest .newRequestTable {
		display: 								none;
	}

	.newRequest .newRequestList {
		display: 								flex;
		flex-flow: 								column nowrap;
		margin: 								25px 0;
	}

	.newRequest .newRequestList .newRequestRow {
		border-top: 							1px solid rgba(0, 0, 0, 0.05);
		padding: 								15px 23px 25px;
	}

	.newRequest .newRequestList .newRequestRow:last-of-type {
		border-bottom:							1px solid rgba(0, 0, 0, 0.05);
	}

	.newRequest .newRequestList .newRequestRow .newRequestName {
		font-weight: 							500;
		margin-bottom: 							20px;
	}

	.newRequest .newRequestList .newRequestRow .newRequestInformation {
		width: 									100%;
		display: 								grid;
		align-items: 							flex-end;
		justify-content:						space-between;
		grid-template-columns: 					repeat(2, fit-content);
		grid-template-rows: 					repeat(8, max-content);
		column-gap: 							30px;
		row-gap: 								0;
	}

	.newRequest .newRequestList .newRequestRow .newRequestInformation .newRequestValue {
		font-size: 								12px;
	}

	.newRequest .newRequestList .newRequestRow .newRequestInformation .newRequestTitle {
		font-size: 								10px;
		font-weight: 							700;
		opacity: 								0.4;
	}

	.newRequest .newRequestList .newRequestRow .newRequestInformation>span:nth-of-type(1) {
		grid-column-start: 						1;
		grid-column-end:						2;
		grid-row-start: 						1;
		grid-row-end: 							2;
	}

	.newRequest .newRequestList .newRequestRow .newRequestInformation>span:nth-of-type(2) {
		grid-column-start: 						1;
		grid-column-end: 						2;
		grid-row-start: 						2;
		grid-row-end: 							3;
		margin-bottom: 							10px;
	}

	.newRequest .newRequestList .newRequestRow .newRequestInformation>span:nth-of-type(3) {
		display: 								flex;
		grid-column-start: 						2;
		grid-column-end: 						3;
		grid-row-start: 						1;
		grid-row-end: 							2;
	}

	.newRequest .newRequestList .newRequestRow .newRequestInformation>span:nth-of-type(4) {
		grid-column-start: 						2;
		grid-column-end: 						3;
		grid-row-start: 						2;
		grid-row-end: 							3;
		margin-bottom: 							10px;
	}

	.newRequest .newRequestList .newRequestRow .newRequestInformation>span:nth-of-type(5) {
		grid-column-start: 						1;
		grid-column-end: 						2;
		grid-row-start:							3;
		grid-row-end: 							4;
	}

	.newRequest .newRequestList .newRequestRow .newRequestInformation>span:nth-of-type(6) {
		grid-column-start: 						1;
		grid-column-end:						2;
		grid-row-start: 						4;
		grid-row-end: 							5;
		margin-bottom: 							10px;
	}

	.newRequest .addAndPagination {
		padding: 								0 20px;
	}

	.newRequest .tableCount {
		font-size: 								10px;
		margin: 								0 25px 25px auto;
	}
}