/* 404 NOT FOUND SECTION */
.notFound {
	width: 							100%;
	height: 						100vh;
	position: 						relative;
}

.notFound .world,
.notFound .img404 {
	height: 						350px;
	object-fit: 					contain;
	object-position: 				center center;
	position: 						absolute;
	top: 							0;
	right: 							0;
	bottom: 						0;
	left: 							0;
	margin: 						auto;
}

.notFound .logo {
	width: 							450px;
	height: 						auto;
	object-fit: 					contain;
	object-position: 				center center;
	position: 						absolute;
	top: 							-60%;
	right: 							0;
	bottom: 						0;
	left: 							0;
	margin: 						auto;
}

.notFound .titleAndAction {
	width: 							fit-content;
	display: 						flex;
	flex-flow: 						column nowrap;
	gap: 							15px;
	position: 						absolute;
	top: 							75%;
	left: 							0;
	right: 							0;
	margin: 						0 auto;
}

.notFound .titleAndAction h4 {
	width: 							45%;
	text-align: 					center;
	margin: 						0 auto;
}

.notFound .titleAndAction .btnBgBlueTextGradient {
	width: 							fit-content;
	margin: 						0 auto;
	gap: 							5px;
}

@media (max-width: 992px) {
	.notFound .row {
		min-height: 					100vh;
		align-items: 					center;
		padding: 						50px 0;
	}

	.notFound .logo,
	.notFound .world,
	.notFound .img404 {
		width: 							100% !important;
		height: 						auto !important;
		position: 						static;
		margin-bottom: 					25px;
	}

	.notFound .titleAndAction {
		width: 							100%;
		gap: 							15px;
		position: 						static;
		margin: 						10px 0 0;
	}

	.notFound .titleAndAction h4 {
		width: 							100%;
		font-size: 						16px;
	}
}