/* Slider CSS */
.actionNeeded .switch {
    position:                       	relative;
    display:                        	inline-block;
    width:                          	115px;
    height:                         	39px;
}

  /* Hide default HTML checkbox */
.actionNeeded .switch input {
    opacity:                        	0;
    width:                          	0;
    height:                         	0;
}

/* lable inside the slider */
.actionNeeded .insideLabel {
    position:                       	absolute;
    top:                            	8px;
    z-index:                        	2;
    cursor:                         	pointer;
    pointer-events:                 	none;
    transition:                     	all 0.1s ease-out;
	white-space: 						nowrap;
}

.actionNeeded .switch #label-on {
    left:                           	15px;
    color:                          	white;
    opacity:                        	1;
    visibility:                     	visible;
}

.actionNeeded .switch #label-on.hidden {
    opacity:                        	0;
    visibility:                     	hidden;
}

.actionNeeded .switch #label-off {
    left:                           	39px;
    color:                          	grey;
    opacity:                        	1;
    visibility:                     	visible;
}

.actionNeeded .switch #label-off.hidden {
    opacity:                        	0;
    visibility:                     	hidden;
}

  /* The slider */
.actionNeeded .slider {
    position:                       	absolute;
    cursor:                         	pointer;
    top:                            	0;
    left:                           	0;
    right:                          	0;
    bottom:                         	0;
    background-color:               	white;
    -webkit-transition:             	.4s;
    transition:                     	.4s;
}

.actionNeeded .slider:before {
    position:                       	absolute;
    content:                        	"";
    height:                         	26px;
    width:                          	26px;
    left:                           	4px;
    bottom:                         	7px;
    background-color:               	rgba(4, 26, 57, 0.3);
    -webkit-transition:             	.35s;
    transition:                     	.35s;
}

.actionNeeded.darkMode .slider:before {
    background-color:               	white;
}

.actionNeeded .slider.isChecked:before {
    background-color:               	white;
}

.actionNeeded .switch input:checked + .slider {
    background-color:               	rgba(8, 209, 161, 1);
}

.actionNeeded .switch input + .slider {
	background-color:               	white;
}

.actionNeeded.darkMode .switch input + .slider {
	background-color:               	#181E24;
}

.actionNeeded.darkMode .switch input:checked + .slider {
	background-color:               	rgba(8, 209, 161, 1);
}

.actionNeeded .switch input:focus + .slider {
    box-shadow:                     	0 0 1px rgba(0, 163, 255, 0.1)
}

.actionNeeded .switch input:checked + .slider:before {
    -webkit-transform:              	translateX(78px);
    -ms-transform:                  	translateX(78px);
    transform:                      	translateX(78px);
}

  /* Rounded sliders */
.actionNeeded .slider.round {
    border-radius:                  	34px;
}

.actionNeeded .slider.round:before {
    border-radius:                  	50%;
}


/* ACTION NEEDED LIST */
.actionNeeded .filters{
	display: 							flex;
    align-items:                        center;
	margin-bottom: 						10px;
	justify-content: 					space-between;
}

.actionNeeded .filters .filtersButtons {
	display: 							flex;
	flex: 								1 1 0;
    align-items:                        center;
	flex-flow: 							row wrap;
	gap: 								10px;
}

.actionNeeded .filters .actionNeededFilter{
	display: 							flex;
	align-items: 						center;
	padding: 							10px 20px;
	background-color: 					#FFFFFF;
	position: 							relative;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.actionNeeded.darkMode .filters .actionNeededFilter{
	background-color: 					#181E24;
}

.actionNeeded .filters .actionNeededFilter.show{
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.actionNeeded .filters .actionNeededFilter.active{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.actionNeeded .filters .actionNeededFilter::after{
	content: 							"";
	width: 								20px;
	height: 							20px;
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size: 					15px;
	transform: 							rotate(90deg);
	margin-left: 						20px;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.actionNeeded .filters .actionNeededFilter.show::after{
	transform: 							rotate(270deg);
}

.actionNeeded .filters .actionNeededFilter > img{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-right: 						5px;
	pointer-events: 					none;
}

.actionNeeded .filters .actionNeededFilter > span{
	font-size: 							12px;
	pointer-events: 					none;
}

.actionNeeded .filters .actionNeededFilter .searchContainer::after{
	right: 								15px;
}

.actionNeeded .filters .actionNeededFilter .searchContainer > input{
	background-color: 					var(--grey);
}

.actionNeeded.darkMode .filters .actionNeededFilter .searchContainer > input{
	background-color: 					#27343F;
	-webkit-text-fill-color: 			white;
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions{
	width: 								max-content;
	position: 							absolute;
	top: 								105%;
	left: 								0;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	padding: 							20px;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
	cursor: 							default;
}

.actionNeeded.darkMode .filters .actionNeededFilter .actionNeededFilterOptions{
	background: 						#131E29;
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .optionsScroll{
	max-height: 						300px;
	overflow-y: 						auto;
}

/* width */
.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .optionsScroll::-webkit-scrollbar {
	width: 								3px;
}
  
 /* Track */
.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .optionsScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
 }
  
/* Handle */
.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.actionNeeded.darkMode .filters .actionNeededFilter .actionNeededFilterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						white;
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions.show, .actionNeeded .filters .actionNeededFilter .searchContainer.show{
	visibility: 						visible;
	opacity: 							1;
	-webkit-text-fill-color: 			#000000;
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .title{
	font-size: 							14px;
	font-weight: 						700;
	margin-bottom: 						5px;
}

.actionNeeded.darkMode .filters .actionNeededFilter .actionNeededFilterOptions .title,
.actionNeeded.darkMode .filters .actionNeededFilter .actionNeededFilterOptions .option{
	-webkit-text-fill-color: 			white;
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .option{
	display: 							flex;
	align-items: 						center;
	padding: 							0 10px;
	cursor: 							pointer;
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions.location{
	width: 								280px;
	top: 								290%;
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .option > input{
	margin-right: 						0;
	cursor: 							pointer;
}

.actionNeeded.darkMode .filters .actionNeededFilter .actionNeededFilterOptions .option > input{
	background-color: 					white;
}

.actionNeeded.darkMode .filters .actionNeededFilter .actionNeededFilterOptions .option > input:checked{
	background-color: 					var(--green);
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .option > label{
	flex:  								1 0 0;
	font-size: 							12px;
	opacity: 							0.8;
	cursor: 							pointer;
	padding: 							10px 0 10px 10px;
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .optionScroll{
	max-height: 						220px;
	overflow-y: 						auto;
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .optionScroll::-webkit-scrollbar {
	width: 								3px;
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .optionScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
}
 
.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .optionScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .actions{
	display: 							flex;
	gap: 								10px;
	justify-content: 					center;
	margin-top: 						15px;
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .actions > button {
	border: 							unset;
	background-color: 					transparent;
	padding: 							5px 15px;
	font-size: 							12px;
}

.actionNeeded.darkMode .filters .actionNeededFilter .actionNeededFilterOptions .actions > button:first-of-type {
	-webkit-text-fill-color: 			white;
}

.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .actions > button:last-of-type {
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.actionNeeded .tableActionNeeded {
    margin-bottom:                      30px;
}

.actionNeeded .tableActionNeeded td:first-of-type .infoContainer span{
	opacity: 							1;
	cursor:								pointer;
}

.actionNeeded .tableActionNeeded td:first-of-type .infoContainer span:hover{
	background: var(--gradient) 		text;
    -webkit-text-fill-color: 			transparent;
}

.actionNeeded .tableActionNeeded td:last-of-type .infoContainer{
    justify-content:                    space-between;
}

.actionNeeded .tableActionNeeded td .infoContainer .actionIcon{
    width:                              20px;
    height:                             20px;
    object-fit:                         contain;
    object-position:                    center center;
    cursor:                             pointer;
}

.actionNeeded .tableActionNeeded td .infoContainer .actionsContainer {
	position: 							relative;
}

.actionNeeded .tableActionNeeded td .infoContainer .actionsContainer > img {
	cursor: 							pointer;
}

.darkMode .actionNeeded .tableActionNeeded td .infoContainer .actionsContainer > img,
.darkMode .actionNeeded .mobileActionsContainer > img {
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.actionNeeded .tableActionNeeded td .infoContainer .actionsContainer .optionsContainer {
	display: 							flex;
	flex-direction: 					column;
	position: 							absolute;
	top: 								100%;
	left: 								50%;
	transform: 							translateX(-50%);
	z-index: 							1;
	background-color: 					white;
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
}

.actionNeeded .tableActionNeeded tr:last-of-type td .infoContainer .actionsContainer .optionsContainer {
	top: 								unset;
	bottom: 							100%;
}

.actionNeeded .tableActionNeeded td .infoContainer .actionsContainer .optionsContainer.visible {
	visibility: 						visible;
	opacity: 							1;
}

.actionNeeded .tableActionNeeded td .infoContainer .actionsContainer .optionsContainer > span {
	cursor: 							pointer;
	white-space: 						nowrap;
	padding: 							10px 35px;
}

.darkMode .actionNeeded .tableActionNeeded td .infoContainer .actionsContainer .optionsContainer > span {
	background: 						#131E29;
}

.actionNeeded .tableActionNeeded td .infoContainer .actionsContainer .optionsContainer > span:hover {
	background: 						var(--gradient), rgba(4, 26, 57, 0.10);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.darkMode .actionNeeded .tableActionNeeded td .infoContainer .actionsContainer .optionsContainer > span:hover {
	background-color:					#27343F;
	-webkit-text-fill-color: 			white;
}

.actionNeeded .tableActionNeeded .infoContainer .completByColumnContainer {
	display: 							flex;
	flex-direction: 					column;
}

.actionNeeded .tableActionNeeded .infoContainer .completByColumnContainer > span {
	font-size: 							12px;
	font-weight: 						500;
	opacity: 							0.5;
}

.actionNeeded .addAndPagination{
	display: 							flex;
	align-items: 						center;
	justify-content: 					flex-end;
	margin-bottom: 						10px;
}

.actionNeeded .addAndPagination nav {
	padding: 							0;
}

.darkMode .actionNeeded .addAndPagination nav ul li button{
	color: 								white;
	opacity: 							1;
}

.actionNeeded .tableCount{
	width: 								fit-content;
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
	margin: 							0 0 55px auto;
}

@media (max-width: 991px) {
	.actionNeeded .filters{
		flex-direction: 					column;
		gap: 								10px;
		overflow-x: 						auto;
		flex-wrap: 							nowrap;
		align-items: 						flex-start;
	}

	/* width */
	.actionNeeded .filters::-webkit-scrollbar {
		display: 							none;
	}
	
	.actionNeeded .filters .actionNeededFilter{
		padding: 							10px;
		position: 							static;
	}

	.actionNeeded .filters .actionNeededFilter > span{
		font-size: 							10px;
	}

	.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions{
		width: 								90vw;
		height: 							max-content;
		position: 							fixed;
		top: 								0;
		left: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto;
		border-radius: 						35px;
		padding: 							50px 35px;

	}
	.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions::after{
		content: 							"";
		width: 								25px;
		height: 							25px;
		background: 						url("../../images/close.svg") center center no-repeat;
		background-size: 					25px;
		position: 							absolute;
		top: 								25px;
		right: 								25px;
	}

	.darkMode .actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions::after{
		mask: 								url("../../images/close.svg") center center no-repeat;
		mask-size: 							25px;
		background: 						white;
	}

	.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .imgMenu{
		width: 								20px;
		height: 							20px;
		object-fit: 						contain;
		object-position: 					center center;
		margin-right: 						10px;
		display: 							inline-block;
	}

	.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .title{
		display: 							inline-block;
		font-size: 							16px;
		margin-bottom: 						30px;
	}

	.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .option{
		padding: 							0;
	}

	.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions.location{
		width: 								90vw;
		top: 								0;
	}

	.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .option > input{
		order: 								2;
		margin-right: 						10px;
	}

	.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .option > label{
		order: 								1;
		padding:  							10px 10px 10px 0;
	}

	.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .actions{
		gap: 								0;
		justify-content: 					space-between;
		margin-top: 						50px;
	}

	.actionNeeded .filters .actionNeededFilter .actionNeededFilterOptions .actions > button{
		font-size: 							14px;
	}

    .actionNeeded .tableActionNeeded {
		display:                            none;
	}

	.actionNeeded .actionNeededList{
		display: 							flex;
		flex-flow: 							column nowrap;
		margin: 							25px 0;
	}

	.actionNeeded .actionNeededList .actionNeededRow{
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
		padding: 							15px 23px 25px;
	}

	.actionNeeded .actionNeededList .actionNeededRow:last-of-type{
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.actionNeeded .actionNeededList .actionNeededRow .actionNeededName{
        display:                            flex;
        align-items:                        center;
        justify-content: 					space-between;
		font-weight: 						500;
		margin-bottom: 						20px;
	}

	.actionNeeded .actionNeededList .actionNeededRow .actionNeededName .mobileActionsContainer {
		position: 							relative;
	}

	.actionNeeded .actionNeededList .actionNeededRow .actionNeededName .mobileActionsContainer .optionsContainer {
		display: 							flex;
		flex-direction: 					column;
		position: 							absolute;
		top: 								100%;
		right: 								0;
		/* transform: 							translateX(-70%); */
		z-index: 							1;
		background-color: 					white;
		transition: 						all 0.3s ease-in-out;
		visibility: 						hidden;
		opacity: 							0;
	}

	.actionNeeded .actionNeededList .actionNeededRow .actionNeededName .mobileActionsContainer .optionsContainer.visible {
		visibility: 						visible;
		opacity: 							1;
	}

	.actionNeeded .actionNeededList .actionNeededRow .actionNeededName .mobileActionsContainer .optionsContainer > span {
		font-size: 							12px;
		white-space: 						nowrap;
		padding: 							10px 15px;
	}

	.darkMode .actionNeeded .actionNeededList .actionNeededRow .actionNeededName .mobileActionsContainer .optionsContainer > span {
		background-color: 					#131E29;
	}

	.actionNeeded .actionNeededList .actionNeededRow .actionNeededInformation{
		width: 								100%;
		display:  							grid;
		align-items: 						flex-end;
		justify-content: 					space-between;
		grid-template-columns: 				repeat(2, minmax(130px, 1fr));
		grid-template-rows: 				repeat(auto, max-content);
		column-gap: 						30px;
		row-gap: 							10px;
	}

	.actionNeeded .actionNeededList .actionNeededRow .actionNeededInformation #idDocInfoMobile{
		grid-column: 						1 / -1;
	}

	.actionNeeded .actionNeededList .actionNeededRow .actionNeededInformation #completedByUsername{
		white-space: 						nowrap;
		text-overflow: 						ellipsis;
		overflow: 							hidden;
	}

	.actionNeeded .actionNeededList .actionNeededRow .actionNeededInformation .actionNeededValue{
		font-size: 							12px;
	}

	.actionNeeded .actionNeededList .actionNeededRow .actionNeededInformation .actionNeededTitle{
		font-size: 							10px;
		font-weight: 						700;
		opacity: 							0.4;
	}

	.actionNeeded .addAndPagination{
		padding: 							0 20px;
	}

	.actionNeeded .tableCount{
		font-size: 							10px;
		margin: 							0 25px 25px auto;
	}
}