/* ADD CLIENT MODAL */
.entityModal .modal-content{
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.entityModal .modal-body{
	padding: 							90px 100px;
}

.entityModal .modal-body .closeModal{
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								20px;
	right: 								20px;
	cursor: 							pointer;
}

.entityModal .modal-body .modalTitle{
	margin-bottom: 						30px;
}

.entityModal .modal-body .inputSections{
	display: 							flex;
	flex-flow: 							row wrap;
	gap: 								10px;
	margin-bottom: 						50px;
}

.entityModal .modal-body .inputSections .inputContainer{
	flex: 								0 0 calc(100% / 3 - (10px * 2 / 3));
}

.entityModal .modal-body .actions{
	display: 							flex;
	justify-content: 					flex-end;
	align-items: 						center;
	gap: 								25px;
}

@media (max-width: 991px){
	.entityModal .modal-content{
		border-radius: 						35px;
	}
	
	.entityModal .modal-body{
		padding: 							35px 20px;
	}
	
	.entityModal .modal-body .closeModal{
		top: 								15px;
		right: 								15px;
	}

	.entityModal .modal-body .inputSections .inputContainer{
		flex: 								0 0 calc(100%);
	}
	
	.entityModal .modal-body .actions{
		justify-content: 					center;
	}
}