/* NEW REQUEST */
.modalNewRequest .modal-content{
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.modalNewRequest .modal-body{
	border-radius: 35px 35px 0 0;
	padding: 							100px 100px 55px;
}

.modalNewRequest .modal-body .closeModal{
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								25px;
	right: 								25px;
	cursor: 							pointer;
}

.modalNewRequest .modal-body .nav-tabs{
	border: 							unset;
	gap: 								15px;
	margin-bottom: 						20px;
}

.modalNewRequest .modal-body .nav-tabs .nav-link{
	color: 								#000000;
	border: 							unset;
	padding: 							0;
	background-color: 					transparent;
	font-size: 							14px;
	opacity: 							0.3;
	display: 							flex;
	align-items: 						center;
}

.modalNewRequest .modal-body .nav-tabs .nav-link::before{
	content: 							"\025cf";
	font-size: 							9px;
	margin-right: 						5px;
}

.modalNewRequest .modal-body .nav-tabs .nav-link.active{
	font-weight: 						500;
	opacity: 							1;
}

.modalNewRequest .modal-body .tab-content{
	margin-bottom: 						50px;
}

.modalNewRequest .modal-body .tabHeaderContainer {
	display: 							flex;
	justify-content: 					space-between;
}

.modalNewRequest .modal-body .tabHeaderContainer .clientNameContainer {
	display: 							flex;
	flex-direction: 					column;
}

.modalNewRequest .modal-body .tabHeaderContainer .clientNameContainer > p{
	align-self: 						flex-end;
}

.modalNewRequest .modal-body .title{
	font-weight: 						700;
}

.modalNewRequest .modal-body .description{
	font-size: 							14px;
	font-weight: 						300;
	margin-bottom: 						55px;
}

.modalNewRequest .modal-body form{
	display: 							flex;
	flex-flow: 							row wrap;
	column-gap: 						10px;
	row-gap: 							15px;
}

.modalNewRequest .modal-body .inputContainer{
	flex: 								0 0 calc(100% / 2 - (10px * 1 / 2));
	display: 							flex;
	flex-flow: 							column nowrap;
	justify-content: 					flex-end;
	margin-bottom: 						0;
}

.modalNewRequest .modal-body .inputContainer .fileContainer{
	display: 							flex;
	gap: 								10px;
	align-items: 						center;
}

.modalNewRequest .modal-body .inputContainer .fileContainer .downloadFile{
	padding: 							10px 20px;
	font-size: 							12px;
}

.modalNewRequest .modal-body .inputContainer .fileContainer .fileName{
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
}

.modalNewRequest .modal-body .inputContainer.radioPerson{
	display: 							flex;
	flex-flow: 							row wrap;
	justify-content: 					flex-start;
}

.modalNewRequest .modal-body .inputContainer.radioPerson > label{
	font-size: 							14px;
	cursor: 							pointer;
}

.modalNewRequest .modal-body .inputContainer.radioPerson > label:first-of-type{
	margin-right: 						30px;
}

.modalNewRequest .modal-body .industryContainer{
	display: 							flex;
	flex-flow: 							row wrap;
	column-gap: 						20px;
	row-gap: 							15px;
}

.modalNewRequest .modal-body .industryContainer .inputContainer{
	flex: 								0 0 100%;
	display: 							flex;
	flex-flow: 							column nowrap;
	justify-content: 					flex-end;
	margin-bottom: 						0;
}

.modalNewRequest .modal-body .industryContainer .inputContainer.radioRepse{
	flex: 								0 0 calc(100% / 3 - (20px * 2 / 3));
	flex-flow: 							row wrap;
	align-items: 						center;
	justify-content: 					start;
}

.modalNewRequest .modal-body .industryContainer .inputContainer.radioRepse > label{
	cursor: 							pointer;
}

.modalNewRequest .modal-body .industryContainer .inputContainer.radioRepse > label:first-of-type{
	margin-right: 						30px;
}

.modalNewRequest .modal-body .industryContainer .inputContainer.textRepse{
	flex: 								1 0 0;
	visibility: 						hidden;
	opacity: 							0;
}

.modalNewRequest .modal-body .industryContainer .inputContainer.textRepse.show{
	visibility: 						visible;
	opacity: 							1;
}

.modalNewRequest .modal-body .titleActions{
	font-weight: 						700;
	margin-bottom: 						10px;
	display: 							flex;
	align-items: 						center;
	gap: 								25px;
}

.modalNewRequest .modal-body .titleActions::after{
	content: 							"Username and password will be created for supplier";
	width: 								fit-content;
	font-size: 							14px;
	font-weight: 						300;
	opacity: 							0.5;
}

.modalNewRequest .modal-body .buttonsContainer{
	display: 							flex;
	flex-flow: 							row nowrap;
	justify-content: 					space-between;
	margin-bottom: 						25px;
}

.modalNewRequest .modal-body .buttonsContainer .btnBgBlueTextGradient:last-of-type{
	-webkit-text-fill-color: 			unset;
	background: 						transparent;
}

.modalNewRequest .modal-body .buttonsContainer .btnBgBlueTextGradient:last-of-type:hover{
	color: 								#FFFFFF;
	background-color: 					var(--red);
}

.darkMode .newRequest .newRequestTable tbody .infoContainer .reviewed {
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

@media (max-width: 991px){
	/* NEW REQUEST */
	.modalNewRequest .modal-content{
		background: 						var(--grey);
		border-radius: 						35px 35px 0px 0px;
		box-shadow: 						0px -30px 100px 0px rgba(4, 26, 57, 0.55);
		top: 								40px;
	}

	.modalNewRequest .modal-body{
		padding: 							60px 30px 85px;
	}

	.modalNewRequest .modal-body .nav-tabs{
		gap: 								20px;
	}

	.modalNewRequest .modal-body .nav-tabs .nav-link{
		font-size: 							12px;
	}

	.modalNewRequest .modal-body .tabHeaderContainer {
		flex-direction: 					column;
	}

	.modalNewRequest .modal-body .tabHeaderContainer .clientNameContainer > h4 {
		font-size: 							16px;
	}

	.modalNewRequest .modal-body .tabHeaderContainer .clientNameContainer > p{
		align-self: 						flex-start;
	}

	.modalNewRequest .modal-body .description{
		margin-bottom: 						30px;
	}

	.modalNewRequest .modal-body form{
		row-gap: 							25px;
	}

	.modalNewRequest .modal-body .inputContainer{
		flex: 								0 0 100%;
	}

	.modalNewRequest .modal-body .industryContainer{
		row-gap: 							25px;
	}

	.modalNewRequest .modal-body .industryContainer .inputContainer.radioRepse{
		flex: 								0 0 100%;
	}

	.modalNewRequest .modal-body .titleActions{
		margin-bottom: 						25px;
		align-items: 						start;
		gap: 								10px;
		flex-flow: 							column nowrap;
	}

	.modalNewRequest .modal-body .titleActions::after{
		content: 							"Username and password will be created for supplier";
		font-size: 							12px;
		font-weight: 						400;
		opacity: 							0.5;
	}

	.modalNewRequest .modal-body .buttonsContainer{
		flex-flow: 							column nowrap;
		justify-content: 					unset;
		gap: 								15px;
		margin-bottom: 						25px;
	}
}