/* 	USER LOGGED */
.homeClass {
    transition:                         all 0.3s ease-in-out;
}

.userLogged{
	width: 								fit-content;
	display: 							flex;
	align-items: 						center;
	gap: 								15px;
	margin: 							85px 0 75px auto;
	position: 							relative;
	cursor: 							pointer;
	padding-right: 						30px;
}

.userLogged::after{
	content: 							"";
	width: 								25px;
	height: 							25px;
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size:					15px;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	right: 								0;
	margin:  							auto 0;
	transform: 							rotate(90deg);
	transition: 						all 0.3s ease-in-out;
}

.userLogged.active::after{
	transform: 							rotate(270deg);
}

.userLogged .userImage{
	width: 								35px;
	height: 							35px;
	object-fit: 						cover;
	object-position: 					center center;
	border-radius: 						100px;
	border: 							3px solid transparent;
	background: 						var(--gradient) center center no-repeat;
	background-size: 					35px 35px;
	background-clip: 					border-box;
}

.userLogged .logOut{
	text-decoration: 					none;
	color: 								#000000;
	display: 							flex;
	align-items: 						center;
	width: 								max-content;
	padding: 							10px 20px;
	background-color: 					#FFFFFF;
	position: 							absolute;
	top: 								100%;
	right: 								0;
	visibility: 						hidden;
	opacity: 							0;
	transition: 						all 0.3s ease-in-out;
}

.darkMode .userLogged .logOut{
	text-decoration: 					none;
	color: 								white;
	display: 							flex;
	align-items: 						center;
	width: 								max-content;
	padding: 							10px 20px;
	background-color: 					#131E29;
	position: 							absolute;
	top: 								100%;
	right: 								0;
	visibility: 						hidden;
	opacity: 							0;
	transition: 						all 0.3s ease-in-out;
}

.userLogged .logOut.show{
	visibility: 						visible;
	opacity: 							1;
}

.userLogged .logOut::before{
	content: 							"";
	width: 								25px;
	height: 							25px;
	background: 						url("../../images/logout.svg") center center no-repeat;
	background-size: 					25px;
	margin-right: 						10px;
}

@media(max-width: 991px){
	.logoAndMenuMobile{
		display: 						flex;
		align-items: 					center;
		justify-content: 				space-between;
		padding: 						25px 13px;
	}

	.logoAndMenuMobile .logo{
		height: 						35px;
		object-fit: 					contain;
		object-position: 				center center;
	}
}