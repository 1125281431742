/* CLIENTS*/
.clientsSupplierView .titleAndSearch{
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
	margin-bottom: 						55px;
}

.clientsSupplierView .titleAndSearch .searchAndSort{
	flex: 								0 0 25%;
}

.clientsSupplierView .titleAndSearch .searchAndSort .searchContainer input{
	padding-right: 						50px;
}

.clientsSupplierView .filters{
	display: 							flex;
	align-items: 						center;
	gap: 								10px;
	margin-bottom: 						10px;
}

.clientsSupplierView .filters .filter{
	display: 							flex;
	align-items: 						center;
	padding: 							10px 20px;
	background-color: 					#FFFFFF;
	position: 							relative;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.darkMode .clientsSupplierView .filters .filter{
	background-color: 					#131E29;
}

.clientsSupplierView .filters .filter.show{
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.clientsSupplierView .filters .filter.active{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.clientsSupplierView .filters .filter::after{
	content: 							"";
	width: 								20px;
	height: 							20px;
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size: 					15px;
	transform: 							rotate(90deg);
	margin-left: 						20px;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.clientsSupplierView .filters .filter.show::after{
	transform: 							rotate(270deg);
}

.clientsSupplierView .filters .filter > img{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-right: 						5px;
	pointer-events: 					none;
}

.clientsSupplierView .filters .filter > span{
	font-size: 							12px;
	pointer-events: 					none;
}

.clientsSupplierView .filters .filter .searchContainer{
	width: 								280px;
	background-color: 					#FFFFFF;
	padding: 							15px;
	position: 							absolute;
	top: 								105%;
	left: 								0;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
	cursor: 							default;
}

.darkMode .clientsSupplierView .filters .filter .searchContainer{
	background-color: 					#131E29;
}

.clientsSupplierView .filters .filter .searchContainer::after{
	right: 								35px;
}

.clientsSupplierView .filters .filter .searchContainer > input{
	background-color: 					var(--grey);
}

.darkMode .clientsSupplierView .filters .filter .searchContainer > input{
	background-color: 					#27343F;
}

.clientsSupplierView .filters .filter .filterOptions{
	width: 								max-content;
	position: 							absolute;
	top: 								105%;
	left: 								0;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	padding: 							20px;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
	cursor: 							default;
}

.darkMode .clientsSupplierView .filters .filter .filterOptions{
	background: 						#131E29;
}

.clientsSupplierView .filters .filter .filterOptions .optionsScroll{
	max-height: 						300px;
	overflow-y: 						auto;
}

/* width */
.clientsSupplierView .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar {
	width: 								3px;
}
  
 /* Track */
.clientsSupplierView .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
 }
  
/* Handle */
.clientsSupplierView .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.darkMode .clientsSupplierView .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						white;
}


.clientsSupplierView .filters .filter .filterOptions.show, .clientsSupplierView .filters .filter .searchContainer.show{
	visibility: 						visible;
	opacity: 							1;
	-webkit-text-fill-color: 			#000000;
}

.clientsSupplierView .filters .filter .filterOptions .title{
	font-size: 							14px;
	font-weight: 						700;
	margin-bottom: 						5px;
}

.darkMode .clientsSupplierView .filters .filter .filterOptions .title,
.darkMode .clientsSupplierView .filters .filter .filterOptions .option{
	-webkit-text-fill-color: 			white;
}

.clientsSupplierView .filters .filter .filterOptions .option{
	display: 							flex;
	align-items: 						center;
	padding: 							0 10px;
}

.clientsSupplierView .filters .filter .filterOptions.location{
	width: 								280px;
	top: 								290%;
}

.clientsSupplierView .filters .filter .filterOptions .option > input{
	margin-right: 						0;
	cursor: 							pointer;
}

.clientsSupplierView .filters .filter .filterOptions .option > label{
	flex:  								1 0 0;
	font-size: 							12px;
	opacity: 							0.8;
	cursor: 							pointer;
	padding:  							10px 0 10px 10px;
}

.clientsSupplierView .filters .btnBgBlueTextGradient{
	padding-inline: 					15px;
	margin-left: 						auto;
}

.clientsSupplierView .filters .btnBgBlueTextGradient::before{
	content: 							"";
    width: 								18px;
    height: 							18px;
    background: 						url("../../images/add.svg") center center no-repeat;
    background-size: 					18px 18px;
    margin-right: 						5px;
}

.clientsSupplierView .filters .filter .filterOptions .optionScroll{
	max-height: 						220px;
	overflow-y: 						auto;
}

.clientsSupplierView .filters .filter .filterOptions .optionScroll::-webkit-scrollbar {
	width: 								3px;
}

.clientsSupplierView .filters .filter .filterOptions .optionScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
}
 
.clientsSupplierView .filters .filter .filterOptions .optionScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.clientsSupplierView .filters .filter .filterOptions .actions{
	display: 							flex;
	gap: 								10px;
	justify-content: 					center;
	margin-top: 						15px;
}

.clientsSupplierView .filters .filter .filterOptions .actions > button {
	border: 							unset;
	background-color: 					transparent;
	padding: 							5px 15px;
	font-size: 							12px;
}

.darkMode .clientsSupplierView .filters .filter .filterOptions .actions > button:first-of-type {
	-webkit-text-fill-color: 			white;
}

.clientsSupplierView .filters .filter .filterOptions .actions > button:last-of-type {
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.clientsSupplierView .filters > .resetAll,
.clientsSupplierView > .resetAll {
    font-size:                          14px;
    font-weight:                        700;
	background: 						var(--gradient) text;
    -webkit-text-fill-color:            transparent;
	opacity: 							0;
	visibility: 						hidden;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.clientsSupplierView .filters > .resetAll.show,
.clientsSupplierView > .resetAll.show {
	opacity: 							1;
	visibility: 						visible;
}

.clientsSupplierView .filters > .resetAll:hover {
	opacity:                            0.5;
}

.clientsSupplierView .tableClientsSupplierView{
	margin-bottom: 						30px;
}

.clientsSupplierView .tableClientsSupplierView thead th:nth-of-type(1){
	width: 								25%;
}

.clientsSupplierView .tableClientsSupplierView tbody td:first-of-type .infoContainer > span {
	opacity: 							1;
	cursor: 							pointer;
}

.clientsSupplierView .tableClientsSupplierView tbody td:first-of-type .infoContainer > span:hover {
	background:							var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.clientsSupplierView .tableClientsSupplierView tbody .infoContainer .viewProfile{
	width: 								18px;
	height: 							18px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
}

.darkMode .clientsSupplierView .tableClientsSupplierView tbody .infoContainer .viewProfile{
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.clientsSupplierView .tableClientsSupplierView tbody td:last-of-type .infoContainer {
	justify-content: 					space-between;
}

.clientsSupplierView .addAndPagination{
	display: 							flex;
	align-items: 						center;
	justify-content: 					flex-end;
	margin-bottom: 						10px;
}

.clientsSupplierView .addAndPagination .pagination{
	display: 							flex;
	align-items: 						center;
	gap: 								5px;
}

.clientsSupplierView .addAndPagination .number{
	border: 							unset;
	font-size: 							12px;
	padding: 							2px 7.5px;
	opacity: 							0.5;
}

.clientsSupplierView .addAndPagination .number.active{
	opacity: 							1;
	font-weight: 						900;
	background:  						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.clientsSupplierView .addAndPagination .nextPage{
	width: 								13px;
	height: 							13px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
}

.clientsSupplierView .tableCount{
	width: 								fit-content;
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
	margin: 							0 0 55px auto;
}

@media (max-width: 991px){
	/* CLIENTS*/
	.clientsSupplierView .titleAndSearch{
		justify-content: 					unset;
		flex-flow: 							column nowrap;
		margin-bottom: 						20px;
	}

	.clientsSupplierView .titleAndSearch .title{
		margin-bottom: 						30px;
	}

	.clientsSupplierView .titleAndSearch .searchAndSort{
		width: 								100%;
		flex: 								0 0 100%;
		display: 							flex;
		gap: 								40px;
	}

	.clientsSupplierView .titleAndSearch .searchContainer{
		flex: 								1 0 0;
	}

	.clientsSupplierView .titleAndSearch .searchContainer input{
		padding-right: 						35px;
		height: 							100%;
	}

	.clientsSupplierView .titleAndSearch .searchAndSort .sortMobile{
		border: 							unset;
		background-color: 					#FFFFFF;
		padding: 							10px;
		font-size: 							12px;
		color: 								rgba(0, 0, 0, 0.40);
		font-weight: 						300;
		display: 							flex;
		gap: 								40px;
		align-items: 						center;
	}

	.darkMode .clientsSupplierView .titleAndSearch .searchAndSort .sortMobile{
		background-color: 					#131E29;
		color: 								white;
	}

	.clientsSupplierView .titleAndSearch .searchAndSort .sortMobile::after{
		content: 							"";
		width: 								20px;
		height: 							20px;
		background: 						url("../../images/arrowForward.svg") center center no-repeat;
		background-size: 					15px;
		transform: 							rotate(90deg);
	}

	.clientsSupplierView .titleAndSearch .searchAndSort .sortDataContainer{
		width: 								90vw;
		height: 							max-content;
		position: 							fixed;
		top: 								0;
		left: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto;
		border-radius: 						35px;
		padding: 							50px 35px;
		background: 						var(--grey);
		box-shadow: 						0px -30px 100px 0px rgba(4, 26, 57, 0.55);
		z-index: 							999;
		opacity: 							0;
		visibility: 						hidden;
		transition: 						all 0.3s ease-in-out;
	}

	.darkMode .clientsSupplierView .titleAndSearch .searchAndSort .sortDataContainer{
		background: 						#131E29;
	}

	.clientsSupplierView .titleAndSearch .searchAndSort .sortDataContainer::after{
		content: 							"";
		width: 								25px;
		height: 							25px;
		background: 						url("../../images/close.svg") center center no-repeat;
		background-size: 					25px;
		position: 							absolute;
		top: 								25px;
		right: 								25px;
	}

	.darkMode .clientsSupplierView .titleAndSearch .searchAndSort .sortDataContainer::after{
		mask: 								url("../../images/close.svg") center center no-repeat;
		mask-size: 							25px;
		background: 						white;
	}

	.clientsSupplierView .titleAndSearch .searchAndSort .sortDataContainer.show{
		opacity: 							1;
		visibility: 						visible;
	}

	.clientsSupplierView .titleAndSearch .searchAndSort .sortDataContainer > .title{
		font-size: 							16px;
		font-weight: 						700;
	}

	.clientsSupplierView .titleAndSearch .searchAndSort .sortDataContainer > span{
		font-size: 							12px;
		margin-bottom: 						20px;
	}

	.clientsSupplierView .titleAndSearch .searchAndSort .sortDataContainer > span:last-of-type{
		margin-bottom: 						0;
	}

	.clientsSupplierView .filters{
		display: 							flex;
		gap: 								10px;
		overflow-x: 						auto;
	}

	/* width */
	.clientsSupplierView .filters::-webkit-scrollbar {
		display: 							none;
	}
	
	.clientsSupplierView .filters .filter{
		padding: 							10px;
		position: 							static;
	}

	.clientsSupplierView .filters .filter > span{
		font-size: 							10px;
	}

	.clientsSupplierView .filters .filter .searchContainer{
		width: 								90vw;
		height: 							fit-content;
		padding: 							15px;
		position: 							fixed;
		top: 								unset;
		right: 								0;
		bottom: 							30px;
		margin: 							0 auto;
	}

	.clientsSupplierView .filters .filter .filterOptions{
		width: 								90vw;
		height: 							max-content;
		position: 							fixed;
		top: 								0;
		left: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto;
		border-radius: 						35px;
		padding: 							50px 35px;

	}
	.clientsSupplierView .filters .filter .filterOptions .closeImg {
		width: 								25px;
		height: 							25px;
		position: 							absolute;
		top: 								25px;
		right: 								25px;
	}

	.darkMode .clientsSupplierView .filters .filter .filterOptions .closeImg {
		filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
	}

	.clientsSupplierView .filters .filter .filterOptions .imgMenu{
		width: 								20px;
		height: 							20px;
		object-fit: 						contain;
		object-position: 					center center;
		margin-right: 						10px;
		display: 							inline-block;
	}

	.clientsSupplierView .filters .filter .filterOptions .title{
		display: 							inline-block;
		font-size: 							16px;
		margin-bottom: 						30px;
	}

	.clientsSupplierView .filters .filter .filterOptions .option{
		padding: 							0;
	}

	.clientsSupplierView .filters .filter .filterOptions.location{
		width: 								90vw;
		top: 								0;
	}

	.clientsSupplierView .filters .filter .filterOptions .option > input{
		order: 								2;
		margin-right: 						10px;
	}

	.clientsSupplierView .filters .filter .filterOptions .option > label{
		order: 								1;
		padding:  							10px 10px 10px 0;
	}

	.clientsSupplierView .filters .filter .filterOptions .actions{
		gap: 								0;
		justify-content: 					space-between;
		margin-top: 						50px;
	}

	.clientsSupplierView .filters .filter .filterOptions .actions > button{
		font-size: 							14px;
	}

	.clientsSupplierView .filters .btnBgBlueTextGradient{
		display: 							none;
	}

	.clientsSupplierView .filters .resetAll
	.clientsSupplierView .filters .btnBgBlueTextGradient,
	.clientsSupplierView .tableClientsSupplierView{
		display: 							none;
	}

	.clientsSupplierView > .resetAll {
        max-height:                         0;
        text-align:                         center;
    }

    .clientsSupplierView > .resetAll.show {
        max-height:                         50px;
    }

	.clientsSupplierView .clientList{
		display: 							flex;
		flex-flow: 							column nowrap;
		margin: 							25px 0;
	}

	.clientsSupplierView .clientList .clientRow{
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
		padding: 							15px 23px 25px;
	}

	.clientsSupplierView .clientList .clientRow:last-of-type{
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.clientsSupplierView .clientList .clientRow .clientName{
		font-weight: 						500;
		margin-bottom: 						20px;
	}

	.clientsSupplierView .clientList .clientRow .clientInformation{
		width: 								100%;
		display:  							grid;
		align-items: 						flex-end;
		justify-content: 					space-between;
		grid-template-columns: 				repeat(2, fit-content);
		grid-template-rows: 				repeat(8, max-content);
		column-gap: 						30px;
		row-gap: 							0;
	}

	.clientsSupplierView .clientList .clientRow .clientInformation .clientValue{
		font-size: 							12px;
	}

	.clientsSupplierView .clientList .clientRow .clientInformation .clientTitle{
		font-size: 							10px;
		font-weight: 						700;
		opacity: 							0.4;
	}

	.clientsSupplierView .clientList .clientRow .clientInformation > span:nth-of-type(1){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					1;
		grid-row-end: 						2;
	}

	.clientsSupplierView .clientList .clientRow .clientInformation > span:nth-of-type(2){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					2;
		grid-row-end: 						3;
		margin-bottom:						10px;
	}

	.clientsSupplierView .clientList .clientRow .clientInformation > span:nth-of-type(3){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					1;
		grid-row-end: 						2;
	}

	.clientsSupplierView .clientList .clientRow .clientInformation > span:nth-of-type(4){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					2;
		grid-row-end: 						3;
		margin-bottom: 						10px;
	}
	
	.clientsSupplierView .clientList .clientRow .clientInformation > span:nth-of-type(5){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					3;
		grid-row-end: 						4;
	}

	.clientsSupplierView .clientList .clientRow .clientInformation > span:nth-of-type(6){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					4;
		grid-row-end: 						5;
		margin-bottom:						10px;
	}

	.clientsSupplierView .clientList .clientRow .clientInformation > span:nth-of-type(7){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					3;
		grid-row-end: 						4;
	}

	.clientsSupplierView .clientList .clientRow .clientInformation > span:nth-of-type(8){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					4;
		grid-row-end: 						5;
		margin-bottom:						10px;
	}

	.clientsSupplierView .clientList .clientRow .clientInformation > span:nth-of-type(9){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					5;
		grid-row-end: 						6;
	}

	.clientsSupplierView .clientList .clientRow .clientInformation > span:nth-of-type(10){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					7;
		grid-row-end: 						8;
	}

	.clientsSupplierView .addAndPagination{
		padding: 							0 20px;
	}

	.clientsSupplierView .tableCount{
		font-size: 							10px;
		margin: 							0 25px 25px auto;
	}

	.clientsSupplierView > .btnBgBlueTextGradient{
		padding-inline: 					15px;
		margin: 							0 auto 25px;
	}
	
	.clientsSupplierView > .btnBgBlueTextGradient::before{
		content: 							"";
		width: 								18px;
		height: 							18px;
		background: 						url("../../images/add.svg") center center no-repeat;
		background-size: 					18px 18px;
		margin-right: 						5px;
	}
}