/* NAVIGATION MENU */
.navigationMenu{
	display: 							flex;
	flex-flow: 							column nowrap;
	width: 								60px;
	height: 							100vh;
	background: 						#FFFFFF;
	box-shadow: 						15px 15px 100px 0px rgba(4, 26, 57, 0.10);
	padding: 							50px 0;
	position: 							fixed;
	top: 								0;
	left: 								0;
	bottom: 							0;
	transition: 						all 0.3s ease-in-out;
	overflow: 							hidden;
}

.navigationMenu .darkModeIcon {
    margin-top:                         auto;
}

.navigationMenu .iconProdensa{
	width: 								25px;
	height: 							45px;
	object-fit: 						contain;
	object-position: 					center center;
	margin:  							0 auto 50px;
}

.navigationMenu .logo{
	display: 							none;
	height: 							45px;
	object-fit: 						contain;
	object-position: 					center center;
	margin:  							0 auto 50px;
}

.navigationMenu .btnNav{
	min-width: 							max-content;
	border:  							unset;
	background-color: 					transparent;
	padding: 							20px;
	display: 							flex;
	align-items: 						center;
	transition: 						all 0.3s ease-in-out;
}

.navigationMenu.darkMode .btnNav{
	color: 								white;
}

.navigationMenu .btnNav.active{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.navigationMenu .btnNav.darkMode.active{
	background: 						var(--gradient), #243D55;
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.navigationMenu .btnNav .iconBtnNav{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-right: 						25px;
}

.navigationMenu .btnNav .arrowBtnNav{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-left: 						auto;
}

.navigationMenu .iconGrowMenu{
	width: 								fit-content;
	height: 							10px;
	padding: 							20px;
	background: 						url("../../images/iconGrowMenu.svg") left center no-repeat;
	background-size: 					15px 15px;
	margin: 							0 0 0 auto;
	cursor: 							pointer;
	transition: 						all 0.3s ease-in-out;
}

.navigationMenu .iconGrowMenu.darkMode{
    background: 						url("../../images/iconGrownWhite.svg") left center no-repeat;
    background-size: 					15px 15px;
}

/* MENU OPEN CSS */
.navigationMenu.grow{
	width: 								315px;
}

.navigationMenu.grow .iconProdensa{
	display: 							none;
}

.navigationMenu.grow .logo{
	display: 							block;
}

.navigationMenu.grow .btnNav{
	padding: 							20px 40px;
}

.navigationMenu.grow .iconGrowMenu{
	margin: 							0 20px 0 auto;
	transform: 							rotate(-180deg);
}

.navigationMenu .hamburguer{
	display: 							none;		
}

.navigationMenu .btnBgBlueTextGradient{
	display: 							none;
}

@media (max-width: 991px){
	/* NAVIGATION MENU */
	.navigationMenu{
		width: 								100vw;
		height: 							100%;
		background: 						rgba(255, 255, 255, 0.95);
		box-shadow: 						0px 30px 100px 0px rgba(4, 26, 57, 0.55);
		padding: 							25px;
		position: 							absolute;
		top: 								0;
		left: 								-100%;
		bottom: 							0;
		overflow: 							hidden;
		opacity: 							0;
		visibility: 						hidden;
		transition: 						all 0.3s ease-in-out;
		z-index: 							1000;
	}

	.navigationMenu .iconProdensa{
		display: 							none;
	}

	.navigationMenu.show{
		left: 								0;
		opacity: 							1;
		visibility: 						visible;
	}

	.navigationMenu > div{
		display: 							flex;
		align-items: 						center;
		justify-content: 					space-between;
		margin-bottom: 						55px;
	}

	.navigationMenu .hamburguer{
		display: 							block;
		width: 								18px;
		height: 							12px;
		object-fit: 						contain;
		object-position: 					center center;
	}

	.navigationMenu .logo{
		display: 							inline-block;
		height: 							35px;
		margin:  							0;
	}

	.navigationMenu .btnNav{
		width: 								fit-content;
		font-size: 							16px;
		border:  							unset;
		background-color: 					transparent;
		padding: 							0;
		margin: 							0 auto;
		margin-bottom: 						40px;
		gap: 								15px;
	}

	.navigationMenu .btnNav.active{
		background: 						transparent;
		background-clip: 					unset;
		-webkit-background-clip: 			unset;
		-webkit-text-fill-color: 			#000000;
	}

	.navigationMenu .btnNav:nth-last-of-type(1){
		margin-bottom: 						0;
	}

	.navigationMenu .btnNav .iconBtnNav{
		margin-right: 						0;
	}

	.navigationMenu .btnNav .arrowBtnNav{
		display: 							none;
	}

	.navigationMenu .btnBgBlueTextGradient{
		width: 								fit-content;
		display: 							flex;
		margin: 							120px auto;
		font-size: 							16px;
		padding: 							10px 50px;
	}

	.navigationMenu .btnBgBlueTextGradient::before{
		content: 							"";
		width: 								20px;
		height: 							20px;
		background: 						url("../../images/logout.svg") center center no-repeat;
		background-size: 					20px;
		margin-right: 						10px;
	}

	.navigationMenu .iconGrowMenu{
		display: 							none;		
	}
}