.product-assignment {
    margin:                                 30px 0;
}

.product-assignment .table-header {
    display:                                grid;
    grid-template-columns:                  repeat(4, 1fr);
    column-gap:                             26px;
    background-color:                       rgba(4, 26, 57, 0.03);
    box-shadow:                             0 0 30px rgba(35, 107, 216, 0.08);
    padding:                                20px;
}

.darkMode .product-assignment .table-header {
    background-color:                       #131E29;
}

.product-assignment .table-header .header-item {
    display:                                flex;
    gap:                                    5px;
}

.product-assignment .table-header .header-item > span {
    font-weight:                            700;
    font-size:                              12px;
}

.product-assignment .table-header .header-item > img {
    cursor:                                 pointer;
}

/* Table content */
.product-assignment .table-content .product-row {
    display:                                grid;
    align-items:                            center;
    grid-template-columns:                  repeat(4, 1fr);
    column-gap:                             26px;
    padding:                                20px;
    border-bottom:                          1px solid rgba(0, 0, 0, 0.03);
}

.product-assignment .table-content .product-row .product-search {
    position:                               relative;
    width:                                  calc(100% - 20px);
}

.product-assignment .table-content .product-row .product-search > img {
    position:                               absolute;
    left:                                   103%;
    top:                                    50%;
    transform:                              translateY(-50%);
    cursor:                                 pointer;
}

.product-assignment .table-content .product-row .product-search .searchContainer > input:disabled {
    background-color:                       rgba(4, 26, 57, 0.03);
}

.product-assignment .table-content .product-row .searchContainer .listOfProducts {
    display:                                flex;
    flex-direction:                         column;
    gap:                                    10px;
    position:                               absolute;
    width:                                  100%;
    top:                                    100%;
    background-color:                       #FFF;
    opacity:                                0;
    visibility:                             hidden;
    transition:                             all 0.3s ease-in-out;
    z-index:                                2;
    max-height:                             200px;
    overflow-y:                             auto;
    box-shadow:                             0px 5px 30px 0px rgba(35, 107, 216, 0.15);
}

.product-assignment .table-content .product-row .searchContainer .listOfProducts::-webkit-scrollbar {
	width: 						            3px;
	height: 						        3px;
}

.product-assignment .table-content .product-row .searchContainer .listOfProducts::-webkit-scrollbar-track {
	background: 					        rgba(0, 0, 0, 0.05);
}

.product-assignment .table-content .product-row .searchContainer .listOfProducts::-webkit-scrollbar-thumb {
	background: 					        var(--darkBlue);
}

.product-assignment .table-content .product-row .searchContainer .listOfProducts.show {
    opacity:                                1;
    visibility:                             visible;
}
    
.product-assignment .table-content .product-row .searchContainer .listOfProducts > span {
    cursor:                                 pointer;
    padding-left:                           20px;
    color:                                  rgba(0, 0, 0, 0.7);
}
    
.product-assignment .table-content .product-row .searchContainer .listOfProducts > span:hover {
    color:                                  rgba(0, 0, 0, 1);
    background-color:                       rgba(0, 0, 0, 0.03);
}

.product-assignment .table-content .product-row .dropdown-toggle:disabled {
    background-color:                       rgba(0, 0, 0, 0.05);
    color:                                  rgba(0, 0, 0, 0.7);
    border-color:                           transparent;
}

.product-assignment .table-content .product-row .dropdown.warning .dropdown-toggle{
    border:                                 1px solid red;
    color:                                  red;
}

@media (width <= 991px) {
    .product-assignment .table-content .product-row {
        grid-template-columns:              repeat(2, 1fr);
        column-gap:                         10px;
        row-gap:                            15px;
        border-bottom:                      unset;
        padding:                            0 0 20px;
    }

    .product-assignment .table-content .product-row .product-name,
    .product-assignment .table-content .product-row .product-search,
    .product-assignment .table-content .product-row .dropdown {
        grid-column:                        -1 / 1;
    }

    .product-assignment .table-content .product-row .product-name {
        background-color:                   rgba(4, 26, 57, 0.03);
        padding:                            10px 20px;
    }
    
    .product-assignment .table-content .product-row .product-name,
    .product-assignment .table-content .product-row .product-location {
        font-weight:                        500;
        font-size:                          14px;
    }

    .product-assignment .table-content .product-row .product-location {
        display:                            flex;
        flex-direction:                     column;
    }

    .product-assignment .table-content .product-row .product-location,
    .product-assignment .table-content .product-row .product-search,
    .product-assignment .table-content .product-row .dropdown {
        padding:                            0 20px;
    }

    .product-assignment .table-content .product-row .product-location::after {
        content:                            "Location";
        font-size:                          10px;
        font-weight:                        700;
        color:                              rgba(0, 0, 0, 0.4);
    }

    .product-assignment .table-content .product-row .product-search {
        display:                            flex;
        gap:                                15px;
        width:                              100%;
        
    }

    .product-assignment .table-content .product-row .product-search > img {
        position:                           relative;
        transform:                          unset;
        top:                                unset;
        left:                               unset;
    }

    .product-assignment .table-content .product-row .product-search .searchContainer{
        width:                              100%;
    }

    .product-assignment .table-content .product-row .dropdown-toggle::after {
        right:                              30px;
    }
}