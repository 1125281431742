.addStaffModal .inactiveInput {
    width:                              100%;
    border:                             unset;
    background-color:                   #FFFFFF;
    padding:                            10px;
    font-size:                          12px;
    font-weight:                        300;
    position:                           relative;
    border-bottom:                      1px solid transparent;
    transition:                         all 0.3s ease-in-out;
    resize:                             none;
    text-transform:                     uppercase;
}

.addStaffModal .buttonGroup {
    display:                            flex;
    flex-direction:                     row;
    justify-content:                    flex-end;
}

.addStaffModal .buttonGroup .btnBgWhiteTextBlack {
    margin:                             15px 0 0 15px;
}

.addStaffModal .buttonGroup .btnBgBlueTextBlue {
    margin:                             15px 0 0 15px;
    height:                             fit-content;
    display:                            flex;
    align-items:                        center;
    justify-content:                    center;
    font-size:                          14px;
    padding:                            10px 40px;
    background:                         var(--gradient), var(--blue);
    background-clip:                    text, padding-box;
    -webkit-background-clip:            text, padding-box;
    -webkit-text-fill-color:            transparent;
    border:                             unset;
    transition:                         all 0.3s ease-in-out;
}

.addStaffModal .buttonGroup .btnBgBlueTextBlue:hover {
    background:                         var(--gradient), var(--darkBlue);
    background-clip:                    text, padding-box;
    -webkit-background-clip:            text, padding-box;
    -webkit-text-fill-color:            transparent;
}

.addStaffModal .memberInfo {
    display:                            flex;
    padding:                            15px 0 15px 0;
}

.addStaffModal .memberInfo .memberInfoData {
    display:                            flex;
    flex:                               1;
    flex-direction:                     column;
    align-items:                        flex-start;
    padding-right: 10px;
}

/* .addStaffModal .memberInfo .memberInfoData:nth-child(2) {
    display:                            flex;
    flex:                               1;
    flex-direction:                     column;
    margin-left:                        30px;
} */

.addStaffModal .memberInfo .textLabel {
    font-size:                          12px;
    font-weight:                        700;
}

.addStaffModal .memberInfo .textValue {
    font-size:                          12px;
    font-weight:                        300;
}

.addStaffModal .inputContainer .textLabel {
    font-size:                          12px;
    font-weight:                        700;
    margin-bottom:                      5px;
}

.addStaffModal .inputContainer {
    width:                              35%;
}

.addStaffModal .membersScrollContainer {
    max-height: 300px; /* Asegura que el contenedor no exceda la altura disponible del modal */
    overflow-y: auto; /* Habilita el scroll vertical si el contenido es demasiado alto */
    padding-right: 15px; /* Agrega espacio a la derecha para evitar que el contenido se superponga con la barra de scroll */
    margin-bottom: 20px; /* Agrega espacio debajo del contenedor para separación visual */
}

/* Opcional: Personalización de la barra de scroll */
.addStaffModal .membersScrollContainer::-webkit-scrollbar {
    width: 								3px;
	height: 							3px;
}

.addStaffModal .membersScrollContainer::-webkit-scrollbar-thumb {
    background: 						var(--darkBlue);
}

.addStaffModal .membersScrollContainer::-webkit-scrollbar-thumb:hover {
    background: 						var(--darkBlue);
}

.addStaffModal .membersScrollContainer::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
 }
