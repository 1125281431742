/* CONTRACTS */
.contracts .inputSections .titleAndClient{
	width: 								100%;
	display: 							flex;
	align-items: 						center;
	gap: 								10px;
	position: 							relative;
	margin-bottom: 						15px;
}

.contracts .inputSections .titleAndClient .title{
	position: 							relative;
	flex:  								1 0 0;	
	font-weight: 						700;
	padding: 							20px 30px;
	background-color: 					rgba(4, 26, 57, 0.03);
	display:                            flex;
	align-items:                        center;
	gap:                                20px;
}

.contracts .inputSections .titleAndClient .clientDropdown{
	width:                              20%;
}

.contracts .inputSections .contractInformation{
	display: 							flex;
	flex-flow: 							row wrap;
	margin-bottom: 						40px;
	column-gap: 						10px;
}

.contracts .inputSections .contractInformation .inputContainer{
	max-width: 							calc(100% / 3 - (10px * 2 / 3));
	flex: 								0 0 calc(100% / 3 - (10px * 2 / 3));
	margin-bottom: 						15px;
}

.contracts .inputSections .contractInformation .inputContainer .fileContainer{
	display: 							flex;
	gap: 								10px;
	align-items: 						center;
	position: 							relative;
	padding-right: 						20px;
}

.contracts .inputSections .contractInformation .inputContainer .fileContainer .downloadFile{
	padding: 							10px 20px;
	font-size: 							12px;
}

.contracts .inputSections .contractInformation .inputContainer .fileContainer .fileName{
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
}

.contracts .inputSections .contractInformation .inputContainer .fileContainer .close{
	width: 								15px;
	height: 							15px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
	right: 								0;
	cursor: 							pointer;
}

.contracts .inputSections .contractInformation .inputContainer .deleteIcon {
	width: 								15px;
	height: 				    		15px;
	object-fit: 				    	contain;
	object-position: 					center center;
	cursor: 							pointer;
	position:                           absolute;
	left:                               0;
	top:                                0;
	bottom:                             0;
	margin:                             auto 0;
}

@media(max-width: 991px){
	/* INFORMATION COMPLIANCE */
	.contracts .inputSections{
		margin-bottom: 						30px;
		column-gap: 						0;
	}

	.contracts .inputSections .titleAndClient{
		flex-flow: 							row wrap;
		column-gap: 						30px;
	}

	.contracts .inputSections .titleAndClient .title{
		padding: 							10px 20px;
	}

	.contracts .inputSections .contractInformation .inputContainer{
		max-width: 							100%;
		flex: 								0 0 100%;
		margin-bottom: 						20px;
	}

	.contracts .inputSections .inputContainer .deleteIcon{
		position: 							absolute;
		left: 								unset !important;
		bottom: 							unset !important;
		right: 								5px;
	}
}