.profilePhotoModal {
    cursor: pointer;
}

.buttonsContainer {
    display:                                        flex;
    justify-content:                                flex-end;
    gap:                                            10px;
}

.btnBgWhiteTextBlack {
    margin: 0;
}

.editButton{
	height: 							fit-content;
	display: 							flex;
	align-items: 						center;
	justify-content: 					center;
	font-size: 							14px;
	padding: 							10px 50px;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	border: 							unset;
	transition: 						all 0.3s ease-in-out;
}

.editButton:hover{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.editButton.disabled {
    background-color:                   var(--gray);
}

@media (max-width: 991px) {
	.staffModal .modal-body .staffInformation .employeeInformation .dataContainer {
		display: 						flex;
		flex: 							1;
		justify-content: 				space-around;
	}

	.buttonsContainer {
		display:                    	flex;
		flex-direction: 				column-reverse;
		justify-content:                flex-end;
		gap:                            10px;
	}

	.buttonsContainer .btnBgWhiteTextBlack {
		display: 						flex;
		flex: 							1;
	}

	.buttonsContainer .editButton {
		display: 						flex;
		flex: 							1;
		padding: 						10px 30px;
	}
}