/* CLIENT INFORMATION */
.clientInformation .header{
	position: 							relative;
	display: 							flex;
	flex-flow: 							row nowrap;
	background-color: 					#FFFFFF;
	padding: 							60px 50px 40px 100px;
	box-shadow: 						0 4px 30px 0 rgba(35, 107, 216, 0.08);
}

.darkMode .clientInformation .header{
	background-color: 					#202B34;
}

.clientInformation .header::before{
	content: 							"";
	width: 								1px;
	height: 							80%;
	background-color: 					rgba(0, 0, 0, 0.05);
	position: 							absolute;
	left: 								69.5%;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
}

.clientInformation .header .generalInformation {
	max-width: 							70%;
	flex: 								1 0 70%;
	padding-right: 						60px;
}

.clientInformation .header .generalInformation .headInformation {
	display: 							flex;
	align-items: 						center;
	gap: 								15px;
	margin-bottom: 						20px;
}

.clientInformation .header .generalInformation .headInformation .goBack{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	transform: 							rotate(180deg);
	cursor: 							pointer;
}

.clientInformation .header .generalInformation .headInformation .profilePhoto{
	width: 								80px;
    height: 							80px;
    border-radius: 						100px;
    border: 							5px solid transparent;
    background: 						var(--gradient) padding-box, var(--gradient) border-box;
	object-fit: 						contain;
	object-position: 					center center;
}

.clientInformation .header .generalInformation .headInformation .ratingValue{
	font-size: 							16px;
	display: 							flex;
	align-items: 						center;
	padding: 							5px 10px;
	font-weight: 						700;
	background: 						var(--gradient) text, var(--blue) padding-box;
	-webkit-text-fill-color: 			transparent;
}

.clientInformation .header .generalInformation .headInformation .ratingValue .ratingImg{
	width: 								20px;
    height: 							20px;
    object-fit: 						contain;
    object-position: 					center center;
}

.clientInformation .header .generalInformation .industriesInformation {
	display: 							flex;
	align-items: 						center;
	gap: 								10px;
	margin-bottom: 						20px;
}

.clientInformation .header .generalInformation .industriesInformation .industriesContainer {
	flex: 								1 0 80%;
	display: 							flex;
	flex-flow: 							row nowrap;
	align-items: 						center;
	gap: 								5px;
	overflow: hidden;
}

.clientInformation .header .generalInformation .industriesInformation .industriesContainer > span{
	font-size: 							12px;
	font-weight: 						700;
	background: 						var(--gradient) text, var(--darkBlue) padding-box;
	-webkit-text-fill-color: 			transparent;
	padding: 							10px 15px;
	text-wrap: 							nowrap;
	text-overflow: 						ellipsis;
	overflow: 							hidden;
}

.clientInformation .header .generalInformation .industriesInformation .btnBgBlueTextGradient {
	padding: 							10px 25px;
}

.clientInformation .header .generalInformation .description{
	width: 								80%;
	font-size: 							12px;
	opacity: 							0.8;
	margin-bottom: 						20px;
}

.clientInformation .header .contactInformation{
	flex: 								1 0 30%;
	padding-left: 						50px;
}

.clientInformation .header .contactInformation .cvpBadge{
	width: 								60px;
    height: 							60px;
    object-fit: 						cover;
    object-position: 					center center;
	margin-bottom: 						30px;
}

.clientInformation .header .contactInformation .info{
	margin-bottom: 						25px;
}

.clientInformation .header .contactInformation .info:last-of-type{
	margin-bottom: 						0;
}

.clientInformation .header .contactInformation .info .infoTitle{
	font-size: 							12px;
	font-weight: 						700;
}

.clientInformation .header .contactInformation .info .infoText{
	font-size: 							12px;
	opacity: 							0.6;
	margin-bottom: 						10px;
}

.clientInformation .header .contactInformation .info .infoText:last-of-type{
	margin-bottom: 						0;
}

.clientIndustriesModalClientInformation .modal-content {
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.clientIndustriesModalClientInformation .modal-body {
	padding: 							50px 65px 35px;
}

.clientIndustriesModalClientInformation .modal-body .commentTitle {
	font-weight: 						700;
	margin-bottom: 						20px;
}

.clientIndustriesModalClientInformation .modal-body .actionButton .btnBgBlueTextGradient {
	margin-top: 						30px;
	margin-left: 						auto;
}

.clientIndustriesModalClientInformation .modal-body .industriesContainer { 
	display: 							flex;
	flex-flow: 							row wrap;
	gap: 								10px;
}

.clientIndustriesModalClientInformation .modal-body .industriesContainer .industry {
	flex: 								1 0 auto;
	font-size: 							16px;
	font-weight: 						700;
	text-align: 						center;
	padding: 							10px 15px;
	background: 						var(--gradient) text, var(--darkBlue) padding-box;
	-webkit-text-fill-color: 			transparent;
}

.clientInformation .nav-tabs{
	padding-top: 						35px;
}

@media (max-width: 991px){
	/* CLIENT INFORMATION */
	.clientInformation .header{
		flex-flow: 							column nowrap;
		padding: 							80px 35px;
		align-items: 						center;
	}
	
	.clientInformation .header::before{
		content: 							none;
	}
	
	.clientInformation .header .generalInformation {
		max-width: 							100%;
		flex: 								1 0 0%;
		padding-right: 						0;
		margin-bottom: 						35px;
	}
	
	.clientInformation .header .generalInformation .headInformation {
		flex-flow: 							column nowrap;
		margin-bottom: 						15px;
	}

	.clientInformation .header .generalInformation .headInformation .goBack{
		align-self: flex-start;
	}
	
	.clientInformation .header .generalInformation .headInformation .profilePhotoContainer{
		width: 								90px;
		height: 							90px;
	}
	
	.clientInformation .header .generalInformation .headInformation .profilePhotoContainer .profilePhoto{
		width: 								70px;
		height: 							70px;
	}

	.clientInformation .header .generalInformation .headInformation h3{
		font-size: 							26px;
		text-align: 						center;
	}
	
	.clientInformation .header .generalInformation .industriesInformation {
		gap: 								15px;
		margin-bottom: 						15px;
		flex-flow: 							row wrap;
	}
	
	.clientInformation .header .generalInformation .industriesInformation .industriesContainer {
		flex: 								1 0 100%;
		flex-flow: 							row wrap;
		justify-content: 					center;
	}
	
	.clientInformation .header .generalInformation .industriesInformation .btnBgBlueTextGradient {
		width: 								100%;
	}
	
	.clientInformation .header .generalInformation .description{
		width: 								100%;
		text-align: 						center;
		margin-bottom: 						0;
	}

	.clientInformation .header .generalInformation > .btnBgBlueTextGradient{
		display: 							none;
	}
	
	.clientInformation .header .contactInformation{
		flex: 								1 0 0;
		padding-left: 						0;
	}
	
	.clientInformation .header .contactInformation .cvpBadge{
		margin: 							0 auto 15px;
	}
	
	.clientInformation .header .contactInformation .info{
		text-align: 						center;
		margin: 							0 auto 15px;
	}
	
	.clientInformation .header .contactInformation .info:last-of-type{
		margin: 							0 auto 0;
	}

	.clientInformation .header > .btnBgBlueTextGradient {
		width: 								100%;
	}

	.clientIndustriesModalClientInformation .modal-body {
		padding: 							30px 25px;
	}

	.clientIndustriesModalClientInformation .modal-body .commentTitle {
		margin-bottom: 						30px;
	}

	.clientIndustriesModalClientInformation .modal-body .actionButton .btnBgBlueTextGradient {
		width: 								100%;
		font-size: 							16px;
	}

	.clientIndustriesModalClientInformation .modal-body .industriesContainer .industry {
		font-size: 							12px;
	}

	.clientInformation .nav-tabs{
		padding-top: 						20px;
	}
}