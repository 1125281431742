/* INFORMATION COMPLIANCE */
.informationCompliance .inputSections{
	display: 							flex;
	flex-flow: 							row wrap;
	margin-bottom: 						40px;
	column-gap: 						10px;
}

.informationCompliance .inputSections .titleAndActions{
	width: 								100%;
	display: 							flex;
	align-items: 						center;
	gap: 								10px;
	position: 							relative;
	margin-bottom: 						15px;
}

.informationCompliance .inputSections .titleAndActions .title{
	position: 							relative;
	flex:  								1 0 0;	
	font-weight: 						700;
	padding: 							20px 30px;
	background-color: 					rgba(4, 26, 57, 0.03);
}

.darkMode .informationCompliance .inputSections .titleAndActions .title{
	background-color: 					#27343F;
}

.informationCompliance .inputSections .titleAndActions .comment{
	width: 								18px;
	height: 							18px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
	margin-left: 						auto;
}

.darkMode .informationCompliance .inputSections .titleAndActions .comment{
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.informationCompliance .inputSections .titleAndActions .commentContainer{
	position: 							absolute;
	top: 								100%;
	right: 								0;
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.20);
	opacity: 							0.92;
	padding: 							45px 65px 35px;
	visibility: 						hidden;
	opacity: 							0;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
}

.darkMode .informationCompliance .inputSections .titleAndActions .commentContainer{
	background: 						#131E29;
}

.informationCompliance .inputSections .titleAndActions .commentContainer.showMenu{
	visibility: 						visible;
	opacity: 							1;
}

.informationCompliance .inputSections .titleAndActions .commentContainer > img{
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								10px;
	right: 								10px;
	cursor: 							pointer;
}

.informationCompliance .inputSections .titleAndActions .commentContainer > h5{
	font-weight: 						700;
	margin-bottom: 						20px;
}

.informationCompliance .inputSections .titleAndActions .commentContainer > textarea{
	width: 								450px;
	padding: 							20px;
	margin-bottom: 						20px;
}

.informationCompliance .inputSections .titleAndActions .commentContainer > .actions{
	display: 							flex;
	gap: 								10px;
	justify-content: 					flex-end;
}

.informationCompliance .inputSections .titleAndActions .commentContainer > .actions .btnBgBlueTextGradient:first-of-type{
	-webkit-text-fill-color: 			unset;
	background-color: 					transparent;
}

.darkMode .informationCompliance .inputSections .titleAndActions .commentContainer > .actions .btnBgBlueTextGradient:first-of-type{
	-webkit-text-fill-color: 			white;
}

.informationCompliance .inputSections .inputContainer{
	max-width: 							calc(100% / 3 - (10px * 2 / 3));
	flex: 								0 0 calc(100% / 3 - (10px * 2 / 3));
	margin-bottom: 						15px;
}

.informationCompliance .inputSections .inputContainer.moreWidth{
	max-width: 							100%;
	flex: 								1 0 0;
}

.informationCompliance .inputSections .inputContainer.fourItems{
	width: 								calc(100% / 4 - (10px * 3 / 4));
	flex: 								0 0 calc(100% / 4 - (10px * 3 / 4));
}

.informationCompliance .inputSections .inputContainer.fourItems.title{
	margin: 							0;
}

.informationCompliance .inputSections .inputContainer.area::after{
	content: 							"sq.ft";
	font-size: 							12px;
	color: 								rgba(0, 0, 0, 0.4);
	position: 							absolute;
	bottom: 							10px;
	right: 								10px;
}

.informationCompliance .inputSections .inputContainer.percent::after{
	content: 							"%";
	font-size: 							12px;
	color: 								rgba(0, 0, 0, 0.4);
	position: 							absolute;
	bottom: 							10px;
	right: 								10px;
}

.darkMode .informationCompliance .inputSections .inputContainer.area::after,
.darkMode .informationCompliance .inputSections .inputContainer.percent::after{
	color: 								white;
}

.informationCompliance .inputSections .labelSales{
	width: 								100%;
	font-size: 							12px;
	font-weight: 						700;
	margin-bottom: 						5px;
}

.informationCompliance .inputSections .customerInfo,
.informationCompliance .inputSections .agreementInfo{
	flex: 								0 0 100%;
	display: 							flex;
	gap: 								10px;
	position: 							relative;
	margin-bottom: 						10px;
}

.informationCompliance .inputSections .customerInfo .inputContainer,
.informationCompliance .inputSections .agreementInfo .inputContainer{
	margin: 							0;
	justify-content: 					center;
}

.informationCompliance .inputSections .deleteIcon{
	width: 								15px;
	height: 							15px;
	object-fit: 						contain;
	object-position: 					center center;
	margin: 							auto 0;
	cursor: 							pointer;
}

.informationCompliance .inputSections .add{
	position: 							relative;
	padding: 							10px 15px 10px 30px;
	margin-top: 						10px;
}

.informationCompliance .inputSections .add::before{
	content: 							"";
	width: 								17px;
	height: 							17px;
	background: 						url("../../images/add.svg") center center no-repeat;
	background-size: 					17px 17px;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	left: 								10px;
	margin: 							auto 0;
}

.informationCompliance .inputSections .containerCustomersCertified{
	flex-flow: 							row nowrap;
	gap: 								10px;
}

.informationCompliance .inputSections .inputContainer .fileContainer{
	display: 							flex;
	gap: 								10px;
	align-items: 						center;
	position: 							relative;
	padding-right: 						20px;
}

.informationCompliance .inputSections .inputContainer .fileContainer .downloadFile{
	padding: 							10px 20px;
	font-size: 							12px;
}

.informationCompliance .inputSections .inputContainer .fileContainer .fileName{
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
}

.darkMode .informationCompliance .inputSections .inputContainer .fileContainer .fileName{
	color: 								white;
}

.informationCompliance .inputSections .inputContainer .fileContainer .close{
	width: 								15px;
	height: 							15px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
	right: 								0;
	cursor: 							pointer;
}

.informationCompliance .inputSections .commodityContainer .buttonCommodities {
	position: 							relative;
	width:                              100%;
	height:                             39px;
	background-color: 					#FFFFFF;
	padding: 							10px 30px 10px 10px;
	font-size: 							12px;
	font-weight: 						300;
	border-bottom: 						1px solid transparent;
	cursor: 							pointer;
	z-index: 							1;
	transition: 						all 0.3s ease-in-out;
    text-wrap:                          nowrap;
    text-overflow:                      ellipsis;
    overflow:                           hidden;
}

.darkMode .informationCompliance .inputSections .commodityContainer .buttonCommodities {
	background-color: 					#27343F;
	color: 								white;
}

.informationCompliance .inputSections .commodityContainer .buttonCommodities::after {
	content: 							"";
	width: 								15px;
	height: 							15px;
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size: 					15px 15px;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	right: 								10px;
	margin: 							auto 0;
	transform: 							rotate(90deg);
	pointer-events: 					none;
	transition: 						all 0.3s ease-in-out;
}

.informationCompliance .inputSections .commodityContainer .buttonCommodities.default {
	color: 								rgba(0, 0, 0, 0.5);
}

.informationCompliance .inputSections .commodityContainer .buttonCommodities:hover,
.informationCompliance .inputSections .commodityContainer .buttonCommodities.active {
	color: 								#000000;
	border-bottom: 						1px solid #009AFF;
}

.darkMode .informationCompliance .inputSections .commodityContainer .buttonCommodities:hover,
.darkMode .informationCompliance .inputSections .commodityContainer .buttonCommodities.active {
	background-color: 					#131E29;
	color: 								white;
}

.informationCompliance .inputSections .commodityContainer .buttonCommodities.active::after { 
	transform: 							rotate(270deg);
}

.informationCompliance .inputSections .commodityContainer .commodityList { 
	min-width: 							100%;
	max-height: 						170px;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	overflow-y: 						auto;
	position: 							absolute;
	top: 								100%;
	left: 								0;
	opacity: 							0;
	visibility: 						hidden;
	transition: 						all 0.3s ease-in-out;
	z-index: 							999;
}

/* width */
.informationCompliance .inputSections .commodityContainer .commodityList::-webkit-scrollbar {
	width: 								3px;
	height: 							3px;
}
  
 /* Track */
.informationCompliance .inputSections .commodityContainer .commodityList::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
 }
  
/* Handle */
.informationCompliance .inputSections .commodityContainer .commodityList::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.informationCompliance .inputSections .commodityContainer .commodityList.active { 
	opacity: 							1;
	visibility: 						visible;
}

.informationCompliance .inputSections .commodityContainer .commodityList .commodityName { 
	font-size: 							14px;
	background-color: 					var(--grey);
	padding: 							5px 15px;
}

.darkMode .informationCompliance .inputSections .commodityContainer .commodityList .commodityName { 
	background-color: 					#131E29;
}

.informationCompliance .inputSections .commodityContainer .commodityList .optionCommodity { 
	display: 							flex;
	align-items: 						center;
	padding: 							5px 20px;
	transition: 						all 0.3s ease-in-out;
}

.darkMode .informationCompliance .inputSections .commodityContainer .commodityList .optionCommodity { 
	background: 						#27343F;
}

.informationCompliance .inputSections .commodityContainer .commodityList .optionCommodity:hover { 
	background-color: 					var(--grey);
}

.darkMode .informationCompliance .inputSections .commodityContainer .commodityList .optionCommodity:hover { 
	background-color: 					#131E29;
}

.informationCompliance .inputSections .commodityContainer .commodityList .optionCommodity label { 
	flex: 								1 0 0;
	font-size: 							12px;
	cursor: 							pointer;
}

.informationCompliance .inputSections .commodityContainer .commodityList .optionCommodity.disabled,
.informationCompliance .inputSections .commodityContainer .commodityList .optionCommodity.disabled > * { 
	opacity: 							0.5;
	cursor: 							not-allowed;
}

.informationCompliance .inputSections .inputContainer.otherOptions{
	flex: 								1 0 0;
	max-width: 							0;
	opacity: 							0;
	visibility: 						hidden;
	overflow: 							hidden;
	transition: 						all 0.3s ease-in-out;
}

.informationCompliance .inputSections .inputContainer.otherOptions.show{
	max-width: 							calc(100% / 3 - (10px* 2 / 3));
	opacity: 							1;
	visibility: 						visible;
}

.informationCompliance .inputSections .inputContainer.otherOptions .label{
	text-wrap: 							nowrap;
}

@media(max-width: 991px){
	/* INFORMATION COMPLIANCE */
	.informationCompliance .inputSections{
		margin-bottom: 						30px;
		column-gap: 						0;
	}

	.informationCompliance .inputSections .titleAndActions{
		flex-flow: 							row wrap;
		column-gap: 						30px;
	}

	.informationCompliance .inputSections .titleAndActions .title{
		padding: 							10px 20px;
	}

	.informationCompliance .inputSections .titleAndActions .comment{
		margin: 							0 15px 0 0;
	}

	.informationCompliance .inputSections .titleAndActions .commentContainer{
		width: 								95%;
		position: 							absolute;
		top: 								100%;
		left: 								0;
		margin: 							0 auto;
		padding: 							35px 25px;
	}
	
	.informationCompliance .inputSections .titleAndActions .commentContainer > img{
		width: 								20px;
		height: 							20px;
	}
	
	.informationCompliance .inputSections .titleAndActions .commentContainer > h5{
		margin-bottom: 						10px;
	}
	
	.informationCompliance .inputSections .titleAndActions .commentContainer > textarea{
		width: 								100%;
		padding: 							10px;
		margin-bottom: 						10px;
	}
	
	.informationCompliance .inputSections .titleAndActions .commentContainer > .actions{
		justify-content: 					center;
	}
	
	.informationCompliance .inputSections .inputContainer{
		max-width: 							100%;
		flex: 								0 0 100%;
		margin-bottom: 						20px;
	}
}