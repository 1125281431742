/* PRODUCTS AND SERVICES */
.productsAndServices .tableProductsAndServices{
	margin-bottom: 						30px;
}

.productsAndServices .titleHeader{
    font-weight: 						700;
    padding: 							18px 30px;
    background: 						#FFFFFF;
    box-shadow: 						0px 4px 30px 0px rgba(35, 107, 216, 0.08);
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
}

.darkMode .productsAndServices .titleHeader{
    background: 						#181E24;
}

.productsAndServices .titleHeader .productsActions {
	display: 							flex;
	gap: 								5px;
}

.productsAndServices .titleHeader .btnBgBlueTextGradient{
	padding: 							10px 30px;
}

.productsAndServices .titleHeader .btnBgBlueTextGradient::before{
	content: 							"";
	width: 								15px;
	height: 							15px;
	background: 						url("../../images/add.svg") center center no-repeat;
	background-size: 					15px 15px;
	margin-right: 						5px;
}

.productsAndServices .titleHeader .downloadButton::before{
	mask: 								url("../../images/download.svg") center center no-repeat;
	background: 						var(--gradient);
}

.productsAndServices .titleHeader .uploadButton::before{
	mask: 								url("../../images/download.svg") center center no-repeat;
	transform: 							rotate(180deg);
	background: 						var(--gradient);
}

.productsAndServices .tableProductsAndServices thead{
	background-color:					rgba(4, 26, 57, 0.03);
	box-shadow: 						none;
}

.productsAndServices .tableProductsAndServices thead span{
	cursor: 							pointer;
}

.productsAndServices .tableProductsAndServices thead .sortIcon{
	margin-left: 						5px;
}

.productsAndServices .tableProductsAndServices tbody td .moreDots{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	right: 								10px;
	margin: 							auto 0;
	cursor: 							pointer;
}

.darkMode .productsAndServices .tableProductsAndServices tbody td .moreDots{
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.productsAndServices .tableProductsAndServices tbody td .menuOptions{
	width: 								max-content;
	padding: 							10px 0;
	position: 							absolute;
	top: 								160%;
	right: 								-15px;
	background: 						#FFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	z-index: 							999;
	visibility: 						hidden;
	opacity: 							0;
	transition: 						all 0.3s ease-in-out;
}

.productsAndServices .tableProductsAndServices tbody td .menuOptions::after{
	content: 							"";
	width: 								0;
	height: 							0;
	position: 							absolute;
	bottom: 							100%;
	left: 								0;
	right: 								0;
	margin: 							0 auto;
	border-left: 						15px solid transparent;
	border-right: 						15px solid transparent;
	border-bottom: 						15px solid #FFFFFF;
}


.productsAndServices .tableProductsAndServices tbody td .menuOptions.show{
	visibility: 						visible;
	opacity: 							1;
}

.productsAndServices .tableProductsAndServices tbody td .menuOptions > span{
	text-decoration: 					none;
	color: 								#000000;
	font-size: 							12px;
	padding: 							5px 20px;
	opacity: 							1;
	transition: 						all 0.3s ease-in-out;
}

.productsAndServices .tableProductsAndServices tbody td .menuOptions span:first-of-type:hover{
	color: 								#FFFFFF;
	background-color: 					var(--darkBlue);
	cursor: 							pointer;
}

.productsAndServices .tableProductsAndServices tbody td .menuOptions span:last-of-type:hover{
	color: 								#FFFFFF;
	background-color: 					var(--red);
	cursor: 							pointer;
}

.productAndServicesModal .modal-content{
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.productAndServicesModal .modal-body{
	padding: 							90px 85px 80px;
}

.productAndServicesModal .modal-body .closeModal{
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								30px;
	right: 								30px;
	cursor: 							pointer;
}

.productAndServicesModal .modal-body .subTitle{
	font-size: 							14px;
	font-weight: 						300;
}

.productAndServicesModal .modal-body .inputsSection{
	display: 							flex;
	flex-flow: 							row wrap;
	gap: 								10px;
	margin: 							30px 0;
}

.productAndServicesModal .modal-body .inputsSection .inputContainer{
	flex: 								0 0 calc(100% / 2 - (10px * 1 / 2));
}

.productAndServicesModal .modal-body .inputsSection .inputContainer.service{
	flex: 								0 0 100%;
}

.productAndServicesModal .modal-body .inputsSection .commodity-container{
	max-width: 						calc(100% / 2 - (10px * 1 / 2));
}

/* .productAndServicesModal .modal-body .inputsSection .inputContainer:first-of-type, */
.productAndServicesModal .modal-body .inputsSection .inputContainer:last-of-type{
	flex: 								0 0 100%;
}

.productAndServicesModal .modal-body .actions{
	display: 							flex;
	justify-content: 					flex-end;
	gap: 								10px;
}

.productAndServicesModal .modal-body .fileContainer{
	display: 							flex;
	gap: 								10px;
	align-items: 						center;
	position: 							relative;
	padding-right: 						20px;
}

.productAndServicesModal .modal-body .fileContainer .downloadFile{
	padding: 							10px 20px;
	font-size: 							12px;
}

.productAndServicesModal .modal-body .fileContainer .fileName{
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
}

.productAndServicesModal .modal-body .fileContainer .close{
	width: 								15px;
	height: 							15px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
	right: 								0;
	cursor: 							pointer;
}

@media (max-width: 992px){
	.productsAndServices .tableContainer{
		display: 							none;
	}

	.productsAndServices .productsAndServicesInfo {
		width: 								100%;
		padding: 							25px 20px;
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
		margin-bottom: 						25px;
		display: 							grid;
		row-gap: 							10px;
		grid-template-areas: 
			"A B"
			"C D";
	}
	
	.productsAndServices .productsAndServicesInfo span{
		font-weight: 						700;
		font-size: 							10px;
		opacity: 							0.4;
	}

	.productsAndServices .productsAndServicesInfo span:nth-of-type(1){
		grid-area: 							A;
		opacity: 							1;
		font-size: 							12px;
	}

	.productsAndServices .productsAndServicesInfo span:nth-of-type(2){
		grid-area: 							A;
		margin-top: 						15px;
	}

	.productsAndServices .productsAndServicesInfo span:nth-of-type(3){
		grid-area: 							C;
		opacity: 							1;
		font-size: 							12px;
	}

	.productsAndServices .productsAndServicesInfo span:nth-of-type(4){
		grid-area: 							C;
		margin-top: 						15px;
	}

	.productsAndServices .productsAndServicesInfo span:nth-of-type(5){
		grid-area: 							B;
		opacity: 							1;
		font-size: 							12px;
	}

	.productsAndServices .productsAndServicesInfo span:nth-of-type(6){
		grid-area: 							B;
		margin-top: 						15px;
	}

	.productAndServicesModal .modal-content{
		border-radius: 						35px;
	}
	
	.productAndServicesModal .modal-body{
		padding: 							55px 35px;
	}
	
	.productAndServicesModal .modal-body .closeModal{
		top: 								20px;
		right: 								20px;
	}

	.productAndServicesModal .modal-body .actions{
		justify-content: 					center;
	}
}