/* LANDING */
nav{
	position: 							relative;
	padding: 							40px 0;
	z-index: 							999;
}

nav > .container{
	display: 							flex;
	align-items: 						center;
}

nav .logo{
	height: 							45px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-right: 						auto;
}

nav .anchorSection{
	text-decoration: 					none;
	color: 								#FFFFFF;
	font-size: 							14px;
	font-weight: 						700;
	margin-right: 						20px;
}

/* VIDEO LOOP */
.videoLoop{
	position: 							relative;
	margin-top: 						-125px;
	width: 								100%;
	height: 							100vh;
	overflow: 							hidden;
}

.videoLoop::after{
	content: 							"";
	width: 								100%;
	height: 							100%;
	position: 							absolute;
	top: 								0;
	left: 								0;
	background: 						linear-gradient(180deg, transparent 0%, #000000 100%);
}

.videoLoop > video{
	width: 								100vw;
	height: 							56.25vw;
	min-width: 							177.77vh;
	min-height: 						100vh;
	position: 							absolute;
	top: 								50%;
	left: 								50%;
	transform: 							translate(-50%, -50%);
}

.videoLoop > .scrollDown{
	width: 								25px;
	height: 							25px;
	position: 							absolute;
	left: 								0;
	right: 								0;
	bottom: 							30px;
	margin: 							0 auto;
	z-index: 							999;
	cursor: 							pointer;
}

.videoLoop > .scrollDown > img{
	width: 								100%;
	height: 							100%;
	object-fit: 						contain;
	object-position: 					center center;
}

/* SELECTION */
.selection{
	padding:  							190px 0 180px;
}

.selection .slickDescription .slick-dots{
	text-align: 						start;
	bottom: 							-50px;
}

.selection .slickDescription .slick-dots li button{
	position: 							relative;
	height: 							auto;
	font-size: 							16px;
	font-weight: 						700;
	color: 								rgba(0, 0, 0, 0.25);
	line-height: 						normal;
	padding:  							0 0 10px 0;
}

.selection .slickDescription .slick-dots li button::before{
	content: 							"";
	width: 								100%;
	height: 							2px;
	position: 							absolute;
	top: 								unset;
	bottom: 							0;
}

.selection .slickDescription .slick-dots li.slick-active button::before{
	background: 						var(--gradient) center center no-repeat;
	background-size: 					100% 2px;
}

.selection .slickDescription .slick-dots li.slick-active button{
	font-weight: 						900;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
	transition: 						all 0.3s ease-in-out;
}

.selection .slickDescription .slick-prev{
	display: 							none !important;
}

.selection .slickDescription .slick-next{
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size: 					20px;
	right: 								-30px;
}

.selection .slickDescription .slick-next::before{
	content: 							"";
}

/* APPLY */
.apply{
	margin-bottom: 						100px;
}

.apply .description{
	font-size: 							14px;
	margin-bottom: 						60px;
}

.apply .tabPane{
	display: 							flex;
	flex-flow: 							row nowrap;
}

.apply .thankTitle{
	text-align: 						center;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
	margin: 							300px 0 300px;
}

.apply .tabPane .nav-tabs{
	flex: 								0 0 30%;
	display: 							flex;
	flex-flow: 							column nowrap;
	gap: 								15px;
	border: 							unset;
}

.apply .tabPane .nav-tabs .nav-item{
	padding-right: 						55px;
}

.apply .tabPane .nav-tabs .nav-link{
	position: 							relative;
	width: 								100%;
	padding: 							10px 60px 10px 40px;
	border:  							unset;
	border-radius: 						unset;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

.apply .tabPane .nav-tabs .nav-link::after{
	content: 							"";
	display: 							block;
	width: 								25px;
	height: 							25px;
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size: 					10px;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	right: 								10px;
	margin: 							auto 0;
}

.apply .tabPane .nav-tabs .nav-link.active{
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
}

.apply .tab-content{
	flex:  								0 0 60%;
}

.apply .tab-content .tab-pane > .title{
	font-weight: 						700;
	margin-bottom: 						5px;
}

.apply .tab-content .tab-pane > .description{
	font-size: 							14px;
	margin-bottom: 						35px;
}

.apply .tab-content .tab-pane form{
	display: 							flex;
	flex-flow: 							row wrap;
	column-gap: 						20px;
	row-gap: 							15px;
}

.apply .tab-content .tab-pane form .inputContainer{
	flex: 								0 0 calc(100% / 2 - (20px * 1 / 2));
	display: 							flex;
	flex-flow: 							column nowrap;
	justify-content: 					flex-end;
	margin-bottom: 						0;
}

.apply .tab-content .tab-pane form .inputContainer.radioPerson{
	display: 							flex;
	flex-flow: 							row wrap;
	justify-content: 					flex-start;
}

.apply .tab-content .tab-pane form .inputContainer.radioPerson > label{
	font-size: 							14px;
	cursor: 							pointer;
}

.apply .tab-content .tab-pane form .inputContainer.radioPerson > label:first-of-type{
	margin-right: 						30px;
}

.apply .tab-content .tab-pane form .btnBgBlueTextGradient{
	margin: 							15px 0 0 auto;
}

.apply .tab-content .tab-pane .industryContainer{
	display: 							flex;
	flex-flow: 							row wrap;
	column-gap: 						20px;
	row-gap: 							15px;
}

.apply .tab-content .tab-pane .industryContainer .inputContainer{
	flex: 								0 0 100%;
	display: 							flex;
	flex-flow: 							column nowrap;
	justify-content: 					flex-end;
	margin-bottom: 						0;
}

.apply .tab-content .tab-pane .industryContainer .commodityContainer .buttonCommodities {
	position: 							relative;
	width:                              100%;
	height:                             39px;
	background-color: 					#FFFFFF;
	padding: 							10px 30px 10px 10px;
	font-size: 							12px;
	font-weight: 						300;
	border-bottom: 						1px solid transparent;
	cursor: 							pointer;
	z-index: 							1;
	transition: 						all 0.3s ease-in-out;
    text-wrap:                          nowrap;
    text-overflow:                      ellipsis;
    overflow:                           hidden;
}

.apply .tab-content .tab-pane .industryContainer .commodityContainer .buttonCommodities::after {
	content: 							"";
	width: 								15px;
	height: 							15px;
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size: 					15px 15px;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	right: 								10px;
	margin: 							auto 0;
	transform: 							rotate(90deg);
	pointer-events: 					none;
	transition: 						all 0.3s ease-in-out;
}

.apply .tab-content .tab-pane .industryContainer .commodityContainer .buttonCommodities.default {
	color: 								rgba(0, 0, 0, 0.5);
}

.apply .tab-content .tab-pane .industryContainer .commodityContainer .buttonCommodities:hover,
.apply .tab-content .tab-pane .industryContainer .commodityContainer .buttonCommodities.active {
	color: 								#000000;
	border-bottom: 						1px solid #009AFF;
}

.apply .tab-content .tab-pane .industryContainer .commodityContainer .buttonCommodities.active::after { 
	transform: 							rotate(270deg);
}

.apply .tab-content .tab-pane .industryContainer .commodityContainer .commodityList { 
	min-width: 							100%;
	max-height: 						170px;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	overflow-y: 						auto;
	position: 							absolute;
	top: 								100%;
	left: 								0;
	opacity: 							0;
	visibility: 						hidden;
	transition: 						all 0.3s ease-in-out;
	z-index: 							999;
}

/* width */
.apply .tab-content .tab-pane .industryContainer .commodityContainer .commodityList::-webkit-scrollbar {
	width: 								3px;
	height: 							3px;
}
  
 /* Track */
.apply .tab-content .tab-pane .industryContainer .commodityContainer .commodityList::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
 }
  
/* Handle */
.apply .tab-content .tab-pane .industryContainer .commodityContainer .commodityList::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.apply .tab-content .tab-pane .industryContainer .commodityContainer .commodityList.active { 
	opacity: 							1;
	visibility: 						visible;
}

.apply .tab-content .tab-pane .industryContainer .commodityContainer .commodityList .commodityName { 
	font-size: 							14px;
	background-color: 					var(--grey);
	padding: 							5px 15px;
}

.apply .tab-content .tab-pane .industryContainer .commodityContainer .commodityList .optionCommodity { 
	display: 							flex;
	align-items: 						center;
	padding: 							5px 20px;
	transition: 						all 0.3s ease-in-out;
}

.apply .tab-content .tab-pane .industryContainer .commodityContainer .commodityList .optionCommodity:hover { 
	background-color: 					var(--grey);
}

.apply .tab-content .tab-pane .industryContainer .commodityContainer .commodityList .optionCommodity label { 
	flex: 								1 0 0;
	font-size: 							12px;
	cursor: 							pointer;
}

.apply .tab-content .tab-pane .industryContainer .commodityContainer .commodityList .optionCommodity.disabled,
.apply .tab-content .tab-pane .industryContainer .commodityContainer .commodityList .optionCommodity.disabled > * { 
	opacity: 							0.5;
	cursor: 							not-allowed;
}

.apply .tab-content .tab-pane .industryContainer .inputContainer.radioRepse{
	flex: 								0 0 calc(100% / 3 - (20px * 2 / 3));
	flex-flow: 							row wrap;
	align-items: 						center;
	justify-content: 					start;
}

.apply .tab-content .tab-pane .industryContainer .inputContainer.radioRepse > label{
	font-size: 							14px;
	cursor: 							pointer;
}

.apply .tab-content .tab-pane .industryContainer .inputContainer.radioRepse > label:first-of-type{
	margin-right: 						30px;
}

.apply .tab-content .tab-pane .industryContainer .inputContainer.otherOptions{
	flex: 								1 0 100%;
	max-height: 						0;
	opacity: 							0;
	visibility: 						hidden;
	overflow: 							hidden;
	transition: 						all 0.3s ease-in-out;
}

.apply .tab-content .tab-pane .industryContainer .inputContainer.otherOptions.show{
	max-height: 						100px;
	opacity: 							1;
	visibility: 						visible;
}

.apply .tab-content .tab-pane .industryContainer .inputContainer.textRepse{
	flex: 								1 0 0;
	max-width: 							0px;
	opacity: 							0;
	overflow: 							hidden;
	visibility: 						hidden;
	transition: 						all 0.3s ease-in-out;
}

.apply .tab-content .tab-pane .industryContainer .inputContainer.textRepse .label{
	text-wrap: 							nowrap;
}

.apply .tab-content .tab-pane .industryContainer .inputContainer.textRepse.show{
	max-width: 							500px;
	opacity: 							1;
	visibility: 						visible;
}

.apply .tab-content .tab-pane .industryContainer .terms{
	flex: 								0 0 60%;
	font-size: 							12px;
	font-weight: 						300;
	margin-top: 						30px;
}

.apply .tab-content .tab-pane .industryContainer .terms > a{
	display: 							inline;
}

.apply .tab-content .tab-pane .industryContainer .btnBgBlueTextGradient{
	flex: 								1 0 0;
	padding: 							10px 30px;
	margin: 							30px 0 0 40px;
}

/* FOOTER */
.footer{
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
	background-color: 					#000000;
	padding: 							15px 125px;
}

.footer > *{
	font-size: 							14px;
	color: 								#FFFFFF;
	text-decoration: 					none;
}

@media (max-width: 991px){
	/* LANDING */
	nav{
		padding: 							25px 0;
	}

	nav > .container{
		justify-content: 					space-between;
	}

	nav .logo{
		height: 							25px;
		margin: 							0;
	}

	nav .anchorSection{
		display: 							none;
	}

	/* VIDEO LOOP */
	.videoLoop{
		position: 							relative;
		margin-top: 						-125px;
		width: 								100%;
		height: 							40vh;
		overflow: 							hidden;
	}

	.videoLoop::after{
		content: 							"";
		width: 								100%;
		height: 							100%;
		position: 							absolute;
		top: 								0;
		left: 								0;
		background: 						linear-gradient(180deg, transparent 0%, #000000 100%);
	}

	.videoLoop > video{
		width: 								auto;
		height: 							100%;
		min-width: 							auto;
		min-height: 						100%;
		position: 							absolute;
		top: 								50%;
		left: 								50%;
		transform: 							translate(-50%, -50%);
	}

	/* SELECTION */
	.selection{
		padding:  							100px 0 150px;
	}

	.selection .slickDescription .slick-dots{
		text-align: 						center;
		bottom: 							-40px;
	}

	.selection .title{
		font-size: 							24px;
		text-align: 						center;
		margin-bottom: 						20px;
	}

	.selection .description{
		font-size: 							14px;
	}

	.selection .slickDescription .slick-prev{
		display: 							block !important;
		background: 						url("../../images/arrowForward.svg") center center no-repeat;
		background-size: 					20px;
		left: 								-30px;
		transform: 							rotate(180deg);
		top: 								0;
		bottom: 							0;
		margin: 							auto 0;
	}
	
	.selection .slickDescription .slick-prev::before{
		content: 							"";
	}

	.selection .slickDescription .slick-dots li button{
		font-size: 							12px;
	}

	/* APPLY */
	.apply{
		margin-bottom: 						50px;
	}

	.apply .titleApply{
		font-size: 							24px;
		margin-bottom: 						15px;
		text-align: 						center;
	}

	.apply .description{
		font-size: 							14px;
		margin-bottom: 						20px;
	}

	.apply .tabPane{
		flex-flow: 							column nowrap;
	}

	.apply .tabPane .nav-tabs{
		flex: 								1 0 0;
		flex-flow: 							row nowrap;
		gap: 								5px;
		margin-bottom: 						20px;
	}

	.apply .tabPane .nav-tabs .nav-item{
		flex: 								0 0 calc(100% / 2 - (5px * 1 / 2));
		padding-right: 						0;
	}

	.apply .tabPane .nav-tabs .nav-link{
		font-size: 							12px;
		padding: 							10px 15px;
	}

	.apply .tabPane .nav-tabs .nav-link::after{
		content: 							unset;
	}

	.apply .tab-content{
		flex:  								1 0 0;
	}

	.apply .tab-content .tab-pane > .title{
		font-size: 							18px;
		text-align: 						center;
	}

	.apply .tab-content .tab-pane > .description{
		margin-bottom: 						15px;
	}

	.apply .tab-content .tab-pane form .inputContainer{
		flex: 								0 0 100%;
	}

	.apply .tab-content .tab-pane form .btnBgBlueTextGradient{
		margin: 							0 0 0 auto;
	}

	.apply .tab-content .tab-pane .industryContainer{
		display: 							flex;
		flex-flow: 							row wrap;
		column-gap: 						20px;
		row-gap: 							15px;
	}

	.apply .tab-content .tab-pane .industryContainer .inputContainer.radioRepse{
		flex: 								0 0 100%;
	}


	.apply .tab-content .tab-pane .industryContainer .terms{
		flex: 								0 0 100%;
		margin-top: 						15px;
	}

	.apply .tab-content .tab-pane .industryContainer .btnBgBlueTextGradient{
		flex: 								unset;
		padding: 							10px 30px;
		margin: 							20px auto 0;
	}

	/* FOOTER */
	.footer{
		display: 							flex;
		flex-flow: 							row nowrap;
		align-items: 						center;
		justify-content: 					space-between;
		background-color: 					#000000;
		padding: 							15px 30px;
	}

	.footer > *{
		font-size: 							12px;
		color: 								#FFFFFF;
	}
}