/* CONTRACTS LIST */
.contracts .filters{
	display: 							flex;
    align-items:                        center;
	flex-flow: 							row wrap;
	gap: 								10px;
	margin-bottom: 						10px;
}

.contracts .filters .contractsFilter{
	display: 							flex;
	align-items: 						center;
	padding: 							10px 20px;
	background-color: 					#FFFFFF;
	position: 							relative;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.contracts.darkMode .filters .contractsFilter{
	background-color: 					#131E29;
}

.contracts .filters .contractsFilter.show{
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.contracts .filters .contractsFilter.active{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.contracts .filters .contractsFilter::after{
	content: 							"";
	width: 								20px;
	height: 							20px;
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size: 					15px;
	transform: 							rotate(90deg);
	margin-left: 						20px;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.contracts .filters .contractsFilter.show::after{
	transform: 							rotate(270deg);
}

.contracts .filters .contractsFilter > img{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-right: 						5px;
	pointer-events: 					none;
}

.contracts .filters .contractsFilter > span{
	font-size: 							12px;
	pointer-events: 					none;
}

.contracts .filters .contractsFilter .searchContainer{
	width: 								280px;
	background-color: 					#FFFFFF;
	padding: 							15px;
	position: 							absolute;
	top: 								105%;
	left: 								0;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
	cursor: 							default;
}

.contracts .filters .contractsFilter .searchContainer::after{
	right: 								35px;
}

.contracts .filters .contractsFilter .searchContainer > input{
	background-color: var(--grey);
}

.contracts .filters .contractsFilter .contractsFilterOptions{
	width: 								max-content;
	position: 							absolute;
	top: 								105%;
	left: 								0;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	padding: 							20px;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
	cursor: 							default;
}

.contracts.darkMode .filters .contractsFilter .contractsFilterOptions{
	background: 						#131E29;
}

.contracts .filters .contractsFilter .contractsFilterOptions .optionsScroll{
	max-height: 						300px;
	overflow-y: 						auto;
}

/* width */
.contracts .filters .contractsFilter .contractsFilterOptions .optionsScroll::-webkit-scrollbar {
	width: 								3px;
}
  
 /* Track */
.contracts .filters .contractsFilter .contractsFilterOptions .optionsScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
 }
  
/* Handle */
.contracts .filters .contractsFilter .contractsFilterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.contracts .filters .contractsFilter .contractsFilterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						white;
}

.contracts .filters .contractsFilter .contractsFilterOptions.show, .contracts .filters .contractsFilter .searchContainer.show{
	visibility: 						visible;
	opacity: 							1;
	-webkit-text-fill-color: 			#000000;
}

.contracts .filters .contractsFilter .contractsFilterOptions .title{
	font-size: 							14px;
	font-weight: 						700;
	margin-bottom: 						5px;
}
.contracts.darkMode .filters .contractsFilter .contractsFilterOptions .title,
.contracts.darkMode .filters .contractsFilter .contractsFilterOptions .option{
	-webkit-text-fill-color: 			white;
}

.contracts .filters .contractsFilter .contractsFilterOptions .option{
	display: 							flex;
	align-items: 						center;
	padding: 							0 10px;
}

.contracts .filters .contractsFilter .contractsFilterOptions.location{
	width: 								280px;
	top: 								290%;
}

.contracts .filters .contractsFilter .contractsFilterOptions .option > input{
	margin-right: 						0;
	cursor: 							pointer;
}

.contracts.darkMode .filters .contractsFilter .contractsFilterOptions .option > input{
	background-color: 					white;
}

.contracts.darkMode .filters .contractsFilter .contractsFilterOptions .option > input:checked{
	background-color: 					var(--green);
}

.contracts .filters .contractsFilter .contractsFilterOptions .option > label{
	flex:  								1 0 0;
	font-size: 							12px;
	opacity: 							0.8;
	cursor: 							pointer;
	padding: 							10px 0 10px 10px;
}

.contracts .filters .contractsFilter .contractsFilterOptions .optionScroll{
	max-height: 						220px;
	overflow-y: 						auto;
}

.contracts .filters .contractsFilter .contractsFilterOptions .optionScroll::-webkit-scrollbar {
	width: 								3px;
}

.contracts .filters .contractsFilter .contractsFilterOptions .optionScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
}
 
.contracts .filters .contractsFilter .contractsFilterOptions .optionScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.contracts .filters .contractsFilter .contractsFilterOptions .actions{
	display: 							flex;
	gap: 								10px;
	justify-content: 					center;
	margin-top: 						15px;
}

.contracts .filters .contractsFilter .contractsFilterOptions .actions > button {
	border: 							unset;
	background-color: 					transparent;
	padding: 							5px 15px;
	font-size: 							12px;
}

.contracts.darkMode .filters .contractsFilter .contractsFilterOptions .actions > button:first-of-type {
	-webkit-text-fill-color: 			white;
}

.contracts .filters .contractsFilter .contractsFilterOptions .actions > button:last-of-type {
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.contracts .tableContracts {
    margin-bottom:                      30px;
}

.contracts .tableContracts td:first-of-type .infoContainer span{
	opacity: 							1;
	cursor:								pointer;
}

.contracts .tableContracts td:first-of-type .infoContainer span:hover{
	background: var(--gradient) text;
    -webkit-text-fill-color: transparent;
}

.contracts .tableContracts td:last-of-type .infoContainer{
    justify-content:                    space-between;
}

.contracts .tableContracts td .infoContainer .reviewed{
    width:                              20px;
    height:                             20px;
    object-fit:                         contain;
    object-position:                    center center;
    cursor:                             pointer;
}

.darkMode .contracts .tableContracts td .infoContainer .reviewed{
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.contracts .addAndPagination{
	display: 							flex;
	align-items: 						center;
	justify-content: 					flex-end;
	margin-bottom: 						10px;
}

.contracts .addAndPagination .pagination{
	display: 							flex;
	align-items: 						center;
	gap: 								5px;
}

.contracts .addAndPagination .number{
	border: 							unset;
	font-size: 							12px;
	padding: 							2px 7.5px;
	opacity: 							0.5;
}

.contracts .addAndPagination .number.active{
	opacity: 							1;
	font-weight: 						900;
	background:  						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.contracts .addAndPagination .nextPage{
	width: 								13px;
	height: 							13px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
}

.contracts .tableCount{
	width: 								fit-content;
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
	margin: 							0 0 55px auto;
}

@media (max-width: 991px) {
	.contracts .filters{
		display: 							flex;
		gap: 								10px;
		overflow-x: 						auto;
		flex-wrap: 							nowrap;
	}

	/* width */
	.contracts .filters::-webkit-scrollbar {
		display: 							none;
	}
	
	.contracts .filters .contractsFilter{
		padding: 							10px;
		position: 							static;
	}

	.contracts .filters .contractsFilter > span{
		font-size: 							10px;
	}

	.contracts .filters .contractsFilter .searchContainer{
		width: 								90vw;
		height: 							fit-content;
		padding: 							15px;
		position: 							fixed;
		top: 								unset;
		right: 								0;
		bottom: 							30px;
		margin: 							0 auto;
	}

	.contracts .filters .contractsFilter .contractsFilterOptions{
		width: 								90vw;
		height: 							max-content;
		position: 							fixed;
		top: 								0;
		left: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto;
		border-radius: 						35px;
		padding: 							50px 35px;

	}
	.contracts .filters .contractsFilter .contractsFilterOptions::after{
		content: 							"";
		width: 								25px;
		height: 							25px;
		background: 						url("../../images/close.svg") center center no-repeat;
		background-size: 					25px;
		position: 							absolute;
		top: 								25px;
		right: 								25px;
	}

	.darkMode .contracts .filters .contractsFilter .contractsFilterOptions::after{
		mask: 								url("../../images/close.svg") center center no-repeat;
		mask-size: 							25px;
		background: 						white;
	}

	.contracts .filters .contractsFilter .contractsFilterOptions .imgMenu{
		width: 								20px;
		height: 							20px;
		object-fit: 						contain;
		object-position: 					center center;
		margin-right: 						10px;
		display: 							inline-block;
	}

	.contracts .filters .contractsFilter .contractsFilterOptions .title{
		display: 							inline-block;
		font-size: 							16px;
		margin-bottom: 						30px;
	}

	.contracts .filters .contractsFilter .contractsFilterOptions .option{
		padding: 							0;
	}

	.contracts .filters .contractsFilter .contractsFilterOptions.location{
		width: 								90vw;
		top: 								0;
	}

	.contracts .filters .contractsFilter .contractsFilterOptions .option > input{
		order: 								2;
		margin-right: 						10px;
	}

	.contracts .filters .contractsFilter .contractsFilterOptions .option > label{
		order: 								1;
		padding:  							10px 10px 10px 0;
	}

	.contracts .filters .contractsFilter .contractsFilterOptions .actions{
		gap: 								0;
		justify-content: 					space-between;
		margin-top: 						50px;
	}

	.contracts .filters .contractsFilter .contractsFilterOptions .actions > button{
		font-size: 							14px;
	}

    .contracts .tableContracts {
		display:                            none;
	}

	.contracts .contractList{
		display: 							flex;
		flex-flow: 							column nowrap;
		margin: 							25px 0;
	}

	.contracts .contractList .contractRow{
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
		padding: 							15px 23px 25px;
	}

	.contracts .contractList .contractRow:last-of-type{
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.contracts .contractList .contractRow .contractName{
        display:                            flex;
        align-items:                        center;
        justify-content: space-between;
		font-weight: 						500;
		margin-bottom: 						20px;
	}

	.contracts .contractList .contractRow .contractInformation{
		width: 								100%;
		display:  							grid;
		align-items: 						flex-end;
		justify-content: 					space-between;
		grid-template-columns: 				repeat(2, fit-content);
		grid-template-rows: 				repeat(8, max-content);
		column-gap: 						30px;
		row-gap: 							0;
	}

	.contracts .contractList .contractRow .contractInformation .contractValue{
		font-size: 							12px;
	}

	.contracts .contractList .contractRow .contractInformation .contractTitle{
		font-size: 							10px;
		font-weight: 						700;
		opacity: 							0.4;
	}

	.contracts .contractList .contractRow .contractInformation > span:nth-of-type(1){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					1;
		grid-row-end: 						2;
	}

	.contracts .contractList .contractRow .contractInformation > span:nth-of-type(2){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					2;
		grid-row-end: 						3;
		margin-bottom:						10px;
	}

	.contracts .contractList .contractRow .contractInformation > span:nth-of-type(3){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					1;
		grid-row-end: 						2;
	}

	.contracts .contractList .contractRow .contractInformation > span:nth-of-type(4){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					2;
		grid-row-end: 						3;
		margin-bottom: 						10px;
	}

	.contracts .contractList .contractRow .contractInformation > span:nth-of-type(5){
		display:							flex;
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					3;
		grid-row-end: 						4;
	}

	.contracts .contractList .contractRow .contractInformation > span:nth-of-type(6){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					4;
		grid-row-end: 						5;
		margin-bottom:						10px;
	}

	.contracts .contractList .contractRow .contractInformation > span:nth-of-type(7){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					3;
		grid-row-end: 						4;
	}

	.contracts .contractList .contractRow .contractInformation > span:nth-of-type(8){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					4;
		grid-row-end: 						5;
		margin-bottom:						10px;
	}

    .contracts .contractList .contractRow .contractInformation > span:nth-of-type(9){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					5;
		grid-row-end: 						6;
	}

	.contracts .contractList .contractRow .contractInformation > span:nth-of-type(10){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					7;
		grid-row-end: 						8;
	}
	
	.contracts .addAndPagination{
		padding: 							0 20px;
	}

	.contracts .tableCount{
		font-size: 							10px;
		margin: 							0 25px 25px auto;
	}
}