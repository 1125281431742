.staffList .addDocumentHeader .rightOptions {
    display:                            flex;
    justify-content:                    flex-end;
    gap:                                25px;
}

.staffList .addDocumentHeader .rightOptions .filterActiveStaff {
    display:                            flex;
    align-items:                        center;
}

.supplierCheckText{
    font-size:                          12px;
}

.statusSwitch {
	width: 								100px;
	padding: 							5px;
	border-radius: 						25px;
	overflow: 							hidden;
	background-color: 					rgba(4, 26, 57, 0.3);
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.darkMode .statusSwitch {
	background-color: 					#181E24;
}

.statusSwitch:hover {
	background-color: 					rgba(4, 26, 57, 0.3);
}

.statusSwitch .statusBall {
	position: 							relative;
	width: 								25px;
	height: 							25px;
	border-radius: 						100px;
	background-color: 					#FFFFFF;
	transition: 						all 0.3s ease-in-out;
}

.statusSwitch .statusBall::before {
	content: 							"Active";
	height: 							fit-content;
	font-weight: 						700;
	color: 								#FFFFFF;
	font-size: 							12px;
	position: 							absolute;
	right: 								150%;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
}

.statusSwitch .statusBall::after {
	content: 							"Active";
	height: 							fit-content;
	font-weight: 						700;
	color: 								#FFFFFF;
	font-size: 							12px;
	position: 							absolute;
	left: 								130%;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
}

.statusSwitch.active {
	background-color: 					var(--green);
}

.statusSwitch.active:hover {
	background-color: 					var(--darkGreen);
}

.statusSwitch.active .statusBall {
	transform: 							translateX(65px);
}