/* SUBMISSION */
.submission .titleDocuments{
	position: 							relative;
	font-weight: 						700;
	padding: 							18px 30px;
	background: 						#FFFFFF;
	box-shadow: 						0px 4px 30px 0px rgba(35, 107, 216, 0.08);
	transition: 						background 0.3s ease-in-out;
}

.darkMode .submission .titleDocuments{
	background: 						#131E29;
}

.submission .tableSubmission{
	margin-bottom: 						60px;
}

.submission .tableSubmission thead{
	background-color:					rgba(4, 26, 57, 0.03);
	box-shadow: 						none;
}

.submission .tableSubmission td .percentContainer{
	flex: 								1 0 0;
	display: 							flex;
	align-items: 						center;
	gap: 								10px;
}

.submission .tableSubmission td .percentContainer .bar{
	position: 							relative;
	flex: 								1 0 0;
	height: 							10px;
	background-color: 					rgba(4, 26, 57, 0.03);
}

.submission .tableSubmission td .percentContainer .bar .barFill{
	height: 							10px;
	position: 							absolute;
	top: 								0;
	left: 								0;
	background: 						var(--gradient);
	background-clip: 					padding-box;
	-webkit-background-clip: 			padding-box;
}

.submission .tableSubmission td .percentContainer .percent{
	font-size: 							12px;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

@media (max-width: 991px){
	/* SUBMISSION */
	.submission .titleDocuments{
		padding: 							15px 20px;
	}

	.submission .tableSubmission{
		display: 							none;
	}

	.submission .progressInformation{
		padding: 							20px 25px;
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.submission .progressInformation:last-of-type{
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
		margin-bottom: 						20px;
	}

	.submission .progressInformation .title{
		font-size: 							14px;
		font-weight: 						500;
		margin-bottom: 						10px;
	}

	.submission .progressInformation .subTitle{
		font-size: 							12px;
		margin-bottom: 						10px;
		opacity: 							0.5;
	}

	.submission .progressInformation .percentContainer{
		display: 							flex;
		align-items: 						center;
		gap: 								40px;
	}

	.submission .progressInformation .percentContainer .bar{
		position: 							relative;
		flex: 								1 0 0;
		height: 							10px;
		background-color: 					rgba(4, 26, 57, 0.03);
	}

	.submission .progressInformation .percentContainer .bar .barFill{
		height: 							10px;
		position: 							absolute;
		top: 								0;
		left: 								0;
		background: 						var(--gradient);
		background-clip: 					padding-box;
		-webkit-background-clip: 			padding-box;
	}

	.submission .progressInformation .percentContainer .percent{
		font-size: 							12px;
		background: 						var(--gradient);
		background-clip: 					text;
		-webkit-background-clip: 			text;
		-webkit-text-fill-color: 			transparent;
	}
}