/* LOGIN */
.login {
	min-height: 						100vh;
	position: 							relative;
	background-color: 					var(--darkBlue);
}

.login .world{
	width: 								60%;
	height: 							100%;
	object-fit: 						cover;
	object-position: 					center center;
	position: 							absolute;
	top: 								0;
	left: 								0;
	background-blend-mode: 				soft-light, normal;
	mix-blend-mode: 					screen;
}

.login .row{
	height: 							100vh;
	align-items: 						center;
}

.login .logo{
	height: 							90px;
	object-fit: 						contain;
	object-position: 					center center;
	margin: 							0 auto 20px;
}

.login .inputContainer{
	margin-bottom: 						10px;
}

.login .loginSubmit{
	display: 							block;
	width: 								100%;
	padding: 							10px 65px;
	border:  							unset;
	margin: 							20px 0 15px;
	font-weight: 						700;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	transition: 						all 0.3s ease-in-out;
}

.login .loginSubmit:hover{
	background: 						unset;
	background-color: 					#00A3FF;
	color: 								var(--darkBlue);
	-webkit-text-fill-color: 			unset;
}

.login .forgotedPass{
	width: 								fit-content;
	color: 								#FFFFFF;
	font-size: 							12px;
	opacity: 							0.5;
	margin: 							0 auto;
	background-color: 					transparent;
	border: 							unset;
	transition: 						all 0.3s ease-in-out;
}

.login .forgotedPass:hover,
.login .contactUs:hover{
	opacity: 							1;
}

.forgottenPassword .modal-content{
	border: 							unset;
    border-radius: 						unset;
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.20);
}

.forgottenPassword .modal-content .modal-body{
	padding: 							50px 50px 30px;
	position: 							relative;
}

.forgottenPassword .modal-content .modal-body .title{
	font-weight: 						700;
	margin-bottom: 						15px;
}

.forgottenPassword .modal-content .modal-body > img{
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								20px;
	right: 								20px;
	cursor: 							pointer;
}

.forgottenPassword .modal-content .modal-body > .inputContainer{
	margin-bottom: 						30px;
}

.forgottenPassword .modal-content .modal-body > .actions{
	display: 							flex;
	gap: 								10px;
	justify-content: 					flex-end;
}

.forgottenPassword .modal-content .modal-body > .actions > button{
	padding: 							10px 20px;
	border: 							unset;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	transition: 						all 0.3s ease-in-out;
}

.forgottenPassword .modal-content .modal-body > .actions > button:hover{
	background-color: 					var(--darkBlue);
}

.forgottenPassword .modal-content .modal-body > .actions > button:first-of-type{
	background-color: 					transparent;
	-webkit-text-fill-color: 			#000000;
}

.login .contactUs{
	width: 								fit-content;
	color: 								#FFFFFF;
	font-size: 							12px;
	opacity: 							0.5;
	margin: 							5px auto 0;
	text-decoration: 					none;
	transition: 						all 0.3s ease-in-out;
}

@media (max-width: 991px){
	/* LOGIN */
	.login .world{
		width: 								100%;
		height: 							auto;
		position: 							static;
		margin-top: 						20px;
	}

	.login .row{
		align-items: 						start;
	}

	.login .logo{
		height: 							45px;
		object-fit: 						contain;
		object-position: 					center center;
		margin: 							0 auto 30px;
	}

	.login .inputContainer{
		margin-bottom: 						10px;
	}

	.login .loginSubmit{
		font-size: 							14px;
		padding: 							10px 30px;
		margin: 							40px 0 20px;
	}

	.forgottenPassword .modal-content .modal-body{
		padding: 							45px 50px;
	}
	
	.forgottenPassword .modal-content .modal-body .title{
		text-align: 						center;
		margin-bottom: 						15px;
	}
	
	.forgottenPassword .modal-content .modal-body > img{
		width: 								20px;
		height: 							20px;
		top: 								15px;
		right: 								15px;
	}
	
	.forgottenPassword .modal-content .modal-body > .actions{
		justify-content: 					center;
	}
}