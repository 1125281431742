/* DASHBOARD */
.dashboard .infomationList {
	display: 						flex;	
	flex-flow: 						row wrap;
	gap: 							10px;
	margin-bottom: 					85px;
}

.dashboard .informationItem {
	flex: 							1 0 calc(100% / 4 - (10px * 3 / 4));
	padding: 						30px;
	background-color: 				var(--blue);
}

.dashboard .informationItem h3{
	color: 							var(--darkBlue);
}

.darkMode .dashboard .informationItem h3{
	color: 							white;
}

.dashboard .informationItem span{
	font-size: 						14px;
	background: 					var(--gradient) text;
	-webkit-text-fill-color: 		transparent;
}

@media (max-width: 991px){
	/* DASHBOARD */
	.dashboard .infomationList {
		gap: 							5px;
		margin-bottom: 					0px;
	}

	.dashboard .informationItem {
		flex: 							1 0 calc(100% / 2 - (10px * 1 / 2));
		padding: 						10px;
		text-align: 					center;
	}

	.dashboard .informationItem:nth-of-type(10) {
		flex-grow: 						1;
	}

	.dashboard .informationItem h3{
		font-size: 						23px;
	}

	.dashboard .informationItem span{
		font-size: 						10px;
	}
}