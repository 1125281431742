/* ALERTS */
.alerts .titleAndSearch{
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
	margin-bottom: 						55px;
}

.alerts .titleAndSearch .searchAndSort{
	flex: 								0 0 25%;
}

.alerts .titleAndSearch .searchAndSort .searchContainer input{
	padding-right: 						50px;
}

.alerts.darkMode .nav-tabs .nav-link {
	background-color: 					#131E29;
	color: 								white;
}

.alerts.darkMode .nav-tabs .nav-link.active {
	background-color: 					#243D55;
	color: 								white;
}

@media (max-width: 991px){
	/* ALERTS */
	.alerts .titleAndSearch{
		justify-content: 					unset;
		flex-flow: 							column nowrap;
		margin-bottom: 						20px;
	}

	.alerts .titleAndSearch .title{
		margin-bottom: 						30px;
	}

	.alerts .titleAndSearch .searchAndSort{
		width: 								100%;
		flex: 								0 0 100%;
		display: 							flex;
		gap: 								40px;
	}

	.alerts .titleAndSearch .searchContainer{
		flex: 								1 0 0;
	}

	.alerts .titleAndSearch .searchContainer input{
		padding-right: 						35px;
		height: 							100%;
	}

	.alerts .titleAndSearch .searchAndSort .sortMobile{
		border: 							unset;
		background-color: 					#FFFFFF;
		padding: 							10px;
		font-size: 							12px;
		color: 								rgba(0, 0, 0, 0.40);
		font-weight: 						300;
		display: 							flex;
		gap: 								40px;
		align-items: 						center;
	}

	.alerts .titleAndSearch .searchAndSort .sortMobile::after{
		content: 							"";
		width: 								20px;
		height: 							20px;
		background: 						url("../../images/arrowForward.svg") center center no-repeat;
		background-size: 					15px;
		transform: 							rotate(90deg);
	}

	.alerts .titleAndSearch .searchAndSort .sortDataContainer{
		width: 								90vw;
		height: 							max-content;
		position: 							fixed;
		top: 								0;
		left: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto;
		border-radius: 						35px;
		padding: 							50px 35px;
		background: 						var(--grey);
		box-shadow: 						0px -30px 100px 0px rgba(4, 26, 57, 0.55);
		z-index: 							999;
		opacity: 							0;
		visibility: 						hidden;
		transition: 						all 0.3s ease-in-out;
	}

	.alerts .titleAndSearch .searchAndSort .sortDataContainer::after{
		content: 							"";
		width: 								25px;
		height: 							25px;
		background: 						url("../../images/close.svg") center center no-repeat;
		background-size: 					25px;
		position: 							absolute;
		top: 								25px;
		right: 								25px;
	}

	.darkMode .alerts .titleAndSearch .searchAndSort .sortDataContainer::after{
		mask: 								url("../../images/close.svg") center center no-repeat;
		mask-size: 							25px;
		background: 						white;
	}

	.alerts .titleAndSearch .searchAndSort .sortDataContainer.show{
		opacity: 							1;
		visibility: 						visible;
	}

	.alerts .titleAndSearch .searchAndSort .sortDataContainer > .title{
		font-size: 							16px;
		font-weight: 						700;
	}

	.alerts .titleAndSearch .searchAndSort .sortDataContainer > span{
		font-size: 							12px;
		margin-bottom: 						20px;
	}

	.alerts .titleAndSearch .searchAndSort .sortDataContainer > span:last-of-type{
		margin-bottom: 						0;
	}
}