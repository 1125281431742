.staffList .visitsFilters .activeFilter {
    background-color: 					var(--darkBlue) !important;
}

.staffList .visitsFilters{
	display: 							flex;
    align-items:                        center;
	flex-flow: 							row wrap;
	gap: 								10px;
	margin-bottom: 						10px;
    user-select:                        none;
}

.staffList .visitsFilters .locationFilter{
	display: 							flex;
	align-items: 						center;
	padding: 							10px 20px;
	background-color: 					#FFFFFF;
	position: 							relative;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
	user-select: 						none;
}

.staffList.darkMode .visitsFilters .locationFilter{
	background-color: 					#131E29;
}

.staffList .visitsFilters .locationFilter .filterLabel {
    margin-left:                        5px;
}

.staffList .visitsFilters .locationFilter .filterLabelActive {
    margin-left:                        5px;
	background: 						var(--gradient) text;
	-webkit-text-fill-color: 			transparent;
}

.staffList .visitsFilters .locationFilter.show{
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	user-select: 						none;
}

.staffList .visitsFilters .locationFilter.active{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.staffList .visitsFilters .locationFilter::after{
	content: 							"";
	width: 								20px;
	height: 							20px;
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size: 					15px;
	transform: 							rotate(90deg);
	margin-left: 						20px;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.staffList .visitsFilters .locationFilter.show::after{
	transform: 							rotate(270deg);
}

.staffList .visitsFilters .locationFilter > img{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-right: 						5px;
	pointer-events: 					none;
}

.staffList .visitsFilters .locationFilter > span{
	font-size: 							12px;
	pointer-events: 					none;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions{
	width: 								max-content;
	position: 							absolute;
	top: 								105%;
	left: 								0;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	padding: 							20px;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
	cursor: 							default;
	width: 								330px;
}

.staffList.darkMode .visitsFilters .locationFilter .locationFilterOptions{
	background: 						#131E29;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .labelDatePickerContainer {
	display: 							flex;
	justify-content: 					space-between;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .labelDatePickerContainer .labelDatePicker {
	display: 							flex;
	flex: 								1;
	padding-left: 						10px;
	font-weight: 						700;
	font-size: 							14px;
}

.darkMode .staffList .visitsFilters .locationFilter .locationFilterOptions .labelDatePickerContainer .labelDatePicker {
	-webkit-text-fill-color: 			white;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .labelDatePickerContainer .labelDatePicker:nth-child(2) {
	display: 							flex;
	flex: 								1.1;
	padding-left: 						0px;
	font-weight: 						700;
	font-size: 							14px;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .clearButtonContainer {
	display: 							flex;
	justify-content: 					flex-end;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .clearButtonContainer .clearButton {
	border: 							unset;
	background-color: 					transparent;
	padding: 							5px 15px;
	font-size: 							12px;
}

.darkMode .staffList .visitsFilters .locationFilter .locationFilterOptions .clearButtonContainer .clearButton {
	-webkit-text-fill-color: 			white;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .locationFilterOptionsInput{
	background-color: 						rgba(4, 26, 57, 0.03);
	position: 								relative;
}

.staffList.darkMode .visitsFilters .locationFilter .locationFilterOptions .locationFilterOptionsInput{
	background-color: 						#27343F;
	-webkit-text-fill-color: 				white;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .optionsScroll{
	max-height: 						300px;
	overflow-y: 						auto;
}

/* width */
.staffList .visitsFilters .locationFilter .locationFilterOptions .optionsScroll::-webkit-scrollbar {
	width: 								3px;
}
  
 /* Track */
.staffList .visitsFilters .locationFilter .locationFilterOptions .optionsScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
 }
  
/* Handle */
.staffList .visitsFilters .locationFilter .locationFilterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.staffList.darkMode .visitsFilters .locationFilter .locationFilterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						white;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions.show, .staffList .visitsFilters .locationFilter .searchContainer.show{
	visibility: 						visible;
	opacity: 							1;
	-webkit-text-fill-color: 			#000000;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .title{
	font-size: 							14px;
	font-weight: 						700;
	margin-bottom: 						5px;
}

.staffList.darkMode .visitsFilters .locationFilter .locationFilterOptions .title,
.staffList.darkMode .visitsFilters .locationFilter .locationFilterOptions .option{
	-webkit-text-fill-color: 			white;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .option{
	display: 							flex;
	align-items: 						center;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions.location{
	width: 								280px;
	top: 								290%;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .option > input{
	margin-right: 						0;
	cursor: 							pointer;
}

.staffList.darkMode .visitsFilters .locationFilter .locationFilterOptions .option > input{
	background-color: 					white;
}

.staffList.darkMode .visitsFilters .locationFilter .locationFilterOptions .option > input:checked{
	background-color: 					var(--green);
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .option > label{
	flex:  								1 0 0;
	font-size: 							12px;
	opacity: 							0.8;
	cursor: 							pointer;
	padding: 							10px 0 10px 10px;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .optionScroll{
	max-height: 						220px;
	overflow-y: 						auto;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .optionScroll::-webkit-scrollbar {
	width: 								3px;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .optionScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
}
 
.staffList .visitsFilters .locationFilter .locationFilterOptions .optionScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .actions{
	display: 							flex;
	gap: 								10px;
	justify-content: 					center;
	margin-top: 						15px;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .actions > button {
	border: 							unset;
	background-color: 					transparent;
	padding: 							5px 15px;
	font-size: 							12px;
}

.staffList.darkMode .visitsFilters .locationFilter .locationFilterOptions .actions > button:first-of-type {
	-webkit-text-fill-color: 			white;	
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .actions > button:last-of-type {
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.staffList .titleAndSearch {
    display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
	margin-bottom: 						55px;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .locationFilterOptionsInputContainer {
	position: 							relative;
}

.staffList .visitsFilters .locationFilter .locationFilterOptions .locationFilterOptionsInputContainer .searchIcon {
    position: 							absolute;
    right: 								10px;
    top: 								50%;
    transform: 							translateY(-50%);
    pointer-events: 					none;
}

.staffList .titleAndSearch .searchAndSort {
    flex: 								0 0 25%;
}

.staffList .titleAndSearch .searchAndSort .searchContainer .searchContainer input {
	padding-right: 						50px;
}

.staffList .addDocumentHeader {
	padding: 							15px;
}

.staffList .addDocumentHeader .btnBgBlueTextGradient {
	padding: 							10px 15px;
}

.staffList .addDocumentHeader .btnBgBlueTextGradient .addIcon {
	height:                             18px;
    width:                              18px;
    margin-right:                       5px;
}

.staffList.darkMode .tableStaff .tableHeader tr {
	background-color: 					#27343F;
}

.staffList .tableStaff .tableHeader th:first-child {
	display: 							flex;
	flex: 								1;
}

.staffList .tableStaff .visitTableBody tr:hover{
	cursor: auto;
}

.infoContainer .modalIconContainer {
    display: flex;
}

.staffList .tableStaff .infoContainer .modalIconContainer .eyeIcon {
    mask: 								url("../../images/visibility.svg") center center no-repeat;
    mask-size:                          20px;
    width:                              20px;
    height:                             20px;
    background:                         var(--darkBlue);
    object-fit: 						contain;
    object-position: 					center center;
	cursor: 							pointer;
	position:                           absolute;
    right:                              10px;
    top:                                0;
    bottom:                             0;
    margin:                             auto 0;
}

.react-daterange-picker__wrapper {
	display: 							flex;
	flex-grow: 							1;
	flex-shrink:		 				0;
	align-items: 						center;
	height: 							50px;
	padding-right: 						15px;
  }
  
  .react-daterange-picker__inputGroup {
	display: 							flex;
	justify-content: 					center;
	min-width: 							calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
	height: 							100%;
	flex-grow: 							1;
	padding: 							0;
	box-sizing: 						content-box;
  }

  .react-daterange-picker__inputGroup__input:invalid {
	background: 						transparent !important;
  }

  input:not(input[type="checkbox"], input[type="radio"]):focus {
	border-bottom: 						1px solid rgba(0, 154, 255, 1);
  }
  
  .react-daterange-picker__inputGroup__divider {
	display: 							flex;
	justify-content: 					center;
	align-content: 						center;
	padding: 							1px 0;
	white-space: 						pre;
  }

  .react-date-picker__inputGroup__divider {
	display: 							flex;
	justify-content: 					center;
	align-items: 						center;
	padding: 							0 5px 0 5px;
  }

  .darkMode .react-date-picker__inputGroup__divider {
	-webkit-text-fill-color: 			white;
  }
  
  .react-daterange-picker__inputGroup__divider,
  .react-daterange-picker__inputGroup__leadingZero {
	display: 							inline-block;
	font: 								inherit;
  }
  
  .react-daterange-picker__inputGroup__input {
	width: 										20px !important;
	height: 									100%;
	position: 									relative;
	padding: 									0 0 0 0 !important;
	border: 									0;
	margin: 									0;
	background: 								none;
	color: 										currentColor;
	font-size: 									12px;
    font-weight: 								300;
	box-sizing: 								content-box;
	-webkit-appearance: 						textfield;
	-moz-appearance: 							textfield;
	appearance: 								textfield;
	text-align: 								center;
  }

  .react-daterange-picker__inputGroup .react-daterange-picker__inputGroup__year {
	width: 										30px !important;
	height: 									100%;
	position: 									relative;
	padding: 									0 0 0 0 !important;
	border: 									0;
	margin:										0;
	background: 								none;
	color: 										currentColor;
	font-size: 									12px;
    font-weight: 								300;
	box-sizing: 								content-box;
	-webkit-appearance: 						textfield;
	-moz-appearance: 							textfield;
	appearance: 								textfield;
  }

 .react-daterange-picker__inputGroup__day{
	padding: 									0 0 0 0 !important;
 }
  
  .react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
	-webkit-appearance: 						none;
	-moz-appearance: 							none;
	appearance: 								none;
	margin: 									0;
  }
  
  .react-daterange-picker__inputGroup__input:invalid {
	background: 								rgba(255, 0, 0, 0.1);
  }
  
  .react-daterange-picker__inputGroup__input--hasLeadingZero {
	margin-left: 								-0.54em;
	padding-left: 								calc(1px + 0.54em);
  }
  
  .react-daterange-picker__button {
	border: 									0;
	background: 								transparent;
	padding: 									0;
  }
  
  .react-daterange-picker__button:enabled {
	cursor: 									pointer;
  }
  
  .react-daterange-picker__button:enabled:hover .react-daterange-picker__button__icon,
  .react-daterange-picker__button:enabled:focus .react-daterange-picker__button__icon {
	stroke: 									#0078d7;
  }
  
  .react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
	stroke: 									#6d6d6d;
  }
  
  .react-daterange-picker__button svg {
	display: 									inherit;
  }
  
  .react-daterange-picker__calendar {
	width: 										350px;
	max-width: 									100vw;
	z-index: 									1;
  }
  
  .react-daterange-picker__calendar--closed {
	display: 									none;
  }
  
  .react-daterange-picker__calendar .react-calendar {
	border-width: 								0;
	width: 										355px;
	padding: 									20px;
  }

  .darkMode .react-daterange-picker__calendar .react-calendar {
	background-color: 							#131E29;
  }

  .react-daterange-picker__clear-button {
	display: 									none;
  }

  .calendarIconSpan {
	content:                                    "";
    mask:                                       url(../../images/calendarIcon.svg) no-repeat center;
    mask-size:                                  contain;
    width:                                      13.5px;
    height:                                     15px;
    background:                                 var(--darkBlue);
  }

  .darkMode .calendarIconSpan {
    background:                                 var(--gradient);
  }

  .calendarIconSpan:hover {
	cursor: 									pointer;
  }

  .calendarIconSpan.active {
	content:                                    "";
    mask:                                       url(../../images/calendarIcon.svg) no-repeat center;
    mask-size:                                  contain;
    width:                                      13.5px;
    height:                                     15px;
    background:                                 var(--gradient);
  }

  .calendarArrowIcon {
    width: 										10px;
    height: 									16px;
  }

  .darkMode .calendarArrowIcon {
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
  }

  .react-calendar__navigation__label:hover, .react-calendar__navigation__label:active, .react-calendar__navigation__label:focus {
	background-color: transparent !important;
  }

  .react-calendar__navigation__prev-button:hover, .react-calendar__navigation__prev-button:active, .react-calendar__navigation__prev-button:focus {
	background-color: transparent !important;
  }

  .react-calendar__navigation__next-button:hover, .react-calendar__navigation__next-button:active, .react-calendar__navigation__next-button:focus {
	background-color: transparent !important;
  }

  .react-calendar__navigation__label__labelText {
	display: flex;
  }

  .react-calendar__tile:hover {
	background-color: rgb(0, 163, 255, 0.1) !important;
  }

  .react-calendar__tile:active, .react-calendar__tile:focus, .react-calendar__tile--hover {
	background-color: rgb(0, 163, 255, 0.1) !important;
  }

  .react-calendar__navigation button:nth-child(1) {
	order: 										2;
  }

  .react-calendar__navigation button:nth-child(2) {
	order: 										1;
  }

  .react-calendar__navigation button:nth-child(2) span::after {
	content: 							"";
	display: 							flex;
	align-self: 						center;
	mask: 								url("../../images/vectorDownIcon.svg") center center no-repeat;
    mask-size:                          contain;
    width:                              10px;
    height:                             5px;
    background:                         var(--darkBlue);
    object-fit: 						contain;
    object-position: 					center center;
	cursor: 							pointer;
	margin-left: 						10px;
  }

  .react-calendar__navigation button:nth-child(3) {
	order: 								3;
  }

  .react-calendar__navigation__prev-button {
	display: 									flex;
	justify-content: 							center;
	align-items: 								center;
  }

  .react-calendar__navigation__next-button {
	display: 									flex;
	justify-content: 							center;
	align-items: 								center;
  }

  .react-calendar__navigation__label__labelText--from {
	font-weight: 								700;
	font-size: 									20px;
  }

  .darkMode .react-calendar__navigation__label__labelText--from {
	-webkit-text-fill-color: 					white;
  }

  .react-calendar__month-view__days {
	column-gap: 								10px;
	row-gap: 									0px;
  }

  .react-calendar__month-view__days__day {
	display: 									flex;
	flex: 										0 0 12% !important;
	justify-content: 							center;
	align-items: 								center;
	padding: 									8.5px;
	max-width: 									calc(100% / 7 - (10px * 6 / 7)) !important;
  }

  .darkMode .react-calendar__month-view__days__day {
	-webkit-text-fill-color: 					rgba(255, 255, 255, 0.9);
  }

  .react-calendar__month-view__days__day abbr {
	padding: 5px 7px;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
	-webkit-text-fill-color: 					rgba(0, 0, 0, 0.2);
  }

  .darkMode .react-calendar__month-view__days__day--neighboringMonth {
	-webkit-text-fill-color: 					rgba(255, 255, 255, 0.4);
  }

  .react-calendar__month-view__weekdays {
	gap: 10px;
  }

  .react-calendar__month-view__weekdays__weekday {
	max-width: 									calc(100% / 7 - (10px * 6 / 7)) !important;
	font-weight: 								700;
	font-size: 									14px;
	-webkit-text-fill-color: 					rgba(0, 0, 0, 0.4);
	padding: 									0;
  }

  .darkMode .react-calendar__month-view__weekdays__weekday {
	-webkit-text-fill-color: 					white;
  }

  .react-calendar__month-view__weekdays__weekday abbr[title] {
	display: 									block;
	font-size: 									0;
	text-decoration: 							none;
  }

  .react-calendar__month-view__weekdays__weekday abbr[title]::first-letter {
	font-size: 									14px;
  }

  .react-calendar__tile--now {
	background: 								transparent;
  }

  .react-calendar__tile--now.react-calendar__year-view__months__month abbr {
	padding: 									10px;
  }

  .react-calendar__tile--now.react-calendar__tile--rangeStart abbr, 
  .react-calendar__tile--now.react-calendar__tile--rangeEnd abbr {
	border: 									1px solid #FFFFFF !important;
  }

  .react-calendar__tile--now abbr{
	background: 								transparent !important;
	border: 									1px solid var(--darkBlue) !important;
	border-radius: 								50px;
  }

  .react-calendar__tile--now:hover {
	background: 								rgba(0, 163, 255, 0.1) !important;
	-webkit-text-fill-color: 					#000000;
  }

  .react-calendar__tile--hover {
	background: 								rgba(0, 163, 255, 0.1) !important;
  }

  .react-calendar__tile--active {
	background: 								rgba(0, 163, 255, 0.1) !important;
    color: 										white;
  }

  .react-calendar__tile--rangeStart, .react-calendar__tile--rangeStart:hover {
	background: 								var(--gradient) padding-box !important;
	-webkit-text-fill-color: 					#FFFFFF;
	font-weight: 								400;
	font-size: 									14px;
  }

  .react-calendar__tile--rangeEnd, .react-calendar__tile--rangeEnd:hover {
	background: 								var(--gradient) padding-box !important;
	-webkit-text-fill-color: 					#FFFFFF;
	font-weight: 								400;
	font-size: 									14px;
  }

  .printReport {
	cursor: 									pointer;
  }