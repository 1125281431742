.selectFile{
	height: 							fit-content;
	display: 							flex;
	align-items: 						center;
	justify-content: 					center;
	font-size: 							14px;
	padding: 							10px 50px;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	border: 							unset;
	transition: 						all 0.3s ease-in-out;
}

.selectFile:hover{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.clipIcon {
    margin-left:                        10px;
}

.staffModal .dropdown {
    position:                           relative;
    
    -webkit-appearance:                 none; /* Chrome, Safari, Opera */
    -moz-appearance:                    none; /* Firefox */
    appearance:                         none;

    border:                             unset;
    background-color:                   #FFFFFF;
    padding:                            10px;
    font-size:                          12px;
    font-weight:                        300;
    position:                           relative;

    background-image:                   url('../../images/arrowDownIcon.svg'); /* Añade la URL del icono deseado */
    
    background-repeat:                  no-repeat;
    background-position:                right 15px center;

    user-select:                        none;
}

.custom-select-wrapper {
    position: relative;
    display: inline-block;
    width: 200px; /* Ajusta el ancho según sea necesario */
    user-select: none;
}

.custom-select {
    position: relative;
    cursor: pointer;
}

.custom-select-trigger {
    position:                           relative;
    
    -webkit-appearance:                 none; /* Chrome, Safari, Opera */
    -moz-appearance:                    none; /* Firefox */
    appearance:                         none;

    border:                             unset;
    background-color:                   #FFFFFF;
    padding:                            10px;
    font-size:                          12px;
    font-weight:                        300;
    position:                           relative;

    background-image:                   url('../../images/arrowDownIcon.svg'); /* Añade la URL del icono deseado */
    
    background-repeat:                  no-repeat;
    background-position:                right 15px center;

    user-select:                        none;
}

.custom-options {
    position:                           absolute;
    top:                                105%;
    left:                               0;
    right:                              0;
    border:                             1px solid none;
    background-color:                   #fff;
    overflow:                           hidden;
    opacity:                            0;
    visibility:                         hidden;
    transform:                          translateY(-15px);
    transition:                         all 0.3s ease;
    z-index:                            99;
}

.custom-options .custom-option {
    padding:                            10px 14px;
    font-size:                          12px;
    cursor:                             pointer;
    transition:                         all 0.2s ease;
}

.custom-options .custom-option:hover {
    background-color:                   var(--grey); /* Cambia el color de fondo cuando se pasa el ratón */
}

.custom-select.open .custom-options {
    opacity:                            1;
    visibility:                         visible;
    transform:                          translateY(0);
}

.addButton{
	height: 							fit-content;
	display: 							flex;
	align-items: 						center;
	justify-content: 					center;
	font-size: 							14px;
	padding: 							10px 50px;
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	border: 							unset;
	transition: 						all 0.3s ease-in-out;
}

.addButton:hover{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.cancelButton{
	height: 							fit-content;
	display: 							flex;
	align-items: 						center;
	justify-content: 					center;
	font-size: 							14px;
	padding: 							10px 35px;
	color: 								#000000;
	background-color: 					rgba(255, 255, 255, 0.6);
	border: 							unset;
	transition: 						all 0.3s ease-in-out;
}

.cancelButton:hover{
	color: 								#FFFFFF;
	background-color: 					var(--red);
}

.selectFileContainer {
    display:                            flex;
}

.fileName {
    margin-left:                        10px;
    color:                              gray;
    font-size:                          14px;
    align-content: center;
}

