/* CLIENT CONTRACTS LIST */
.clientContracts .clientTableContracts {
    margin-bottom:                      30px;
}

.clientContracts .clientTableContracts td:first-of-type .infoContainer span{
	opacity: 							1;
	cursor:								pointer;
}

.clientContracts .clientTableContracts td:first-of-type .infoContainer span:hover{
	background: var(--gradient) text;
    -webkit-text-fill-color: transparent;
}

.clientContracts .clientTableContracts td:last-of-type .infoContainer{
    justify-content:                    space-between;
}

.clientContracts .clientTableContracts td .infoContainer .reviewed{
    width:                              20px;
    height:                             20px;
    object-fit:                         contain;
    object-position:                    center center;
    cursor:                             pointer;
}

.darkMode .clientContracts .clientTableContracts td .infoContainer .reviewed{
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.clientContracts .addAndPagination{
	display: 							flex;
	align-items: 						center;
	justify-content: 					flex-end;
	margin-bottom: 						10px;
}

.clientContracts .addAndPagination .pagination{
	display: 							flex;
	align-items: 						center;
	gap: 								5px;
}

.clientContracts .addAndPagination .number{
	border: 							unset;
	font-size: 							12px;
	padding: 							2px 7.5px;
	opacity: 							0.5;
}

.clientContracts .addAndPagination .number.active{
	opacity: 							1;
	font-weight: 						900;
	background:  						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.clientContracts .addAndPagination .nextPage{
	width: 								13px;
	height: 							13px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
}

.clientContracts .tableCount{
	width: 								fit-content;
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
	margin: 							0 0 55px auto;
}

@media (max-width: 991px) {
	.clientContracts .clientTableContracts {
		display:                            none;
	}

	.clientContracts .clientContractList{
		display: 							flex;
		flex-flow: 							column nowrap;
		margin: 							25px 0;
	}

	.clientContracts .clientContractList .contractRow{
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
		padding: 							15px 23px 25px;
	}

	.clientContracts .clientContractList .contractRow:last-of-type{
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.clientContracts .clientContractList .contractRow .contractName{
        display:                            flex;
        align-items:                        center;
        justify-content: space-between;
		font-weight: 						500;
		margin-bottom: 						20px;
	}

	.clientContracts .clientContractList .contractRow .contractInformation{
		width: 								100%;
		display:  							grid;
		align-items: 						flex-end;
		justify-content: 					space-between;
		grid-template-columns: 				repeat(2, fit-content);
		grid-template-rows: 				repeat(8, max-content);
		column-gap: 						30px;
		row-gap: 							0;
	}

	.clientContracts .clientContractList .contractRow .contractInformation .contractValue{
		font-size: 							12px;
	}

	.clientContracts .clientContractList .contractRow .contractInformation .contractTitle{
		font-size: 							10px;
		font-weight: 						700;
		opacity: 							0.4;
	}

	.clientContracts .clientContractList .contractRow .contractInformation > span:nth-of-type(1){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					1;
		grid-row-end: 						2;
	}

	.clientContracts .clientContractList .contractRow .contractInformation > span:nth-of-type(2){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					2;
		grid-row-end: 						3;
		margin-bottom:						10px;
	}

	.clientContracts .clientContractList .contractRow .contractInformation > span:nth-of-type(3){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					1;
		grid-row-end: 						2;
	}

	.clientContracts .clientContractList .contractRow .contractInformation > span:nth-of-type(4){
		grid-column-start: 					2;
		grid-column-end: 					3;
		grid-row-start: 					2;
		grid-row-end: 						3;
		margin-bottom: 						10px;
	}

	.clientContracts .clientContractList .contractRow .contractInformation > span:nth-of-type(5){
		display:							flex;
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					3;
		grid-row-end: 						4;
	}

	.clientContracts .clientContractList .contractRow .contractInformation > span:nth-of-type(6){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					4;
		grid-row-end: 						5;
		margin-bottom:						10px;
	}
	
	.clientContracts .addAndPagination{
		padding: 							0 20px;
	}

	.clientContracts .tableCount{
		font-size: 							10px;
		margin: 							0 25px 25px auto;
	}
}