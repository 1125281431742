/* STAFF LIST */
.staffList {
	margin-bottom: 						30px;
}

.staffList .addDocumentHeader {
	display: 							flex;
	flex-flow: 							row nowrap;
	align-items: 						center;
	justify-content: 					space-between;
	gap: 								20px;
	padding: 							20px 30px;
	background: 						#FFFFFF;
	box-shadow: 						0px 4px 30px 0px rgba(35, 107, 216, 0.08);
	transition: 						background 0.3s ease-in-out;
}

.darkMode .staffList .addDocumentHeader {
	background: 						#131E29;
}

.staffList .addDocumentHeader > h5 {
	font-weight: 						700;
}

.staffList .tableStaff thead {
	background-color: 					rgba(4, 26, 57, 0.03);
	box-shadow: 						none;
}

.staffList .tableStaff thead th:first-of-type{
	width: 								80px;
}

.staffList .tableStaff tbody tr:hover{
	background-color: 					#FCFCFC;
	cursor: 							pointer;
	transition: 						all 0.3s ease-in-out;
}

.darkMode .staffList .tableStaff tbody tr:hover{
	background-color: 					#181E24;
}

.staffList.darkMode .tableStaff tbody tr:hover{
	background-color: 					#27343F;
}

.staffList .tableStaff .profilePhoto {
	width: 								35px;
    height: 							35px;
    object-fit: 						cover;
    object-position: 					center center;
    border-radius: 						100px;
    border: 							3px solid transparent;
    background: 						var(--gradient) center center no-repeat;
    background-size: 					35px 35px;
    background-clip: 					border-box;
	margin:								0 auto;
}

.staffList .tableStaff .deleteStaff {
	width: 								15px;
    height: 							15px;
    object-fit: 						contain;
    object-position: 					center center;
	cursor: 							pointer;
	position:                           absolute;
    right:                              10px;
    top:                                0;
    bottom:                             0;
    margin:                             auto 0;
}

/* GENERAL MODAL VIEW */
.viewStaffModal .modal-content,
.addStaffModal .modal-content {
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.viewStaffModal .modal-body,
.addStaffModal .modal-body {
	padding: 							50px 100px;
}

.viewStaffModal .modal-body .closeModal,
.addStaffModal .modal-body .closeModal {
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								20px;
	right: 								20px;
	cursor: 							pointer;
}

/* VIEW STAFF */
.viewStaffModal .modal-body .qrCode {
	display: 							block;
	margin: 							0 auto 50px;
}

.viewStaffModal .modal-body .staffResume {
	display: 							flex;
	flex-flow: 							row nowrap;
	gap: 								100px;
	margin-bottom: 						50px;
}

.viewStaffModal .modal-body .staffResume .profilePhoto{
    width: 								200px;
	height: 							200px;
	object-position: 					center center;
	object-fit: 						cover;
    border-radius: 						100px;
    border: 							5px solid transparent;
    background: 						var(--gradient) padding-box, var(--gradient) border-box;
	background-size: 					100% 100%;
	cursor: 							pointer;
}

.viewStaffModal .modal-body .staffResume .information {
	flex: 								1 0 0;
	display: 							grid;
	grid-template-areas: 				
	"A A A"
	"B B B"
	"C C C"
	"D . E"
	"F . G"
	"H . ."
	"I . .";
}

.viewStaffModal .modal-body .staffResume .information .textInfo {
	font-size: 							10px;
	opacity: 							0.5;
	font-weight: 						700;
	margin-bottom: 						10px;
}

.viewStaffModal .modal-body .staffResume .information .textInfo:last-of-type {
	margin-bottom: 						0;
}

.viewStaffModal .modal-body .staffResume .information .textTitle {
	font-size: 							20px;
	font-weight: 						700;
}

.viewStaffModal .modal-body .staffResume .information span:nth-child(1) {
	grid-area: 							A;
}

.viewStaffModal .modal-body .staffResume .information span:nth-child(2) {
	grid-area: 							B;
}

.viewStaffModal .modal-body .staffResume .information span:nth-child(3) {
	grid-area: 							C;
}

.viewStaffModal .modal-body .staffResume .information span:nth-child(4) {
	grid-area: 							D;
}

.viewStaffModal .modal-body .staffResume .information span:nth-child(5) {
	grid-area: 							F;
}

.viewStaffModal .modal-body .staffResume .information span:nth-child(6) {
	grid-area: 							E;
}

.viewStaffModal .modal-body .staffResume .information span:nth-child(7) {
	grid-area: 							G;
}

.viewStaffModal .modal-body .staffResume .information span:nth-child(8) {
	grid-area: 							H;
}

.viewStaffModal .modal-body .staffResume .information span:nth-child(9) {
	grid-area: 							I;
}

.viewStaffModal .modal-body .downloadQR {
	width: 								fit-content;
	font-size: 							12px;
	font-weight: 						700;
	margin-bottom: 						5px;
	margin-left: 						auto;
}

.viewStaffModal .modal-body .downloadQR > label {
	font-weight: 						300;
	opacity: 							0.5;
}

.viewStaffModal .modal-body .btnBgBlueTextGradient {
	margin-left: 						auto;
}

/* ADD STAFF */
.addStaffModal .modal-body .title {
	font-weight: 						700;
	margin-bottom: 						5px;
}

.addStaffModal .modal-body .description {
	font-weight: 						300;
	font-size: 							14px;
	margin-bottom: 						50px;
}

.addStaffModal .modal-body .inputSelection {
	display: 							flex;
	flex-flow: 							row nowrap;
	gap: 								30px;
	margin-bottom: 						50px;
}

.addStaffModal .modal-body .inputSelection input[type="file"]::file-selector-button {
	display: 							flex;
	width: 								100%;
	margin: 							0 0 10px;
}

.addStaffModal .modal-body .inputSelection .separator {
	font-size: 							12px;
	font-weight: 						700;
}

.addStaffModal .modal-body .btnBgWhiteTextBlack {
	margin-left: 						auto;
}

@media(max-width: 991px) {
	/* STAFF LIST */
	.staffList .tableStaff { 
		display: 							none;
	}
	
	.staffList .staffsContainer{
		margin-bottom: 						25px;
	}

	.staffList .staffsContainer .staffInformation{
		width: 								100%;
		padding: 							25px 20px;
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
	}
	
	.staffList .staffsContainer .staffInformation span{
		font-weight: 						700;
		font-size: 							10px;
		opacity: 							0.4;
	}

	.staffList .staffsContainer .staffInformation:last-of-type{
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.staffList .staffsContainer .staffInformation .titleAndPhoto{
		position: 							relative;
		margin-bottom: 						15px;
	}

	.staffList .staffsContainer .staffInformation .titleAndPhoto > h5{
		font-weight: 						500;
		font-size: 							14px;
	}

	.staffList .staffsContainer .staffInformation .titleAndPhoto > img{
		width: 								35px;
		height: 							35px;
		object-fit: 						cover;
		object-position: 					center center;
		border-radius: 						50%;
		border: 							2px solid transparent;
		background: 						var(--gradient) center center no-repeat;
		background-size: 					35px 35px;
		position: 							absolute;
		top: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto 0;
	}

	.staffList .staffsContainer .staffInformation .imssAndRfc{
		display: 							flex;
		align-items: 						center;
		justify-content: 					space-between;
		margin-bottom: 						15px;

	}

	.staffList .staffsContainer .staffInformation .imssAndRfc h5{
		font-size: 							12px;
	}

	.staffList .staffsContainer .staffInformation .inputContainer span{
		margin-top: 						5px;
	}

	/* GENERAL MODAL VIEW */
	.viewStaffModal .modal-content,
	.addStaffModal .modal-content {
		border-radius: 						35px;
	}

	.viewStaffModal .modal-body,
	.addStaffModal .modal-body {
		padding: 							55px 35px;
	}

	.viewStaffModal .modal-body .closeModal,
	.addStaffModal .modal-body .closeModal {
		width: 								25px;
		height: 							25px;
		object-fit: 						contain;
		object-position: 					center center;
		position: 							absolute;
		top: 								20px;
		right: 								20px;
		cursor: 							pointer;
	}

	/* VIEW STAFF */
	.viewStaffModal .modal-body .qrCode {
		margin: 							0 auto 20px;
	}

	.viewStaffModal .modal-body .staffResume {
		flex-flow: 							column nowrap;
		gap: 								0;
		position: 							relative;
	}

	.viewStaffModal .modal-body .staffResume .profilePhoto{
		width: 								50px;
		height: 							50px;
		position: 							absolute;
		top: 								30px;
		left: 								0;
	}

	.viewStaffModal .modal-body .staffResume .information .textInfo {
		margin-bottom: 						20px;
	}

	.viewStaffModal .modal-body .staffResume .information span {
		font-size: 							12px;
		font-weight: 						500;
	}

	.viewStaffModal .modal-body .staffResume .information span:nth-child(1) {
		text-align: 						center;
		font-weight: 						300;
	}

	.viewStaffModal .modal-body .staffResume .information span:nth-child(2) {
		font-size: 							16px;
		padding-left: 						60px;
	}

	.viewStaffModal .modal-body .staffResume .information span:nth-child(3) {
		padding-left: 						60px;
	}

	.viewStaffModal .modal-body .staffResume .information span:nth-child(4) {
		grid-area: 							E;
	}

	.viewStaffModal .modal-body .staffResume .information span:nth-child(5) {
		grid-area: 							G;
	}

	.viewStaffModal .modal-body .staffResume .information span:nth-child(6) {
		grid-area: 							H;
	}

	.viewStaffModal .modal-body .staffResume .information span:nth-child(7) {
		grid-area: 							I;
		margin-bottom: 						0;
	}

	.viewStaffModal .modal-body .staffResume .information span:nth-child(8) {
		grid-area: 							D;
	}

	.viewStaffModal .modal-body .staffResume .information span:nth-child(9) {
		grid-area: 							F;
	}

	.viewStaffModal .modal-body .downloadQR {
		width: 								100%;
		text-align: 						center;
	}

	.viewStaffModal .modal-body .btnBgBlueTextGradient,
	.viewStaffModal .modal-body .btnBgWhiteTextBlack {
		width: 								100%;
	}

	/* ADD STAFF */
	.addStaffModal .modal-body .title {
		text-align: 						center;
	}

	.addStaffModal .modal-body .description {
		text-align: 						center;
		margin-bottom: 						20px;
	}

	.addStaffModal .modal-body .inputSelection {
		flex-flow: 							column nowrap;
		gap: 								20px;
		margin-bottom: 						20px;
	}

	.addStaffModal .modal-body .inputSelection .separator {
		text-align: 						center;
		font-size: 							12px;
		font-weight: 						700;
	}

	.addStaffModal .modal-body .btnBgWhiteTextBlack {
		margin: 							0 auto;
	}
}