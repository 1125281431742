/* MASSIVE UPLOAD MODAL */
.massiveSupplierUpload .modal-content{
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.massiveSupplierUpload .modal-body{
	padding: 							90px 100px;
}

.massiveSupplierUpload .modal-body .closeModal{
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								20px;
	right: 								20px;
	cursor: 							pointer;
}

.massiveSupplierUpload .modal-body .actions{
	display: 							flex;
	justify-content: 					flex-end;
	align-items: 						center;
	gap: 								25px;
	margin-top: 						20px;
}

.massiveSupplierUpload .modal-body .actions .btnBgBlueTextGradient {
	text-wrap: 							nowrap;
	max-width: 							0;
	padding-inline: 					0;
	transition: 						all 0.3s ease-in-out;
	opacity: 							0;
	visibility: 						hidden;
}

.massiveSupplierUpload .modal-body .actions .btnBgBlueTextGradient.show {
	max-width: 							1000px;
	padding-inline: 					50px;
	opacity: 							1;
	visibility: 						visible;
}

@media (max-width: 991px){
	.massiveSupplierUpload .modal-content{
		border-radius: 						35px;
	}
	
	.massiveSupplierUpload .modal-body{
		padding: 							35px 20px;
	}
	
	.massiveSupplierUpload .modal-body .closeModal{
		top: 								15px;
		right: 								15px;
	}


	.massiveSupplierUpload .modal-body .actions{
		justify-content: 					center;
	}
}