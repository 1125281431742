/* REVIEW FOR AUTH */
.reviewForAuth .header{
	position: 							relative;
	display: 							flex;
	flex-flow: 							row nowrap;
	background-color: 					#FFFFFF;
	padding: 							60px 50px 40px 100px;
	box-shadow: 						0 4px 30px 0 rgba(35, 107, 216, 0.08);
	transition: 						background-color 0.3s ease-in-out;
}

.reviewForAuth.darkMode .header{
	background-color: 					#202B34;
}

.reviewForAuth .header::before{
	content: 							"";
	width: 								1px;
	height: 							80%;
	background-color: 					rgba(0, 0, 0, 0.05);
	position: 							absolute;
	left: 								69.5%;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
}

.reviewForAuth .header .generalInformation {
	max-width: 							70%;
	flex: 								1 0 70%;
	padding-right: 						60px;
}

.reviewForAuth .header .generalInformation .headInformation {
	display: 							flex;
	align-items: 						center;
	gap: 								15px;
	margin-bottom: 						20px;
}

.reviewForAuth .header .generalInformation .headInformation .goBack{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	transform: 							rotate(180deg);
	cursor: 							pointer;
}

.reviewForAuth .header .generalInformation .headInformation .profilePhoto{
	width: 								80px;
    height: 							80px;
    border-radius: 						100px;
    border: 							5px solid transparent;
    background: 						var(--gradient) padding-box, var(--gradient) border-box;
	object-fit: 						contain;
	object-position: 					center center;
}

.reviewForAuth .header .generalInformation .headInformation > h3 > span{
	font-size: 							12px;
	font-weight: 						400;
	background: 						var(--gradient) text;
	-webkit-text-fill-color: 			transparent;
}

.reviewForAuth .header .generalInformation .headInformation .ratingValue{
	font-size: 							16px;
	display: 							flex;
	align-items: 						center;
	padding: 							5px 10px;
	font-weight: 						700;
	background: 						var(--gradient) text, var(--blue) padding-box;
	-webkit-text-fill-color: 			transparent;
}

.reviewForAuth .header .generalInformation .headInformation .ratingValue .ratingImg{
	width: 								20px;
    height: 							20px;
    object-fit: 						contain;
    object-position: 					center center;
}

.reviewForAuth .header .generalInformation .industriesInformation {
	display: 							flex;
	align-items: 						center;
	gap: 								10px;
	margin-bottom: 						20px;
}

.reviewForAuth .header .generalInformation .industriesInformation .industriesContainer {
	flex: 								1 0 80%;
	display: 							flex;
	flex-flow: 							row nowrap;
	align-items: 						center;
	gap: 								5px;
	overflow: hidden;
}

.reviewForAuth .header .generalInformation .industriesInformation .industriesContainer > span{
	font-size: 							12px;
	font-weight: 						700;
	background: 						var(--gradient) text, var(--darkBlue) padding-box;
	-webkit-text-fill-color: 			transparent;
	padding: 							10px 15px;
	text-wrap: 							nowrap;
	text-overflow: 						ellipsis;
	overflow: 							hidden;
}

.reviewForAuth .header .generalInformation .industriesInformation .btnBgBlueTextGradient {
	padding: 							10px 25px;
}

.reviewForAuth .header .generalInformation .description{
	width: 								80%;
	font-size: 							12px;
	opacity: 							0.8;
	margin-bottom: 						20px;
}

.reviewForAuth .header .generalInformation .actions {
	display: 							flex;
	flex-flow: 							row wrap;
	gap: 								10px;
}

.reviewForAuth .header .generalInformation .actions > .btnBgBlueTextGradient{
	padding:							10px 20px;
}

.reviewForAuth .header .contactInformation{
	flex: 								1 0 30%;
	padding-left: 						50px;
}

.reviewForAuth .header .contactInformation .cvpBadge{
	width: 								60px;
    height: 							60px;
    object-fit: 						cover;
    object-position: 					center center;
	margin-bottom: 						30px;
}

.reviewForAuth .header .contactInformation .info{
	margin-bottom: 						25px;
}

.reviewForAuth .header .contactInformation .info:last-of-type{
	margin-bottom: 						0;
}

.reviewForAuth .header .contactInformation .info .infoTitle{
	font-size: 							12px;
	font-weight: 						700;
}

.reviewForAuth .header .contactInformation .info .infoText{
	font-size: 							12px;
	opacity: 							0.6;
}

.supplierIndustriesModalReviewForAuth .modal-content,
.supplierClientsModalReviewForAuth .modal-content {
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.supplierIndustriesModalReviewForAuth .modal-body,
.supplierClientsModalReviewForAuth .modal-body {
	padding: 							50px 65px 35px;
}

.supplierIndustriesModalReviewForAuth .modal-body .commentTitle,
.supplierClientsModalReviewForAuth .modal-body .commentTitle {
	font-weight: 						700;
	margin-bottom: 						20px;
}

.supplierIndustriesModalReviewForAuth .modal-body .actionButton .btnBgBlueTextGradient {
	margin-top: 						30px;
	margin-left: 						auto;
}

.supplierIndustriesModalReviewForAuth .modal-body .industriesContainer { 
	display: 							flex;
	flex-flow: 							row wrap;
	gap: 								10px;
}

.supplierIndustriesModalReviewForAuth .modal-body .industriesContainer .industry {
	flex: 								1 0 auto;
	font-size: 							16px;
	font-weight: 						700;
	text-align: 						center;
	padding: 							10px 15px;
	background: 						var(--gradient) text, var(--darkBlue) padding-box;
	-webkit-text-fill-color: 			transparent;
}

.supplierClientsModalReviewForAuth .modal-body .tableClients span{
	opacity: 							1;
}

.supplierClientsModalReviewForAuth .modal-body .tableClients .actions{
	width: 								100%;
	gap: 								10px;
	justify-content: 					flex-end;
}

.supplierClientsModalReviewForAuth .modal-body .tableClients .actions > div{
	width: 								20px;
	height: 							20px;
	cursor: 							pointer;
	background-size: 					20px 20px;
	background-color: 					var(--darkBlue);
}

.supplierClientsModalReviewForAuth .modal-body .tableClients .actions > div:nth-of-type(1){
	mask: 								url("../../images/activeSupplier.svg") center center no-repeat;
    mask-size:                          20px;
}

.supplierClientsModalReviewForAuth .modal-body .tableClients .actions > div:nth-of-type(2){
	mask: 								url("../../images/authorize.svg") center center no-repeat;
    mask-size:                          20px;
}

.supplierClientsModalReviewForAuth .modal-body .tableClients .actions > div:nth-of-type(3){
	mask: 								url("../../images/reject.svg") center center no-repeat;
    mask-size:                          20px;
}

.supplierClientsModalReviewForAuth .modal-body .tableClients .actions > div:nth-of-type(4){
	mask: 								url("../../images/review.svg") center center no-repeat;
    mask-size:                          20px;
}

.supplierClientsModalReviewForAuth .modal-body .tableClients .actions > div:hover{
	background: 						var(--gradient);
	opacity: 							1;
}

.supplierClientsModalReviewForAuth .modal-body .tableClients .actions > .btnBgBlueTextGradient{
	font-size: 							10px;
	padding: 							5px;
}

.supplierClientsModalReviewForAuth .modal-body .tableClients .actions > .btnBgBlueTextGradient::after{
	content: 							"";
	width: 								15px;
	height: 							15px;
	background: 						url("../../images/arrowForwardFull.svg") center center no-repeat;
	background-size: 					15px 15px;;
}

#activeTooltip .tooltip-inner,
#authorizeTooltip .tooltip-inner,
#rejectTooltip .tooltip-inner,
#reviewTooltip .tooltip-inner{
	font-size: 							12px;
	background-color: 					#FFFFFF;
	color: 								#000000;
	font-weight: 						500;
	padding: 							10px 20px;
}

#activeTooltip .tooltip-arrow::before,
#authorizeTooltip .tooltip-arrow::before,
#rejectTooltip .tooltip-arrow::before,
#reviewTooltip .tooltip-arrow::before{
	border-top: 						6px solid #FFFFFF;
}

.supplierClientsModalReviewForAuth .modal-body .commentActions{
	display: 							flex;
	flex-flow: 							row nowrap;
	justify-content: 					flex-end;
	margin-top: 						20px;
	gap: 								10px;
}

.reviewForAuth .nav-tabs{
	padding-top: 						35px;
	border: 							unset;
	gap: 								10px;
	margin-bottom: 						10px;
	width: 								84%;
}

.reviewForAuth .nav-tabs .nav-link{
	padding: 							10px 20px;
	border-radius: 						unset;
	border: 							unset;
	background-color: 					#FFFFFF;
	color: 								#000000;
	font-size: 							12px;
}

.reviewForAuth.darkMode .nav-tabs .nav-link{
	background-color: 					#131E29;
	color: 								white;
}

.reviewForAuth .nav-tabs .nav-link.active{
	font-weight: 						700;
	color: 								#FFFFFF;
	background-color: 					var(--darkBlue);
}

.reviewForAuth.darkMode .nav-tabs .nav-link.active{
	background-color: 					#243D55;
	color: 								white;
}

.reviewForAuth .tabs-container {
	position: 							relative;
}

.reviewForAuth .tabs-container .send-email {
	display: 							flex;
	gap: 								5px;
	align-items: 						center;
	position: 							absolute;
	right: 								0;
	top: 								32px;
	font-size: 							14px;
	padding: 							10px 30px;
}

#send-email-tooltip .tooltip-inner{
	font-size: 							12px;
	background-color: 					#FFFFFF;
	color: 								#000000;
	font-weight: 						500;
	padding: 							10px 20px;
}

#send-email-tooltip .tooltip-arrow::before{
	border-top: 						6px solid #FFFFFF;
}

.reviewForAuth .tabs-container .send-email::before {
	content: 							"";
	mask: 								url("../../images/forward_to_inbox.svg");
    mask-size:							20px;
    width:                              20px;
    height:                             16px;
    background:                         var(--gradient);
}


.reviewForAuth .saveChanges{
	border: 							unset;
	border-radius: 						100px;
	background-color: 					var(--blue);
	padding: 							10px;
	transition: 						all 0.3s ease-in-out;
	position: 							fixed;
	right: 								30px;
	bottom: 							30px;
	z-index: 							999;
}

.reviewForAuth .saveChanges:hover{
	background-color: 					var(--darkBlue);
}

.reviewForAuth .saveChanges{
	border: 							unset;
	border-radius: 						100px;
	background-color: 					var(--blue);
	padding: 							10px;
	transition: 						all 0.3s ease-in-out;
	position: 							fixed;
	right: 								30px;
	bottom: 							30px;
	z-index: 							999;
}

.reviewForAuth .saveChanges:hover{
	background-color: 					var(--darkBlue);
}

.reviewForAuth .actionButtons{
	display: 							flex;
	gap: 								15px;
	align-items: 						center;
	justify-content: 					flex-end;	
	margin-bottom: 						100px;
}

.reviewForAuth .actionButtons .btnBgBlueTextGradient:first-of-type{
	color: 								#000000;
	background-color: 					transparent;
	-webkit-text-fill-color: 			unset;
}

.reviewForAuth.darkMode .actionButtons .btnBgBlueTextGradient:first-of-type{
	color: 								white;
}

@media (max-width: 991px){
	/* REVIEW FOR AUTH */
	.reviewForAuth .header{
		flex-flow: 							column nowrap;
		padding: 							80px 35px;
		align-items: 						center;
	}
	
	.reviewForAuth .header::before{
		content: 							none;
	}
	
	.reviewForAuth .header .generalInformation {
		max-width: 							100%;
		flex: 								1 0 0%;
		padding-right: 						0;
		margin-bottom: 						35px;
	}
	
	.reviewForAuth .header .generalInformation .headInformation {
		flex-flow: 							column nowrap;
		margin-bottom: 						15px;
	}

	.reviewForAuth .header .generalInformation .headInformation .goBack{
		align-self: 						flex-start;
	}
	
	.reviewForAuth .header .generalInformation .headInformation .profilePhoto{
		width: 								90px;
		height: 							90px;
	}
	
	.reviewForAuth .header .generalInformation .headInformation h3{
		font-size: 							26px;
		text-align: 						center;
	}
	
	.reviewForAuth .header .generalInformation .industriesInformation {
		gap: 								15px;
		margin-bottom: 						15px;
		flex-flow: 							row wrap;
	}
	
	.reviewForAuth .header .generalInformation .industriesInformation .industriesContainer {
		flex: 								1 0 100%;
		flex-flow: 							row wrap;
		justify-content: 					center;
	}
	
	.reviewForAuth .header .generalInformation .industriesInformation .btnBgBlueTextGradient {
		width: 								100%;
	}
	
	.reviewForAuth .header .generalInformation .description{
		width: 								100%;
		text-align: 						center;
		margin-bottom: 						0;
	}

	.reviewForAuth .header .generalInformation .actions {
		display: 							none;
	}

	.reviewForAuth .header .generalInformation > .btnBgBlueTextGradient{
		display: 							none;
	}
	
	.reviewForAuth .header .contactInformation{
		flex: 								1 0 0;
		padding-left: 						0;
		margin-bottom: 						35px;
	}
	
	.reviewForAuth .header .contactInformation .cvpBadge{
		margin: 							0 auto 15px;
	}
	
	.reviewForAuth .header .contactInformation .info{
		text-align: 						center;
		margin: 							0 auto 15px;
	}
	
	.reviewForAuth .header .contactInformation .info:last-of-type{
		margin: 							0 auto 0;
	}

	.reviewForAuth .header .actions {
		width: 								100%;
		display: 							flex;
		flex-flow: 							column nowrap;
		gap: 								10px;
	}

	.reviewForAuth .header .actions > .btnBgBlueTextGradient {
		width: 								100%;
	}

	.supplierIndustriesModalReviewForAuth .modal-body,
	.supplierClientsModalReviewForAuth .modal-body {
		padding: 							30px 25px;
	}

	.supplierIndustriesModalReviewForAuth .modal-body .commentTitle,
	.supplierClientsModalReviewForAuth .modal-body .commentTitle {
		margin-bottom: 						30px;
	}

	.supplierIndustriesModalReviewForAuth .modal-body .actionButton .btnBgBlueTextGradient {
		width: 								100%;
		font-size: 							16px;
	}

	.supplierIndustriesModalReviewForAuth .modal-body .industriesContainer .industry {
		font-size: 							12px;
	}

	.supplierClientsModalReviewForAuth .modal-body .commentActions{
		justify-content: 					center;
		margin-top: 						10px;
	}

	.supplierClientsModalReviewForAuth .modal-body .commentActions > .btnBgBlueTextGradient{
		width: 								100%;
	}

	.supplierClientsModalReviewForAuth .modal-body .tableClients{
		display: 							none;
	}

	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .clientRow {
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
		padding: 							25px 0;
	}

	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .clientRow:last-of-type {
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .clientRow .clientHeaderTitle {
		font-size: 							12px;
		font-weight: 						500;
		margin-bottom: 						10px;
	}

	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .clientRow .clientValue {
		font-size: 							10px;
		font-weight: 						400;
	}

	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .clientRow .clientTitle {
		font-size: 							10px;
		font-weight: 						700;
		opacity: 							0.4;
		margin-bottom: 						10px;
	}

	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .clientRow .clientTitle:last-of-type {
		margin-bottom: 						0;
	}

	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .actions {
		display: 							flex;
		gap: 								10px;
	}

	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .actions > div{
		width: 								20px;
		height: 							20px;
		cursor: 							pointer;
		background-size: 					20px 20px;
		background-color: 					var(--darkBlue);
	}
	
	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .actions > div:nth-of-type(1){
		mask: 								url("../../images/activeSupplier.svg") center center no-repeat;
		mask-size:                          20px;
	}
	
	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .actions > div:nth-of-type(2){
		mask: 								url("../../images/authorize.svg") center center no-repeat;
		mask-size:                          20px;
	}
	
	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .actions > div:nth-of-type(3){
		mask: 								url("../../images/reject.svg") center center no-repeat;
		mask-size:                          20px;
	}
	
	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .actions > div:nth-of-type(4){
		mask: 								url("../../images/review.svg") center center no-repeat;
		mask-size:                          20px;
	}
	
	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .actions > div:hover{
		background: 						var(--gradient);
		opacity: 							1;
	}
	
	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .actions > .btnBgBlueTextGradient{
		font-size: 							10px;
		padding: 							5px;
		margin-left: 						auto;
	}
	
	.supplierClientsModalReviewForAuth .modal-body .mobileCientList .actions > .btnBgBlueTextGradient::after{
		content: 							"";
		width: 								15px;
		height: 							15px;
		background: 						url("../../images/arrowForwardFull.svg") center center no-repeat;
		background-size: 					15px 15px;;
	}

	.reviewForAuth .nav-tabs{
		padding-top: 						20px;
		gap: 								5px;
		padding-bottom: 					10px;
		margin-bottom: 						0;
		overflow-x: 						auto;
		width: 								100%;
	}

	.reviewForAuth .nav-tabs .nav-link{
		width: 								max-content;
		padding: 							10px;
		font-size: 							10px;
	}

	.reviewForAuth .tabs-container {
		margin-top: 						60px;
	}

	.reviewForAuth .tabs-container .send-email {
		top: 								unset;
		bottom: 							99.8%;
	}

	.reviewForAuth .actionButtons{
		justify-content: 					center;	
	}
}