/* CLIENTS*/
.clientAlerts .titleAndSearch{
	display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
	margin-bottom: 						55px;
}

.clientAlerts .titleAndSearch .searchAndSort{
	flex: 								0 0 25%;
}

.clientAlerts .titleAndSearch .searchAndSort .searchContainer input{
	padding-right: 						50px;
}

.clientAlerts .filters{
	display: 							flex;
	align-items: 						center;
	gap: 								10px;
	margin-bottom: 						10px;
}

.clientAlerts .filters .filter{
	display: 							flex;
	align-items: 						center;
	padding: 							10px 20px;
	background-color: 					#FFFFFF;
	position: 							relative;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.darkMode .clientAlerts .filters .filter{
	background-color: 					#131E29;
}

.clientAlerts .filters .filter.show{
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.clientAlerts .filters .filter.active{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.clientAlerts .filters .filter::after{
	content: 							"";
	width: 								20px;
	height: 							20px;
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size: 					15px;
	transform: 							rotate(90deg);
	margin-left: 						20px;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.clientAlerts .filters .filter.show::after{
	transform: 							rotate(270deg);
}

.clientAlerts .filters .filter > img{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-right: 						5px;
	pointer-events: 					none;
}

.clientAlerts .filters .filter > span{
	font-size: 							12px;
	pointer-events: 					none;
}

.clientAlerts .filters .filter .searchContainer{
	width: 								280px;
	background-color: 					#FFFFFF;
	padding: 							15px;
	position: 							absolute;
	top: 								105%;
	left: 								0;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
	cursor: 							default;
}

.darkMode .clientAlerts .filters .filter .searchContainer{
	background-color: 					#131E29;
}

.clientAlerts .filters .filter .searchContainer::after{
	right: 								35px;
}

.clientAlerts .filters .filter .searchContainer > input{
	background-color: 					var(--grey);
}

.darkMode .clientAlerts .filters .filter .searchContainer > input{
	background-color: 					#27343F;
}

.clientAlerts .filters .filter .filterOptions{
	width: 								max-content;
	position: 							absolute;
	top: 								105%;
	left: 								0;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	padding: 							20px;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
	cursor: 							default;
}

.darkMode .clientAlerts .filters .filter .filterOptions{
	background: 						#131E29;
}

.clientAlerts .filters .filter .filterOptions .optionsScroll{
	max-height: 						300px;
	overflow-y: 						auto;
}

/* width */
.clientAlerts .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar {
	width: 								3px;
}
  
 /* Track */
.clientAlerts .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
 }
  
/* Handle */
.clientAlerts .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.darkMode .clientAlerts .filters .filter .filterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						white;
}


.clientAlerts .filters .filter .filterOptions.show, .clientAlerts .filters .filter .searchContainer.show{
	visibility: 						visible;
	opacity: 							1;
	-webkit-text-fill-color: 			#000000;
}

.clientAlerts .filters .filter .filterOptions .title{
	font-size: 							14px;
	font-weight: 						700;
	margin-bottom: 						5px;
}

.darkMode .clientAlerts .filters .filter .filterOptions .title,
.darkMode .clientAlerts .filters .filter .filterOptions .option{
	-webkit-text-fill-color: 			white;
}

.clientAlerts .filters .filter .filterOptions .option{
	display: 							flex;
	align-items: 						center;
	padding: 							0 10px;
}

.clientAlerts .filters .filter .filterOptions.location{
	width: 								280px;
	top: 								290%;
}

.clientAlerts .filters .filter .filterOptions .option > input{
	margin-right: 						0;
	cursor: 							pointer;
}

.clientAlerts .filters .filter .filterOptions .option > label{
	flex:  								1 0 0;
	font-size: 							12px;
	opacity: 							0.8;
	cursor: 							pointer;
	padding:  							10px 0 10px 10px;
}

.clientAlerts .filters .alertActions{
	display: 							flex;
	align-items: 						center;
	margin-left: 						auto;
}

.clientAlerts .filters .alertActions .btnBgBlueTextGradient{
	max-width: 							1000px;
	padding-inline: 					15px;
	margin-left: 						auto;
	overflow: 							hidden;
	text-wrap: 							nowrap;
	margin-left: 						10px;
}

.clientAlerts .filters .alertActions .btnBgBlueTextGradient::before{
	content: 							"";
    width: 								18px;
    height: 							18px;
    margin-right: 						5px;
}

.clientAlerts .filters .alertActions .btnBgBlueTextGradient.hide{
	max-width: 							0;
	padding-inline: 					0;
	opacity: 							0;
	visibility: 						hidden;
	margin-left: 						0;
}

.clientAlerts .filters .alertActions .btnBgBlueTextGradient:nth-of-type(1):before{
	background: 						url("../../images/readAll.svg") center center no-repeat;
	background-size: 					18px 18px;
}

.clientAlerts .filters .alertActions .btnBgBlueTextGradient:nth-of-type(2):before{
	background: 						url("../../images/markAsRead.svg") center center no-repeat;
    background-size: 					18px 18px;
}

.clientAlerts .filters .alertActions .btnBgBlueTextGradient:nth-of-type(3):before{
	background: 						url("../../images/markAsUnread.svg") center center no-repeat;
    background-size: 					18px 18px;
}

.clientAlerts .filters .alertActions .btnBgBlueTextGradient:nth-of-type(4):before{
	background: 						url("../../images/delete.svg") center center no-repeat;
    background-size: 					18px 18px;
	margin: 							0;
}

.clientAlerts .filters .filter .filterOptions .optionScroll{
	max-height: 						220px;
	overflow-y: 						auto;
}

.clientAlerts .filters .filter .filterOptions .optionScroll::-webkit-scrollbar {
	width: 								3px;
}

.clientAlerts .filters .filter .filterOptions .optionScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
}
 
.clientAlerts .filters .filter .filterOptions .optionScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.clientAlerts .filters .filter .filterOptions .actions{
	display: 							flex;
	gap: 								10px;
	justify-content: 					center;
	margin-top: 						15px;
}

.clientAlerts .filters .filter .filterOptions .actions > button {
	border: 							unset;
	background-color: 					transparent;
	padding: 							5px 15px;
	font-size: 							12px;
}

.darkMode .clientAlerts .filters .filter .filterOptions .actions > button:first-of-type {
	-webkit-text-fill-color: 			white;
}

.clientAlerts .filters .filter .filterOptions .actions > button:last-of-type {
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.clientAlerts .filters > .resetAll,
.clientAlerts > .resetAll {
    font-size:                          14px;
    font-weight:                        700;
	background: 						var(--gradient) text;
    -webkit-text-fill-color:            transparent;
	opacity: 							0;
	visibility: 						hidden;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.clientAlerts .filters > .resetAll.show,
.clientAlerts > .resetAll.show {
	opacity: 							1;
	visibility: 						visible;
}

.clientAlerts .filters > .resetAll:hover {
	opacity:                            0.5;
}

.clientAlerts .tableClientAlerts{
	margin-bottom: 						30px;
}

.clientAlerts .tableClientAlerts thead th:nth-of-type(1){
	width: 								70px;
}

.clientAlerts .tableClientAlerts tbody td .infoContainer > input {
	margin: 							0 auto;
}

.clientAlerts .tableClientAlerts tbody td:nth-of-type(2) .infoContainer > span {
	opacity: 							1;
	/* cursor: 							pointer; */
}

.clientAlerts .tableClientAlerts tbody td:nth-of-type(2) .infoContainer > span.unreaded::before {
	content: 							"";
    width: 								5px;
    height: 							5px;
    border-radius: 						50%;
    margin-right: 						5px;
	background: 						var(--red);
}

/* .clientAlerts .tableClientAlerts tbody td:nth-of-type(2) .infoContainer > span:hover {
	background:							var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
} */

.clientAlerts .addAndPagination{
	display: 							flex;
	align-items: 						center;
	justify-content: 					flex-end;
	margin-bottom: 						10px;
}

.clientAlerts .addAndPagination .pagination{
	display: 							flex;
	align-items: 						center;
	gap: 								5px;
}

.clientAlerts .addAndPagination .number{
	border: 							unset;
	font-size: 							12px;
	padding: 							2px 7.5px;
	opacity: 							0.5;
}

.clientAlerts .addAndPagination .number.active{
	opacity: 							1;
	font-weight: 						900;
	background:  						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.clientAlerts .addAndPagination .nextPage{
	width: 								13px;
	height: 							13px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
}

.clientAlerts .tableCount{
	width: 								fit-content;
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
	margin: 							0 0 55px auto;
}

@media (max-width: 991px){
	/* CLIENTS*/
	.clientAlerts .titleAndSearch{
		justify-content: 					unset;
		flex-flow: 							column nowrap;
		margin-bottom: 						20px;
	}

	.clientAlerts .titleAndSearch .title{
		margin-bottom: 						30px;
	}

	.clientAlerts .titleAndSearch .searchAndSort{
		width: 								100%;
		flex: 								0 0 100%;
		display: 							flex;
		gap: 								40px;
	}

	.clientAlerts .titleAndSearch .searchContainer{
		flex: 								1 0 0;
	}

	.clientAlerts .titleAndSearch .searchContainer input{
		padding-right: 						35px;
		height: 							100%;
	}

	.clientAlerts .titleAndSearch .searchAndSort .sortMobile{
		border: 							unset;
		background-color: 					#FFFFFF;
		padding: 							10px;
		font-size: 							12px;
		color: 								rgba(0, 0, 0, 0.40);
		font-weight: 						300;
		display: 							flex;
		gap: 								40px;
		align-items: 						center;
	}

	.clientAlerts .titleAndSearch .searchAndSort .sortMobile::after{
		content: 							"";
		width: 								20px;
		height: 							20px;
		background: 						url("../../images/arrowForward.svg") center center no-repeat;
		background-size: 					15px;
		transform: 							rotate(90deg);
	}

	.clientAlerts .titleAndSearch .searchAndSort .sortDataContainer{
		width: 								90vw;
		height: 							max-content;
		position: 							fixed;
		top: 								0;
		left: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto;
		border-radius: 						35px;
		padding: 							50px 35px;
		background: 						var(--grey);
		box-shadow: 						0px -30px 100px 0px rgba(4, 26, 57, 0.55);
		z-index: 							999;
		opacity: 							0;
		visibility: 						hidden;
		transition: 						all 0.3s ease-in-out;
	}

	.clientAlerts .titleAndSearch .searchAndSort .sortDataContainer::after{
		content: 							"";
		width: 								25px;
		height: 							25px;
		background: 						url("../../images/close.svg") center center no-repeat;
		background-size: 					25px;
		position: 							absolute;
		top: 								25px;
		right: 								25px;
	}

	.darkMode .clientAlerts .titleAndSearch .searchAndSort .sortDataContainer::after{
		mask: 								url("../../images/close.svg") center center no-repeat;
		mask-size: 							25px;
		background: 						white;
	}

	.clientAlerts .titleAndSearch .searchAndSort .sortDataContainer.show{
		opacity: 							1;
		visibility: 						visible;
	}

	.clientAlerts .titleAndSearch .searchAndSort .sortDataContainer > .title{
		font-size: 							16px;
		font-weight: 						700;
	}

	.clientAlerts .titleAndSearch .searchAndSort .sortDataContainer > span{
		font-size: 							12px;
		margin-bottom: 						20px;
	}

	.clientAlerts .titleAndSearch .searchAndSort .sortDataContainer > span:last-of-type{
		margin-bottom: 						0;
	}

	.clientAlerts .filters{
		display: 							flex;
		gap: 								10px;
		overflow-x: 						auto;
	}

	/* width */
	.clientAlerts .filters::-webkit-scrollbar {
		display: 							none;
	}
	
	.clientAlerts .filters .filter{
		padding: 							10px;
		position: 							static;
	}

	.clientAlerts .filters .filter > span{
		font-size: 							10px;
	}

	.clientAlerts .filters .filter .searchContainer{
		width: 								90vw;
		height: 							fit-content;
		padding: 							15px;
		position: 							fixed;
		top: 								unset;
		right: 								0;
		bottom: 							30px;
		margin: 							0 auto;
	}

	.clientAlerts .filters .filter .filterOptions{
		width: 								90vw;
		height: 							max-content;
		position: 							fixed;
		top: 								0;
		left: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto;
		border-radius: 						35px;
		padding: 							50px 35px;

	}
	.clientAlerts .filters .filter .filterOptions::after{
		content: 							"";
		width: 								25px;
		height: 							25px;
		background: 						url("../../images/close.svg") center center no-repeat;
		background-size: 					25px;
		position: 							absolute;
		top: 								25px;
		right: 								25px;
	}
	.darkMode .clientAlerts .filters .filter .filterOptions::after{
		mask: 								url("../../images/close.svg") center center no-repeat;
		mask-size: 							25px;
		background: 						white;
	}

	.clientAlerts .filters .filter .filterOptions .imgMenu{
		width: 								20px;
		height: 							20px;
		object-fit: 						contain;
		object-position: 					center center;
		margin-right: 						10px;
		display: 							inline-block;
	}

	.clientAlerts .filters .filter .filterOptions .title{
		display: 							inline-block;
		font-size: 							16px;
		margin-bottom: 						30px;
	}

	.clientAlerts .filters .filter .filterOptions .option{
		padding: 							0;
	}

	.clientAlerts .filters .filter .filterOptions .option > input{
		order: 								2;
		margin-right: 						10px;
	}

	.clientAlerts .filters .filter .filterOptions .option > label{
		order: 								1;
		padding:  							10px 10px 10px 0;
	}

	.clientAlerts .filters .filter .filterOptions .actions{
		gap: 								0;
		justify-content: 					space-between;
		margin-top: 						50px;
	}

	.clientAlerts .filters .filter .filterOptions .actions > button{
		font-size: 							14px;
	}

	.clientAlerts .filters .alertActions{
		display: 							none;
	}

	.clientAlerts .filters .resetAll,
	.clientAlerts .tableClientAlerts{
		display: 							none;
	}

	.clientAlerts > .resetAll {
        max-height:                         0;
        text-align:                         center;
    }

    .clientAlerts > .resetAll.show {
        max-height:                         50px;
    }

	.clientAlerts .clientAlertsList{
		display: 							flex;
		flex-flow: 							column nowrap;
		margin: 							25px 0;
	}

	.clientAlerts .clientAlertsList .clientRow{
		position: 							relative;
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
		padding: 							15px 25px 25px 35px;
	}

	.clientAlerts .clientAlertsList .clientRow > input {
		position: 							absolute;
		top: 								0;
		bottom: 							0;
		left: 								5px;
		margin: 							auto 0;
	}

	.clientAlerts .clientAlertsList .clientRow:last-of-type{
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.clientAlerts .clientAlertsList .clientRow .clientName{
		display: 							flex;
		align-items: 						center;
		font-weight: 						500;
		margin-bottom: 						20px;
	}

	.clientAlerts .clientAlertsList .clientRow .clientName.unreaded::before {
		content: 							"";
		width: 								5px;
		height: 							5px;
		border-radius: 						50%;
		margin-right: 						5px;
		background: 						var(--red);
	}

	.clientAlerts .clientAlertsList .clientRow .clientInformation {
		width: 								100%;
		display:  							grid;
		justify-content: 					space-between;
		column-gap: 						30px;
		grid-template-areas: 				
			"A B"
			"C D"
			"E E"
			"F F";
	}

	.clientAlerts .clientAlertsList .clientRow .clientInformation .clientValue{
		font-size: 							12px;
	}

	.clientAlerts .clientAlertsList .clientRow .clientInformation .clientTitle{
		font-size: 							10px;
		font-weight: 						700;
		opacity: 							0.4;
	}

	.clientAlerts .clientAlertsList .clientRow .clientInformation > span:nth-of-type(1){
		grid-area: 							A;
	}

	.clientAlerts .clientAlertsList .clientRow .clientInformation > span:nth-of-type(2){
		grid-area: 							C;
		margin-bottom:						10px;
	}

	.clientAlerts .clientAlertsList .clientRow .clientInformation > span:nth-of-type(3){
		grid-area: 							B;
	}

	.clientAlerts .clientAlertsList .clientRow .clientInformation > span:nth-of-type(4){
		grid-area: 							D;
		margin-bottom: 						10px;
	}
	
	.clientAlerts .clientAlertsList .clientRow .clientInformation > span:nth-of-type(5){
		grid-area: 							E;
	}

	.clientAlerts .clientAlertsList .clientRow .clientInformation > span:nth-of-type(6){
		grid-area: 							F;
		margin-bottom:						10px;
	}

	.clientAlerts .addAndPagination{
		padding: 							0 20px;
	}

	.clientAlerts .tableCount{
		font-size: 							10px;
		margin: 							0 25px 25px auto;
	}

	.clientAlerts .alertActions{
		display: 							flex;
		flex-flow: 							column nowrap;
		margin-bottom: 						40px;
		padding-inline: 					23px;
	}

	.clientAlerts .alertActions .btnBgBlueTextGradient{
		flex: 								1 0 0;
		padding-inline: 					15px;
		overflow: 							hidden;
		text-wrap: 							nowrap;
		margin-bottom: 						10px;
	}
	
	.clientAlerts .alertActions .btnBgBlueTextGradient::before{
		content: 							"";
		width: 								18px;
		height: 							18px;
		margin-right: 						5px;
	}
	
	.clientAlerts .alertActions .btnBgBlueTextGradient.hide{
		max-height: 						0;
		padding: 							0;
		opacity: 							0;
		visibility: 						hidden;
		margin-bottom: 						0;
	}
	
	.clientAlerts .alertActions .btnBgBlueTextGradient:nth-of-type(1):before{
		background: 						url("../../images/readAll.svg") center center no-repeat;
		background-size: 					18px 18px;
	}
	
	.clientAlerts .alertActions .btnBgBlueTextGradient:nth-of-type(2):before{
		background: 						url("../../images/markAsRead.svg") center center no-repeat;
		background-size: 					18px 18px;
	}
	
	.clientAlerts .alertActions .btnBgBlueTextGradient:nth-of-type(3):before{
		background: 						url("../../images/markAsUnread.svg") center center no-repeat;
		background-size: 					18px 18px;
	}
	
	.clientAlerts .alertActions .btnBgBlueTextGradient:nth-of-type(4):before{
		background: 						url("../../images/delete.svg") center center no-repeat;
		background-size: 					18px 18px;
		margin: 							0;
	}
}