/* STAFF */
.clientStaff .clientTableStaff{
	margin-bottom: 						30px;
}

.clientStaff .titleClient{
    font-weight: 						700;
    padding: 							18px 30px;
    background: 						#FFFFFF;
    box-shadow: 						0px 4px 30px 0px rgba(35, 107, 216, 0.08);
}

.clientStaff .clientTableStaff thead{
	background-color:					rgba(4, 26, 57, 0.03);
	box-shadow: 						none;
}

.clientStaff .clientTableStaff thead th:nth-of-type(1){
	width: 								85px;
}

.clientStaff .clientTableStaff thead th:nth-of-type(2){
	width: 								30%;
}

.clientStaff .clientTableStaff .profilePhoto{
	width: 								35px;
    height: 							35px;
    object-fit: 						cover;
    object-position: 					center center;
    border-radius: 						100px;
    border: 							3px solid transparent;
    background: 						var(--gradient) center center no-repeat;
    background-size: 					35px 35px;
    background-clip: 					border-box;
	margin:								0 auto;
}

.clientStaff .clientTableStaff .optionsTable{
    position:                           absolute;
    right:                              10px;
    top:                                0;
    bottom:                             0;
    margin:                             auto 0;
	display: 							flex;
	align-items: 						center;
	gap: 								15px;
}

.clientStaff .clientTableStaff .optionsTable .viewStaff{
	width: 								20px;
    height: 							20px;
    object-fit: 						contain;
    object-position: 					center center;
	cursor: 							pointer;
}

.clientStaff .clientTableStaff .optionsTable .deleteStaff{
	width: 								15px;
    height: 							15px;
    object-fit: 						contain;
    object-position: 					center center;
	cursor: 							pointer;
}

.clientStaff .btnBgBlueTextGradient{
	padding: 							10px 15px;
}

.clientStaff .btnBgBlueTextGradient::before{
	content: 							"";
	width: 								20px;
	height: 							20px;
	background: 						url("../../images/add.svg") center center no-repeat;
	background-size: 					20px 20px;
	margin-right: 						5px;
}

.clientStaff .tableContainer .btnBgBlueTextGradient{
	margin-bottom: 						20px;
}

.staffModal .modal-content{
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.staffModal .modal-body{
	padding: 							90px 85px 80px;
}

.staffModal .modal-body .closeModal{
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								30px;
	right: 								30px;
	cursor: 							pointer;
}

/* MODAL VIEW INFORMATION */
.staffModal .modal-body .qrCode{
	display: 							block;
	margin: 							0 auto 50px;
}

.staffModal .modal-body .staffInformation{
	display: 							flex;
	align-items: 						center;
	gap: 								110px;
	margin-bottom: 						50px;
}

.staffModal .modal-body .profilePhotoModal{
	width: 								190px;
    height: 							190px;
    object-fit: 						cover;
    object-position: 					center center;
    border-radius: 						100px;
    border: 							5px solid transparent;
    background: 						var(--gradient) center center no-repeat;
    background-size: 					190px 190px;
    background-clip: 					border-box;
}

.staffModal .modal-body .textInfo{
	font-size: 							10px;
	opacity: 							0.5;
	font-weight: 						700;
}

.staffModal .modal-body .textName{
	font-size: 							20px;
	font-weight: 						700;
	margin-top: 						10px;
}

.staffModal .modal-body .dataContainer{
	display: 							grid;
	grid-template-columns: 				repeat(2, 1fr);
	grid-template-rows: 				repeat(4, auto);
	column-gap: 						90px;
	margin-top: 						25px;
}

.staffModal .modal-body .dataContainer .textValue:nth-of-type(1){
	grid-column-start: 					1;
	grid-row-start: 					1;
}

.staffModal .modal-body .dataContainer .textInfo:nth-of-type(2){
	grid-column-start: 					1;
	grid-row-start: 					2;
}

.staffModal .modal-body .dataContainer .textInfo:nth-of-type(3){
	grid-column-start: 					2;
	grid-row-start: 					1;
	margin-bottom: 						15px;
}

.staffModal .modal-body .dataContainer .textInfo:nth-of-type(4){
	grid-column-start: 					2;
	grid-row-start: 					2;
	margin-bottom: 						15px;
}

.staffModal .modal-body .dataContainer .textInfo:nth-of-type(5){
	grid-column-start: 					1;
	grid-row-start: 					3;
}

.staffModal .modal-body .dataContainer .textInfo:nth-of-type(6){
	grid-column-start: 					1;
	grid-row-start: 					4;
}

.staffModal .modal-body .dataContainer .textInfo:nth-of-type(7){
	grid-column-start: 					2;
	grid-row-start: 					3;
}

.staffModal .modal-body .dataContainer .textInfo:nth-of-type(8){
	grid-column-start: 					2;
	grid-row-start: 					4;
}

.staffModal .modal-body .downloadQR{
	width: 								fit-content;
	font-size: 							12px;
	font-weight: 						700;
	margin-bottom: 						5px;
	margin-left: 						auto;
}

.staffModal .modal-body .downloadQR > label{
	font-weight: 						300;
	opacity: 							0.5;
}

.staffModal .modal-body .btnBgBlueTextGradient{
	margin-left: 						auto;
}

/* MODAL ADD STAFF */
.staffModal .modal-body .addStaff{
	display: 							flex;
	flex-flow: 							row wrap;
	column-gap: 						10px;
}

.staffModal .modal-body .title{
	flex: 								0 0 100%;
	margin-bottom: 						35px;
}

.staffModal .modal-body .inputContainer{
	margin-bottom: 						20px;
}

.staffModal .modal-body .inputContainer{
	width: 								calc(100% / 3 - (10px * 2 / 3));
}

.staffModal .modal-body .inputContainer:first-of-type {
	width: 								100%;
}

.staffModal .modal-body .actionButtons{
	flex: 								0 0 100%;
	display: 							flex;
	align-items: 						center;
	justify-content: 					flex-end;
	gap: 								10px;
}

.staffModal .modal-body .actionButtons > .btnBgBlueTextGradient:first-of-type{
	background: 						transparent;
	-webkit-text-fill-color: 			#000000;
}

@media (max-width: 992px){
	.clientStaff .clientTableStaff{
		display: 							none;
	}

	.clientStaff .clientStaffsContainer{
		margin-bottom: 						25px;
	}

	.clientStaff .clientStaffsContainer .staffInformation{
		width: 								100%;
		padding: 							25px 20px;
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
	}
	
	.clientStaff .clientStaffsContainer .staffInformation span{
		font-weight: 						700;
		font-size: 							10px;
		opacity: 							0.4;
	}

	.clientStaff .clientStaffsContainer .staffInformation:last-of-type{
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.clientStaff .clientStaffsContainer .staffInformation .titleAndPhoto{
		position: 							relative;
		margin-bottom: 						15px;
	}

	.clientStaff .clientStaffsContainer .staffInformation .titleAndPhoto > h5{
		font-weight: 						500;
		font-size: 							14px;
	}

	.clientStaff .clientStaffsContainer .staffInformation .titleAndPhoto > img{
		width: 								35px;
		height: 							35px;
		object-fit: 						cover;
		object-position: 					center center;
		border-radius: 						50%;
		border: 							2px solid transparent;
		background: 						var(--gradient) center center no-repeat;
		background-size: 					35px 35px;
		position: 							absolute;
		top: 								0;
		right: 								0;
		bottom: 							0;
		margin: 							auto 0;
	}

	.clientStaff .clientStaffsContainer .staffInformation .imssAndRfc{
		display: 							flex;
		align-items: 						center;
		justify-content: 					space-between;
		margin-bottom: 						15px;

	}

	.clientStaff .clientStaffsContainer .staffInformation .imssAndRfc h5{
		font-size: 							12px;
	}

	.clientStaff .clientStaffsContainer .staffInformation .inputContainer span{
		margin-top: 						5px;
	}

	.clientStaff .btnBgBlueTextGradient{
		margin: 							5px auto 15px;
	}
	
	.staffModal .modal-content{
		border-radius: 						35px;
	}
	
	.staffModal .modal-body{
		padding: 							55px 35px;
	}
	
	.staffModal .modal-body .closeModal{
		top: 								20px;
		right: 								20px;
	}
	
	/* MODAL VIEW INFORMATION */
	.staffModal .modal-body .qrCode{
		margin-bottom: 						20px;
	}

	.staffModal .modal-body .staffInformation{
		display: 							flex;
		align-items: 						center;
		gap: 								110px;
		margin-bottom: 						25px;
	}
	
	.staffModal .modal-body .staffInformation > .profilePhotoModal{
		display: 							none;
	}
	
	.staffModal .modal-body .staffInformation > .employeeInformation{
		width: 								100%;
	}

	.staffModal .modal-body .staffInformation > .employeeInformation > .textInfo{
		text-align:  						center;
		font-weight: 						300;
		margin-bottom: 						20px;
	}
	
	.staffModal .modal-body .staffInformation .employeeInformation .titlePhotoMobile {
		position: 							relative;
		padding-left:  						60px;
	}

	.staffModal .modal-body .staffInformation .employeeInformation .titlePhotoMobile .textName{
		font-size: 							16px;
		margin-top: 						0;
	}

	.staffModal .modal-body .staffInformation .employeeInformation .titlePhotoMobile .profilePhotoModal{
		width: 								50px;
		height: 							50px;
		position: 							absolute;
		top: 								0;
		bottom: 							0;
		left: 								0;
		margin: 							auto 0;
	}
	
	.staffModal .modal-body .staffInformation .employeeInformation .dataContainer{
		grid-template-columns: 				repeat(2, max-content);
		grid-template-rows: 				repeat(4, auto);
		column-gap: 						0;
		justify-content: 					space-between;
	}

	.staffModal .modal-body .staffInformation .employeeInformation .dataContainer .textValue{
		font-size: 							12px;
	}
	
	.staffModal .modal-body .btnBgWhiteTextBlack{
		width: 								100%;
		font-size: 							12px;
		margin: 							0 auto;
	}

	.staffModal .modal-body .downloadQR{
		margin: 							0 auto 5px;
	}
	
	.staffModal .modal-body .btnBgBlueTextGradient{
		width: 								100%;
		margin: 							0 0 5px;
	}
	
	/* MODAL ADD STAFF */
	.staffModal .modal-body .addStaff{
		display: 							flex;
		flex-flow: 							row wrap;
		column-gap: 						10px;
	}
	
	.staffModal .modal-body .title{
		font-size: 							16px;
		font-weight: 						700;
		margin-bottom: 						20px;
	}
	
	.staffModal .modal-body .inputContainer{
		flex: 								0 0 100%;
	}
	
	.staffModal .modal-body .actionButtons{
		flex-flow: 							row wrap;
		gap: 								15px;
		margin-top: 						30px;
	}
	
	.staffModal .modal-body .actionButtons > .btnBgBlueTextGradient:first-of-type{
		flex:  								0 0 100%;
		order: 								2;
	}

	.staffModal .modal-body .actionButtons > .btnBgBlueTextGradient:last-of-type{
		flex:  								0 0 100%;
		order: 								1;
	}
}