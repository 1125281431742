/* INFORMATION COMPLIANCE */
.qualitySurvey .inputSections{
	display: 							flex;
	flex-flow: 							row wrap;
	padding-block: 						40px;
	column-gap: 						10px;
	border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
}

.qualitySurvey .titleAndActions{
	width: 								100%;
	display: 							flex;
	align-items: 						center;
	gap: 								10px;
	position: 							relative;
	margin-bottom: 						15px;
}

.qualitySurvey .titleAndActions .titleSection,
.qualitySurvey .titleAndActions .titleQuestion{
	position: 							relative;
	flex:  								1 0 0;	
	font-weight: 						700;
	padding: 							20px 30px;
	background-color: 					#FFFFFF;
	box-shadow: 						0px 4px 30px 0px rgba(35, 107, 216, 0.08);
}

.darkMode .qualitySurvey .titleAndActions .titleSection,
.darkMode .qualitySurvey .titleAndActions .titleQuestion{
	background-color: 					#181E24;
}

.qualitySurvey .titleAndActions .titleQuestion{
	background-color: 					rgba(4, 26, 57, 0.03);
	box-shadow: 						unset;
}

.qualitySurvey .titleAndActions .title .compliantSupplier{
	height: 							fit-content;
	font-size: 							12px;
	font-weight: 						400;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
	right: 								20px;
}

.qualitySurvey .titleAndActions .compliant{
	position: 							relative;
	flex:  								0 0 18%;
}

.qualitySurvey .titleAndActions .compliant .dropdown-toggle.newAlone:hover, 
.qualitySurvey .titleAndActions .compliant .dropdown-toggle.newAlone:focus{
	color: 								#00A3FF;
	opacity: 							1;
}

.qualitySurvey .titleAndActions .compliant .dropdown-toggle.failedAlone:hover, 
.qualitySurvey .titleAndActions .compliant .dropdown-toggle.failedAlone:focus{
	color: 								var(--red);
	opacity: 							1;
}

.qualitySurvey .titleAndActions .compliant .dropdown-menu{
	padding: 							0;
}

.qualitySurvey .titleAndActions .compliant .dropdown-menu .dropdown-item{
	padding: 							10px;
}

.qualitySurvey .titleAndActions .compliant .dropdown-menu .dropdown-item:first-of-type:hover{
	color: 								#FFFFFF;
	background-color: 					var(--darkBlue);
}

.qualitySurvey .titleAndActions .compliant .dropdown-menu .dropdown-item:last-of-type:hover{
	color: 								#FFFFFF;
	background-color: 					var(--red);
}

.qualitySurvey .titleAndActions .comment{
	width: 								18px;
	height: 							18px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
	margin-left: 						auto;
}

.darkMode .qualitySurvey .titleAndActions .comment{
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.qualitySurvey .titleAndActions .commentContainer{
	position: 							absolute;
	top: 								100%;
	right: 								0;
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.20);
	opacity: 							0.92;
	padding: 							45px 65px 35px;
	visibility: 						hidden;
	opacity: 							0;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
}

.qualitySurvey .titleAndActions .commentContainer.showMenu{
	visibility: 						visible;
	opacity: 							1;
}

.qualitySurvey .titleAndActions .commentContainer > img{
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								10px;
	right: 								10px;
	cursor: 							pointer;
}

.qualitySurvey .titleAndActions .commentContainer > h5{
	font-weight: 						700;
	margin-bottom: 						20px;
}

.qualitySurvey .titleAndActions .commentContainer > textarea{
	width: 								450px;
	padding: 							20px;
	margin-bottom: 						20px;
}

.qualitySurvey .titleAndActions .commentContainer > .actions{
	display: 							flex;
	gap: 								10px;
	justify-content: 					flex-end;
}

.qualitySurvey .titleAndActions .commentContainer > .actions .btnBgBlueTextGradient:first-of-type{
	-webkit-text-fill-color: 			unset;
	background-color: 					transparent;
}

.qualitySurvey .inputSections .inputContainer{
	max-width: 							calc(100% / 3 - (10px * 2 / 3));
	flex: 								0 0 calc(100% / 3 - (10px * 2 / 3));
	margin-bottom: 						15px;
}

.qualitySurvey .inputSections .inputContainer.moreWidth{
	max-width: 							100%;
	flex: 								1 0 100%;
}

.qualitySurvey .inputSections .inputContainer .deleteIcon{
	width: 								15px;
	height: 							15px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							pointer;
	margin: 							0 auto;
}

.qualitySurvey .inputSections .inputContainer .arrowMenu{
	width: 								15px;
	height: 							15px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	right: 								10px;
	bottom: 							12px;
	transform: 							rotate(90deg);
	pointer-events: 					none;
}

.qualitySurvey .inputSections .inputContainer .certificationMenu{
	position: 							absolute;
	left: 								0;
	top: 								105%;
	min-width: 							100%;
	max-height: 						170px;
	background: 						#FFFFFF;
    box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	z-index: 							9;
	overflow-y: 						auto;
	opacity: 							0;
	visibility: 						hidden;
	transition: 						all 0.3s ease-in-out;
}

.qualitySurvey .inputSections .inputContainer .certificationMenu.show{
	opacity: 							1;
	visibility: 						visible;
}

.qualitySurvey .inputSections .inputContainer .certificationMenu::-webkit-scrollbar {
    width: 								3px;
}

.qualitySurvey .inputSections .inputContainer .certificationMenu::-webkit-scrollbar-thumb {
    background: 						var(--darkBlue);
}

.qualitySurvey .inputSections .inputContainer .certificationMenu::-webkit-scrollbar-track {
    background: 						rgba(0, 0, 0, 0.05);
}

.qualitySurvey .inputSections .inputContainer .certificationMenu .optionMenu{
	font-size: 							12px;
	padding: 							5px 20px;
	cursor: 							pointer;
	transition: 						all 0.3s ease-in-out;
}

.darkMode .qualitySurvey .inputSections .inputContainer .certificationMenu .optionMenu{
	background: #27343F;
	color: white;
}

.qualitySurvey .inputSections .inputContainer .certificationMenu .optionMenu:hover{
	background-color: 					rgba(0, 0, 0, 0.05);
}

.qualitySurvey .inputSections .inputContainer .fileContainer{
	display: 							flex;
	gap: 								10px;
	align-items: 						center;
	position: 							relative;
	padding-right: 						20px;
}

.qualitySurvey .inputSections .inputContainer .fileContainer .downloadFile{
	padding: 							10px 20px;
	font-size: 							12px;
}

.qualitySurvey .inputSections .inputContainer .fileContainer .fileName{
	font-size: 							12px;
	font-weight: 						300;
	opacity: 							0.5;
}

.qualitySurvey .inputSections .inputContainer .fileContainer .close{
	width: 								15px;
	height: 							15px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
	right: 								0;
	cursor: 							pointer;
}

.qualitySurvey > .btnBgBlueTextGradient{
	margin-block: 						40px;
}

@media(max-width: 991px){
	/* INFORMATION COMPLIANCE */
	.qualitySurvey .inputSections{
		padding-block: 						20px;
		column-gap: 						0;
	}

	.qualitySurvey .titleAndActions{
		flex-flow: 							row wrap;
		column-gap: 						30px;
	}

	.qualitySurvey .titleAndActions .title{
		padding: 							10px 20px;
	}

	.qualitySurvey .titleAndActions .title .compliantSupplier{
		font-size: 							0;
	}

	.qualitySurvey .titleAndActions .title .compliantSupplier::after{
		content: 							"\025cf";
		font-size: 							14px;
	}

	.qualitySurvey .titleAndActions .compliant{
		position: 							relative;
		flex:  								1 0 80%;
		padding-left: 						15px;
	}

	.qualitySurvey .titleAndActions .compliant .dropdown-toggle.newAlone{
		color: 								#00A3FF;
	}

	.qualitySurvey .titleAndActions .compliant .dropdown-toggle.failedAlone{
		color: 								var(--red);
	}

	.qualitySurvey .titleAndActions .comment{
		margin: 							0 15px 0 0;
	}

	.qualitySurvey .titleAndActions .commentContainer{
		width: 								95%;
		position: 							absolute;
		top: 								100%;
		left: 								0;
		margin: 							0 auto;
		padding: 							35px 25px;
	}
	
	.qualitySurvey .titleAndActions .commentContainer > img{
		width: 								20px;
		height: 							20px;
	}
	
	.qualitySurvey .titleAndActions .commentContainer > h5{
		margin-bottom: 						10px;
	}
	
	.qualitySurvey .titleAndActions .commentContainer > textarea{
		width: 								100%;
		padding: 							10px;
		margin-bottom: 						10px;
	}
	
	.qualitySurvey .titleAndActions .commentContainer > .actions{
		justify-content: 					center;
	}
	
	.qualitySurvey .inputSections .inputContainer{
		max-width: 							100%;
		flex: 								0 0 100%;
		margin-bottom: 						20px;
	}

	.qualitySurvey .inputSections .inputContainer:last-of-type{
		margin: 							0
	}

	.qualitySurvey .inputSections .inputContainer .deleteIcon{
		position: 							absolute;
		right: 								5px;
		top: 								5px;
	}
	
	.qualitySurvey > .btnBgBlueTextGradient{
		width: 								100%;
		margin: 							20px auto;
	}
}