/* MAINTENANCE */
.maintenance {
	min-height: 						100vh;
	position: 							relative;
	background-color: 					var(--darkBlue);
}

.maintenance .world{
	width: 								50%;
	height: 							100%;
	object-fit: 						cover;
	object-position: 					center center;
	position: 							absolute;
	top: 								0;
	left: 								0;
	background-blend-mode: 				soft-light, normal;
	mix-blend-mode: 					screen;
}

.maintenance .row{
	height: 							100vh;
	align-items: 						center;
}

.maintenance .logo{
	height: 							90px;
	object-fit: 						contain;
	object-position: 					center center;
	margin: 							0 auto 0;
}

.maintenance h2{
	width: 								fit-content;
	color: 								#FFFFFF;
	font-weight: 						500;
	margin: 							0 auto;
	font-family: 						"Inter", sans-serif;
	margin-bottom: 						10px;
}

.maintenance span{
	width: 								fit-content;
	font-weight: 						500;
	margin: 							0 auto;
	font-family: 						"Inter", sans-serif;
	background: 						var(--gradient);
	background-clip: 					text;
	-webkit-background-clip: 			text;
	-webkit-text-fill-color: 			transparent;
}

@media (max-width: 991px){
	/* MAINTENANCE */
	.maintenance .world{
		width: 								100%;
		height: 							auto;
		position: 							static;
		margin-top: 						20px;
	}

	.maintenance .row{
		align-items: 						start;
	}

	.maintenance .logo{
		height: 							45px;
		object-fit: 						contain;
		object-position: 					center center;
		margin: 							0 auto 0;
	}

	.maintenance h2{
		text-align: 						center;
		font-size: 							24px;
	}
	
	.maintenance span{
		font-size: 							14px;
	}
}