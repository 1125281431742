/* REGISTER CLIENT */
.editClient {
	margin-bottom: 						130px;
}

.editClient .titleAndBack {
	height: 							40px;
	display: 							flex;
	gap: 								15px;
	align-items: 						center;
	margin-bottom: 						35px;
}

.editClient .titleAndBack .goBack{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	transform: 							rotate(180deg);
	cursor: 							pointer;
}

.editClient .clientQuickActions{
	display: 							flex;
	flex-flow: 							row wrap;
	gap: 								10px;
	margin-bottom: 						20px;
}

.editClient .clientQuickActions > button{
	padding-inline: 					15px;
}

.editClient .titleHeader{
	font-weight: 						700;
	padding: 							20px;
	background: 						#FFFFFF;
    box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.08);
}

.editClient.darkMode .titleHeader{
	background: 						#131E29;
}

.editClient .titleHeader:first-of-type{
	margin-bottom: 						15px;
}

.editClient .inputSections{
	display: 							flex;
	flex-flow: 							row wrap;
	margin-bottom: 						100px;
	gap: 								10px;
}

.editClient .inputSections .inputContainer{
	flex: 								0 1 calc(100% / 3 - (10px * 2 / 3));
	max-width: 							calc(100% / 3 - (10px * 2 / 3));
}

.editClient .inputSections .inputContainer input:disabled {
	cursor: 							not-allowed;
	background-color: 					rgba(0, 0, 0, 0.1);
	opacity: 							0.5;
}

.darkMode.editClient .inputSections .inputContainer input:disabled {
	cursor: 							not-allowed;
	background-color: 					rgba(0, 0, 0, 0.2);
	opacity: 							0.5;
}

.editClient .inputSections .inputContainer.fullWidth{
	flex: 								0 0 100%;
	max-width: 							unset;
}

.editClient .sectionTitle{
	width: 								100%;
    font-weight: 						700;
    padding: 							20px 0 0;
}

.editClient .contactInformationRow{
	flex: 								0 0 100%;
	display: 							flex;
	gap: 								10px;
}

.editClient .contactInformationRow .deleteIcon{
	width: 								15px;
	height: 							15px;
	object-fit: 						contain;
	object-position: 					center center;
	margin: 							auto 0;
	cursor: 							pointer;
}

.editClient .entityTable{
	margin-bottom: 						20px;
}

.editClient .entityTable thead{
	background-color: 					rgba(4, 26, 57, 0.03);
	box-shadow: 						unset;
}

.editClient .entityTable thead th:nth-of-type(1){
	width: 								70px;
}

.editClient .entityTable tbody td .moreDots{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	right: 								10px;
	margin: 							auto 0;
	cursor: 							pointer;
}

.darkMode .editClient .entityTable tbody td .moreDots{
	filter: 							invert(100%) sepia(0%) saturate(0%) hue-rotate(100deg);
}

.editClient .entityTable tbody td .menuOptions{
	width: 								max-content;
	padding: 							10px 0;
	position: 							absolute;
	top: 								200%;
	right: 								0;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	z-index: 							999;
	visibility: 						hidden;
	opacity: 							0;
	transition: 						all 0.3s ease-in-out;
}

.editClient .entityTable tbody td .menuOptions::after{
	content: 							"";
	width: 								0;
	height: 							0;
	position: 							absolute;
	bottom: 							100%;
	right: 								5px;
	margin: 							0 auto;
	border-left: 						15px solid transparent;
	border-right: 						15px solid transparent;
	border-bottom: 						15px solid #FFFFFF;
}


.editClient .entityTable tbody td .menuOptions.show{
	visibility: 						visible;
	opacity: 							1;
}

.editClient .entityTable tbody td .menuOptions > span{
	text-decoration: 					none;
	color: 								#000000;
	font-size: 							12px;
	padding: 							5px 20px;
	opacity: 							1;
	transition: 						all 0.3s ease-in-out;
}

.editClient .entityTable tbody td .menuOptions span:first-of-type:hover{
	color: 								#FFFFFF;
	background-color: 					var(--darkBlue);
	cursor: 							pointer;
}

.editClient .entityTable tbody td .menuOptions span:last-of-type:hover{
	color: 								#FFFFFF;
	background-color: 					var(--red);
	cursor: 							pointer;
}

.editClient .addContact,
.editClient .addEntity{
	padding-inline: 					15px;
}

.editClient .addContact::before,
.editClient .addEntity::before{
	content: 							"";
    width: 								18px;
    height: 							18px;
    background: 						url("../../images/add.svg") center center no-repeat;
    background-size: 					18px 18px;
    margin-right: 						5px;
}

.editClient .addClient{
	margin:  							100px 0 0 auto;
}


@media (max-width: 991px){
	.editClient {
		margin-bottom: 						50px;
	}
	
	.editClient .titleAndBack {
		gap: 								10px;
		margin-bottom: 						30px;
	}

	.editClient .clientQuickActions{
		justify-content: 					center;
	}
	
	.editClient .titleHeader:first-of-type{
		margin-bottom: 						10px;
	}
	
	.editClient .inputSections{
		margin-bottom: 						50px;
		gap: 								20px;
		padding-inline: 					10px;
	}
	
	.editClient .inputSections .inputContainer{
		flex: 								0 0 calc(100%);
		max-width: 							calc(100%);
	}
	
	.editClient .sectionTitle{
		width: 								100%;
		font-weight: 						700;
		padding: 							20px 0 0;
	}
	
	.editClient .contactInformationRow{
		flex-flow: 							row wrap;
	}
	
	.editClient .contactInformationRow .deleteIcon{
		margin: 							0 auto;
		margin-top: 						5px;
	}
	
	.editClient .entityTable{
		display: 							none;
	}
	
	.editClient .addContact,
	.editClient .addEntity{
		margin: 							0 auto;
		padding-inline: 					50px;
	}

	.editClient .entityList{
		display: 							flex;
		flex-flow: 							column nowrap;
		margin: 							25px 0;
	}

	.editClient .entityList .entityRow{
		border-top: 						1px solid rgba(0, 0, 0, 0.05);
		padding: 							15px 23px 25px;
		position: 							relative;
	}

	.editClient .entityList .entityRow:last-of-type{
		border-bottom: 						1px solid rgba(0, 0, 0, 0.05);
	}

	.editClient .entityList .entityRow .entityName{
		font-weight: 						500;
		margin-bottom: 						20px;
	}

	.editClient .entityList .entityRow .entityInformation{
		width: 								100%;
		display:  							grid;
		align-items: 						flex-end;
		justify-content: 					space-between;
		grid-template-columns: 				repeat(1, fit-content);
		grid-template-rows: 				repeat(2, max-content);
		row-gap: 							0;
	}

	.editClient .entityList .entityRow .entityInformation .entityValue{
		font-size: 							12px;
	}

	.editClient .entityList .entityRow .entityInformation .entityTitle{
		font-size: 							10px;
		font-weight: 						700;
		opacity: 							0.4;
	}

	.editClient .entityList .entityRow .entityInformation > span:nth-of-type(1){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					1;
		grid-row-end: 						2;
	}

	.editClient .entityList .entityRow .entityInformation > span:nth-of-type(2){
		grid-column-start: 					1;
		grid-column-end: 					2;
		grid-row-start: 					2;
		grid-row-end: 						3;
	}

	.editClient .entityList .entityRow .entityInformation .moreDots{
		width: 								20px;
		height: 							20px;
		object-fit: 						contain;
		object-position: 					center center;
		position: 							absolute;
		top: 								0;
		bottom: 							0;
		right: 								10px;
		margin: 							auto 0;
		cursor: 							pointer;
	}
	
	.editClient .entityList .entityRow .entityInformation .menuOptions{
		width: 								max-content;
		height: 							fit-content;
		padding: 							10px 0;
		position: 							absolute;
		top: 								0;
		bottom: 							0;
		right: 								45px;
		margin: 							auto 0;
		background: 						#FFFFFF;
		box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
		z-index: 							999;
		visibility: 						hidden;
		opacity: 							0;
		transition: 						all 0.3s ease-in-out;
	}
	
	.editClient .entityList .entityRow .entityInformation .menuOptions::after{
		content: 							"";
		width: 								0;
		height: 							0;
		position: 							absolute;
		left: 								100%;
		top: 								0;
		bottom: 							0;
		margin: 							auto 0;
		border-left: 						15px solid #FFFFFF;
		border-top: 						15px solid transparent;
		border-bottom: 						15px solid transparent;
	}
	
	
	.editClient .entityList .entityRow .entityInformation .menuOptions.show{
		visibility: 						visible;
		opacity: 							1;
	}
	
	.editClient .entityList .entityRow .entityInformation .menuOptions > span{
		text-decoration: 					none;
		color: 								#000000;
		font-size: 							12px;
		padding: 							5px 20px;
		opacity: 							1;
		transition: 						all 0.3s ease-in-out;
	}
	
	.editClient .entityList .entityRow .entityInformation .menuOptions span:first-of-type:hover{
		color: 								#FFFFFF;
		background-color: 					var(--darkBlue);
		cursor: 							pointer;
	}
	
	.editClient .entityList .entityRow .entityInformation .menuOptions span:last-of-type:hover{
		color: 								#FFFFFF;
		background-color: 					var(--red);
		cursor: 							pointer;
	}
	
	.editClient .addClient{
		width: 								100%;
	}
}