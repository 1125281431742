/* ADD CLIENT MODAL */
.usersControlModal .modal-content{
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.usersControlModal .modal-body{
	padding: 							90px 100px;
}

.usersControlModal .modal-body .closeModal{
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								20px;
	right: 								20px;
	cursor: 							pointer;
}

.usersControlModal .modal-body .modalTitle{
	margin-bottom: 						30px;
}

.usersControlModal .modal-body .usersTable{
	margin-bottom: 						30px;
}

.usersControlModal .modal-body .actions{
	display: 							flex;
	justify-content: 					flex-end;
	align-items: 						center;
	gap: 								25px;
}

@media (max-width: 991px){
	.usersControlModal .modal-content{
		border-radius: 						35px;
	}
	
	.usersControlModal .modal-body{
		padding: 							35px 20px;
	}
	
	.usersControlModal .modal-body .closeModal{
		top: 								15px;
		right: 								15px;
	}


	.usersControlModal .modal-body .actions{
		justify-content: 					center;
	}
}