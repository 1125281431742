.staffList .securityStaffFilters{
	display: 							flex;
    align-items:                        center;
	flex-flow: 							row wrap;
	gap: 								10px;
	margin-bottom: 						10px;
    user-select:                        none;
}

.staffList .securityStaffFilters .activeFilter {
    background-color: 					var(--darkBlue) !important;
}

.staffList .securityStaffFilters .locationFilter{
	display: 							flex;
	align-items: 						center;
	padding: 							10px 20px;
	background-color: 					#FFFFFF;
	position: 							relative;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
	user-select: 						none;
}

.staffList.darkMode .securityStaffFilters .locationFilter{
	background-color: 					#131E29;
}

.staffList .securityStaffFilters .locationFilter .filterLabel {
    margin-left:                        5px;
}

.staffList .securityStaffFilters .locationFilter .filterLabelActive {
    margin-left:                        5px;
	background: 						var(--gradient) text;
	-webkit-text-fill-color: 			transparent;
}

.staffList .securityStaffFilters .locationFilter.show{
	background: 						var(--gradient), var(--blue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
	user-select: 						none;
}

.staffList .securityStaffFilters .locationFilter.active{
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.staffList .securityStaffFilters .locationFilter::after{
	content: 							"";
	width: 								20px;
	height: 							20px;
	background: 						url("../../images/arrowForward.svg") center center no-repeat;
	background-size: 					15px;
	transform: 							rotate(90deg);
	margin-left: 						20px;
	transition: 						all 0.3s ease-in-out;
	cursor: 							pointer;
}

.staffList .securityStaffFilters .locationFilter.show::after{
	transform: 							rotate(270deg);
}

.staffList .securityStaffFilters .locationFilter > img{
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	margin-right: 						5px;
	pointer-events: 					none;
}

.staffList .securityStaffFilters .locationFilter > span{
	font-size: 							12px;
	pointer-events: 					none;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions{
	width: 								max-content;
	position: 							absolute;
	top: 								105%;
	left: 								0;
	background: 						#FFFFFF;
	box-shadow: 						0px 5px 30px 0px rgba(35, 107, 216, 0.15);
	padding: 							20px;
	z-index: 							999;
	transition: 						all 0.3s ease-in-out;
	visibility: 						hidden;
	opacity: 							0;
	cursor: 							default;
	width: 								330px;
}

.staffList.darkMode .securityStaffFilters .locationFilter .locationFilterOptions{
	background: 						#131E29;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .locationFilterOptionsInput{
	background-color: 						rgba(4, 26, 57, 0.03);
	position: 								relative;
}

.staffList.darkMode .securityStaffFilters .locationFilter .locationFilterOptions .locationFilterOptionsInput{
	background: 						#27343F;
	-webkit-text-fill-color: 			white;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .optionsScroll{
	max-height: 						300px;
	overflow-y: 						auto;
}

/* width */
.staffList .securityStaffFilters .locationFilter .locationFilterOptions .optionsScroll::-webkit-scrollbar {
	width: 								3px;
}
  
 /* Track */
.staffList .securityStaffFilters .locationFilter .locationFilterOptions .optionsScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
 }
  
/* Handle */
.staffList .securityStaffFilters .locationFilter .locationFilterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.staffList.darkMode .securityStaffFilters .locationFilter .locationFilterOptions .optionsScroll::-webkit-scrollbar-thumb {
	background: 						white;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions.show, .staffList .securityStaffFilters .locationFilter .searchContainer.show{
	visibility: 						visible;
	opacity: 							1;
	-webkit-text-fill-color: 			#000000;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .title{
	font-size: 							14px;
	font-weight: 						700;
	margin-bottom: 						5px;
}

.staffList.darkMode .securityStaffFilters .locationFilter .locationFilterOptions .title,
.staffList.darkMode .securityStaffFilters .locationFilter .locationFilterOptions .option{
	-webkit-text-fill-color: 			white;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .option{
	display: 							flex;
	align-items: 						center;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions.location{
	width: 								280px;
	top: 								290%;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .option > input{
	margin-right: 						0;
	cursor: 							pointer;
}

.staffList.darkMode .securityStaffFilters .locationFilter .locationFilterOptions .option > input{
	background-color: 					white;
}

.staffList.darkMode .securityStaffFilters .locationFilter .locationFilterOptions .option > input:checked{
	background-color: 					var(--green);
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .option > label{
	flex:  								1 0 0;
	font-size: 							12px;
	opacity: 							0.8;
	cursor: 							pointer;
	padding: 							10px 0 10px 10px;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .optionScroll{
	max-height: 						220px;
	overflow-y: 						auto;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .optionScroll::-webkit-scrollbar {
	width: 								3px;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .optionScroll::-webkit-scrollbar-track {
	background: 						rgba(0, 0, 0, 0.05);
}
 
.staffList .securityStaffFilters .locationFilter .locationFilterOptions .optionScroll::-webkit-scrollbar-thumb {
	background: 						var(--darkBlue);
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .actions{
	display: 							flex;
	gap: 								10px;
	justify-content: 					center;
	margin-top: 						15px;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .actions > button {
	border: 							unset;
	background-color: 					transparent;
	padding: 							5px 15px;
	font-size: 							12px;
}

.staffList.darkMode .securityStaffFilters .locationFilter .locationFilterOptions .actions > button:first-of-type {
	-webkit-text-fill-color: 			white;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .actions > button:last-of-type {
	background: 						var(--gradient), var(--darkBlue);
	background-clip: 					text, padding-box;
	-webkit-background-clip: 			text, padding-box;
	-webkit-text-fill-color: 			transparent;
}

.staffList .titleAndSearch {
    display: 							flex;
	align-items: 						center;
	justify-content: 					space-between;
	margin-bottom: 						55px;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .locationFilterOptionsInputContainer {
	position: 							relative;
}

.staffList .securityStaffFilters .locationFilter .locationFilterOptions .locationFilterOptionsInputContainer .searchIcon {
    position: 							absolute;
    right: 								10px;
    top: 								50%;
    transform: 							translateY(-50%);
    pointer-events: 					none;
}

.staffList .titleAndSearch .searchAndSort {
    flex: 								0 0 25%;
}

.staffList .titleAndSearch .searchAndSort .searchContainer .searchContainer input {
	padding-right: 						50px;
}

.staffList .addDocumentHeader {
	padding: 							15px;
}

.staffList .addDocumentHeader .btnBgBlueTextGradient {
	padding: 							10px 15px;
}

.staffList .addDocumentHeader .btnBgBlueTextGradient .addIcon {
	height:                             18px;
    width:                              18px;
    margin-right:                       5px;
}

.staffList.darkMode .tableStaff th {
	background-color: 					#27343F;
}

.staffList .tableStaff .dataSection .infoContainer > span {
	display: 							inline-flex;
    align-items: 						center;
    font-weight: 						500;
    font-size: 							12px;
    opacity: 							1;
}

.staffList .tableStaff .dataSection .infoContainer .guardName {
	font-size: 							12px;
	font-weight: 						500;
	color: 								#000000;
}

.staffList.darkMode .tableStaff .dataSection .infoContainer .guardName {
	color: 								white;
}

.infoContainer .modalIconContainer {
    display: flex;
}

.staffList .tableStaff .infoContainer .modalIconContainer .eyeIcon {
    mask: 								url("../../images/visibility.svg") center center no-repeat;
    mask-size:                          20px;
    width:                              20px;
    height:                             20px;
    background:                         var(--darkBlue);
    object-fit: 						contain;
    object-position: 					center center;
	cursor: 							pointer;
	position:                           absolute;
    right:                              10px;
    top:                                0;
    bottom:                             0;
    margin:                             auto 0;
}

.darkMode .staffList .tableStaff .infoContainer .modalIconContainer .eyeIcon {
    background:                         white;
}