/* DOCUMENT INFORMATION MODAL */
.documentInformationModal .modal-dialog {
	max-width: 							650px;
}

.documentInformationModal .modal-content {
	background: 						var(--grey);
	box-shadow: 						30px 30px 100px 0px rgba(4, 26, 57, 0.15);
	border: 							unset;
	border-radius: 						unset;
}

.documentInformationModal .modal-body {
	padding: 							35px;
}

.documentInformationModal.darkMode .modal-body {
	background: 						#131E29;
	color: 								white;
}

.documentInformationModal .modal-body .closeModal {
	width: 								25px;
	height: 							25px;
	object-fit: 						contain;
	object-position: 					center center;
	position: 							absolute;
	top: 								15px;
	right: 								15px;
	cursor: 							pointer;
}

.documentInformationModal .modal-body .documentHeader {
	display:  							grid;
	align-items: 						center;
	grid-template-columns: 				1fr 1fr;
	row-gap: 							10px;
	margin-bottom: 						32px;
	margin-top: 						5px;
}

.documentInformationModal .modal-body .documentHeader:has(.informationIcon) {
	column-gap: 						5px;
	grid-template-columns: 				20px 1fr 1fr;
}

.documentInformationModal .modal-body .documentHeader .informationIcon {
	width: 								20px;
	height: 							20px;
	object-fit: 						contain;
	object-position: 					center center;
	cursor: 							help;
}

.documentInformationModal .modal-body .documentHeader .title {
	font-weight: 						700;
}

.documentInformationModal .modal-body .documentHeader .requestReview {
	display: 							flex;
	gap: 								5px;
	padding: 							10px 20px;
	width: 								max-content;
	justify-self: 						flex-end;
}

.documentInformationModal .modal-body .documentHeader .requestReview.inReview {
	background: 						var(--lightYellow);
	-webkit-text-fill-color: 			var(--darkBlue);
	cursor: 							default;
}

.documentInformationModal .modal-body .documentHeader .requestReview:disabled {
	background: 						rgba(0, 0, 0, 0.05) padding-box;
	opacity: 							1;
	-webkit-text-fill-color: 			rgba(0, 0, 0, 0.5);
}

.documentInformationModal .modal-body .documentHeader .requestReview::after {
	content: 							"";
	width: 								15px;
	height: 							15px;
	mask: 								url("../../images/quick_reference_all.svg") center center no-repeat;
	mask-size: 							15px;
	background: 						var(--gradient);
}

.documentInformationModal .modal-body .documentHeader .requestReview.inReview::before {
	content: 							"!";
	display: 							flex;
	align-items: 						center;
	justify-content: 					center;
	font-size: 							20px;
	font-weight: 						700;
	width: 								20px;
	height: 							20px;
	border-radius: 						50%;
	background: 						var(--yellow);
}

.documentInformationModal .modal-body .documentHeader .requestReview.inReview::after {
	display: 							none;
}

.documentInformationModal .modal-body .documentHeader .requestReview:disabled::after {
	background: 						rgba(0, 0, 0, 0.5);
}

.documentInformationModal .modal-body .documentHeader .toggleContainer {
	grid-row: 							1 / 2;
	grid-column: 						1 / -1;
	width: 								80px;
	background-color: 					#FFFFFF;
	border-radius: 						35px;
	justify-self: 						flex-end;
	padding: 							5px;
	overflow: 							hidden;
	cursor:								pointer;
	transition: 						all 0.3s ease-in-out;
}

.documentInformationModal.darkMode .modal-body .documentHeader .toggleContainer {
	background-color: 					#27343F;
}

.documentInformationModal .modal-body .documentHeader .toggleContainer .toggleIndicator {
	position: 							relative;
	width: 								25px;
	height: 							25px;
	border-radius: 						100px;
	background-color: 					rgba(4, 26, 57, 0.3);
	transition: 						all 0.3s ease-in-out;
}

.documentInformationModal .modal-body .documentHeader .toggleContainer .toggleIndicator::after,
.documentInformationModal .modal-body .documentHeader .toggleContainer .toggleIndicator::before {
	content: 							"N/A";
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
	left: 								135%;
	color: 								#808080;
}

.documentInformationModal .modal-body .documentHeader .toggleContainer .toggleIndicator::before {
	left: 								unset;
	right: 								135%;
	color: 								#FFFFFF;
}

.documentInformationModal .modal-body .documentHeader .toggleContainer.active {
	background-color: 					var(--green);
}

.documentInformationModal .modal-body .documentHeader .toggleContainer.active .toggleIndicator {
	background-color: 					#FFFFFF;
	transform: 							translateX(180%);
}

.documentInformationModal .modal-body .documentInformation {
	display: 							grid;
	column-gap: 						30px;
	row-gap: 							20px;
	grid-template-areas: 				"A B C D"
										"E E E F"
										"G G G G";
	grid-template-columns: 				calc(100% / 4 - (30px * 3 / 4)) calc(100% / 4 - (30px * 3 / 4)) calc(100% / 4 - (30px * 3 / 4)) calc(100% / 4 - (30px * 3 / 4));
}

.documentInformationModal .modal-body .documentInformation .dataContainer:nth-of-type(1) {
	grid-area: 							A;
}

.documentInformationModal .modal-body .documentInformation .dataContainer:nth-of-type(2) {
	grid-area: 							B;
}

.documentInformationModal .modal-body .documentInformation .dataContainer:nth-of-type(3) {
	grid-area: 							C;
}

.documentInformationModal .modal-body .documentInformation .dataContainer:nth-of-type(4) {
	grid-area: 							D;
}

.documentInformationModal .modal-body .documentInformation .dataContainer .value {
	font-size: 							12px;
	font-weight: 						700;
}

.documentInformationModal .modal-body .documentInformation .dataContainer .title {
	font-size: 							12px;
	opacity: 							0.5;
}

.documentInformationModal .modal-body .documentInformation .inputContainer:nth-of-type(5) {
	grid-area: 							E;
}

.documentInformationModal .modal-body .documentInformation .inputContainer:nth-of-type(6) {
	grid-area: 							F;
}

.documentInformationModal .modal-body .documentInformation .inputContainer:nth-of-type(7) {
	grid-area: 							G;
}

.documentInformationModal .modal-body .documentInformation .inputContainer .label {
	margin: 							0;
}

.documentInformationModal .modal-body .documentInformation .inputContainer .compliancy {
	font-size: 							12px;
}

.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer {
	width: 								100%;
	display: 							flex;
	flex-flow: 							row nowrap;
	gap: 								5px;
}

.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .documentSelector,
.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .downloadButton,
.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .deleteButton {
	flex: 								0 0 10%;
	border: 							0;
	padding: 							10px;
	background-color: 					var(--blue);
	transition: 						all 0.3s ease-in-out;
}

.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .documentSelector:hover,
.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .downloadButton:hover,
.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .deleteButton:hover {
	background-color: 					var(--darkBlue);
}

.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .documentSelector {
    position: 							relative;
	font-size: 							14px;
	background: 						var(--gradient) text, var(--blue) padding-box;
	-webkit-text-fill-color: 			transparent;
	flex: 								1 0 0;
    white-space: 						nowrap;
    overflow: 							hidden;
    text-overflow: 						ellipsis;
    padding: 							10px 20px 10px 35px;
}

.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .documentSelector.disabled {
	background: 						rgba(0, 0, 0, 0.05) padding-box;
	opacity: 							1;
	-webkit-text-fill-color: 			rgba(0, 0, 0, 0.5);
}

.documentInformationModal.darkMode .modal-body .documentInformation .inputContainer .buttonContainer .documentSelector.disabled {
	background: 						#27343F padding-box;
}

.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .documentSelector::after,
.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .downloadButton::after,
.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .deleteButton::after {
	content: 							"";
	display: 							block;
}

.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .documentSelector::after {
	width: 								20px;
	height: 							20px;
	mask: 								url("../../images/attachFile.svg") center center no-repeat;
	background: 						var(--gradient);
	mask-size: 							100% 100%;
	position: 							absolute;
	top: 								0;
	bottom: 							0;
	margin: 							auto 0;
	left: 								10px;
}

.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .documentSelector.disabled::after {
	background: 						rgba(0, 0, 0, 0.5);
	mask-size: 							100% 100%;
}

.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .downloadButton::after {
	width: 								18px;
	height: 							18px;
	mask: 								url("../../images/download.svg") center center no-repeat;
	background: 						var(--gradient);
	mask-size: 							100% 100%;
	margin: 							auto;
}

.documentInformationModal .modal-body .documentInformation .inputContainer .buttonContainer .deleteButton::after {
	width: 								15px;
	height: 							15px;
	mask: 								url("../../images/delete.svg") center center no-repeat;
	background: 						var(--gradient);
	mask-size: 							100% 100%;
	margin: 							auto;
}

@media (max-width: 991px){
	/* DOCUMENT INFORMATION MODAL */
	.documentInformationModal .modal-body .documentHeader {
		grid-template-columns: 				1fr;
		margin-bottom: 						30px;
	}
	
	.documentInformationModal .modal-body .documentHeader .informationIcon {
		order: 								4;
		margin-left: 						auto;
	}

	.documentInformationModal .modal-body .documentHeader:has(.informationIcon) {
		column-gap: 						5px;
		grid-template-columns: 				3fr 1fr;
	}

	.documentInformationModal .modal-body .documentHeader .title {
		order: 								3;
		max-width: 							90%;
		white-space: 						nowrap;
        text-overflow: 						ellipsis;
        overflow: 							hidden;
	}

	.documentInformationModal .modal-body .documentHeader .requestReview {
		justify-self: 						flex-start;
	}

	.documentInformationModal .modal-body .documentHeader .toggleContainer {
		grid-row: 							1;
		grid-column: 						2;
	}

	.documentInformationModal .modal-body .documentHeader:not(:has(.requestReview)) .toggleContainer {
		grid-column: 						1 / -1;
	}

	.documentInformationModal .modal-body .documentHeader > div {
		order: 								2;
	}

	.documentInformationModal .modal-body .documentInformation {
		display: 							grid;
		column-gap: 						30px;
		row-gap: 							20px;
		grid-template-areas: 				"A B"
											"C D"
											"E E"
											"G G"
											"F F";
		grid-template-columns: 				calc(100% / 2 - (30px * 1 / 2)) calc(100% / 2 - (30px * 1 / 2)) calc(100% / 2 - (30px * 1 / 2)) calc(100% / 2 - (30px * 1 / 2));
	}

	.documentInformationModal .modal-body .documentInformation .dataContainer:nth-of-type(1) {
		grid-area: 							A;
	}

	.documentInformationModal .modal-body .documentInformation .dataContainer:nth-of-type(2) {
		grid-area: 							B;
	}

	.documentInformationModal .modal-body .documentInformation .dataContainer:nth-of-type(3) {
		grid-area: 							C;
	}

	.documentInformationModal .modal-body .documentInformation .dataContainer:nth-of-type(4) {
		grid-area: 							D;
	}

	.documentInformationModal .modal-body .documentInformation .inputContainer:nth-of-type(5) {
		grid-area: 							G;
	}

	.documentInformationModal .modal-body .documentInformation .inputContainer:nth-of-type(6) {
		grid-area: 							E;
	}

	.documentInformationModal .modal-body .documentInformation .inputContainer:nth-of-type(7) {
		grid-area: 							F;
	}
}